import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { ValueChangedEvent } from 'devextreme/ui/date_box';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-date-edit',
  templateUrl: './date-edit.component.html',
  styleUrls: ['./date-edit.component.less']
})

/**
* Component belonging to the date input form elements.
*/
export class DateEditComponent implements OnInit {

    @Input() element: Element;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() fixedWidth: number;
    @Input() includeTime: boolean;

    public dateValue: Date;

    public renderingInformation: InputInformation;

    public dateBoxWidth: string;
    public dateBoxType: string;
    public dateDisplayFormat: string;

    constructor(private validationService: ElementValidationService, private stringUtils: StringUtilsService, private elementOperations: FormElementOperationsService) {
        // Making the component context available in the validity check function callback
        this.isValid = this.isValid.bind(this);  
    }

    /**
     * Initialization method. Formats the input component according to the inputs.
     */
    ngOnInit(): void {
        if (this.fixedWidth) {
            this.dateBoxWidth = this.fixedWidth ? String(this.fixedWidth) : 'auto';
            this.dateBoxType = "date";
        }
        if (!this.includeTime) {
            this.dateDisplayFormat = "dd.MM.yyyy";
            this.dateBoxType = "date";
        } else {
            this.dateDisplayFormat = "dd.MM.yyyy HH:mm";
            this.dateBoxType = "datetime";
        }
        const value = this.inputFormGroup.get('value').value;
        this.updateDateInputValue(value);
        this.updateDateDisplayValue();
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
            this.dateValue);
        this.subscribeToElementChanges();
    }


    /**
     * With each input change, updates the value in the reactive form structure and checks the
     * validity of the chosen value, updating also the validation message.
     * This method is necessary because of a DevExtreme limitation that prevents the "valid" attribute
     * from working with reactive forms validation.
     * @param e
     * @returns
     */
    isValid(e: any): boolean {
        if (e.value !== undefined) { 
            this.inputFormGroup.get('value').setValue(e.value);
        }
        return this.elementOperations.checkValue(this.inputFormGroup).valid;
    }

    /**
     * After a value was changed in the input, updates the display value in the reactive form structure
     * and also updates the validation summary with the potential errors that the value could have.
     * @param event
     */
    valueChanged(event: ValueChangedEvent) {
        this.dateValue = event.value;
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, this.dateValue, this.dateValue ? this.stringUtils.convertDateToDateString(event.value, this.includeTime) : "");
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.dateValue);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.updateDateInputValue(this.renderingInformation.updatedValueAfterOperation);
                    this.updateDateDisplayValue();
                }
            }
        })
    }

    /**
     * Updates the date input value with the value passed in the parameter.
     * @param value
     */
    private updateDateInputValue(value: unknown) {
        if (value && String(value).trim().length) {
            this.dateValue = new Date(value as string);
        } else {
            this.dateValue = null;
        }
    }

    /**
     * From the current value in the date input, the displayValue form control
     * is updated.
     */
    private updateDateDisplayValue() {
        if (this.dateValue) {
            this.inputFormGroup.get('displayValue').setValue(this.stringUtils.convertDateToDateString(this.dateValue, this.includeTime));
        } else {
            this.inputFormGroup.get('displayValue').setValue(null);
        }
    }
}
