import { Injectable } from '@angular/core';
import { ElementValidationService } from './element-validation.service';
import { Element } from '../../interfaces/form/element';
import { UntypedFormGroup } from '@angular/forms';
import { ValidationInformation } from '../../interfaces/form/elements/validation-information';
import { ElementChangeInformation } from '../../interfaces/form/elements/element-change-information';
import { ValidationFunctionType } from '../../interfaces/form/enums/validation-function-type-enum';
import { InputInformation } from '../../interfaces/form/elements/input-information';

@Injectable({
  providedIn: 'root'
})

/**
 * This service centralizes the operations made by the form elements
 * in different stages of the lifecycle (initialization, value change
 * validation status changes...)
 */
export class FormElementOperationsService {

    constructor(private validationService: ElementValidationService) { }

    /**
     * Obtains information for the form element to be properly rendered on initialization. Applies the logic
     * and validation functions for the current value.
     * @param element
     * @param elementFormGroup
     * @param inputValue
     * @returns information for input validation rendering
     */
    public initializeValidation(element: Element, elementFormGroup: UntypedFormGroup, inputValue: unknown): InputInformation {
        const elementRenderingInformation = <InputInformation>{};
        elementRenderingInformation.validationInformation = this.checkValue(elementFormGroup);
        // When a table add/edit form is initialized, the validations must be applied for the initial input value
        if (element.Validations) {
            this.validationService.applyElementValidations(element, [inputValue], elementFormGroup.get("belongsToTableId").value);
        }
        return elementRenderingInformation;
    }

    /**
     * After an form element's input value has changed, it updates the form structure, triggers the
     * element validation and also applies the validation and logic functions over the other elements,
     * if proceeding.
     * @param element
     * @param elementFormGroup
     * @param moduleIndex
     * @param inputValue
     * @param inputDisplayValue
     * @returns information for the validation rendering
     */
    public processValueChangeValidation(element: Element, elementFormGroup: UntypedFormGroup,
        moduleIndex: number, inputValue: unknown, inputDisplayValue: string): ValidationInformation {
        elementFormGroup.get('value').setValue(inputValue);
        elementFormGroup.get('value').markAsTouched();
        elementFormGroup.get('displayValue').setValue(inputDisplayValue);
        const validationInformation = this.checkValue(elementFormGroup);
        this.validationService.updateValidationSummary(elementFormGroup.get("belongsToTableId")?.value, moduleIndex);
        if (element.Validations) { 
            if (!Array.isArray(inputValue)) {
                this.validationService.applyElementValidations(element, [inputValue], elementFormGroup.get("belongsToTableId").value);
            } else {
                this.validationService.applyElementValidations(element, inputValue, elementFormGroup.get("belongsToTableId").value);
            }
        }
        return validationInformation;
    }

    /**
     * After an element has changed as a result of other element's logic/validation function, the
     * corresponding set of actions is performed here. 
     * @param data
     * @param elementFormGroup
     * @param inputValue
     * @param inputDisplayValue
     * @returns
     */
    public processElementChangeValidation(data: ElementChangeInformation, elementFormGroup: UntypedFormGroup,
        inputValue: unknown): InputInformation {
        const elementRenderingInformation = <InputInformation>{};
        if (data.elementFunctionChangeInformation.functionType === ValidationFunctionType.PlausiRequired) {
            elementFormGroup.get('value').setValue(inputValue);
            elementFormGroup.get('value').markAsTouched();
        } else if (data.elementFunctionChangeInformation.functionType === ValidationFunctionType.Update) {
            elementRenderingInformation.updatedAfterOperation = true;
            elementRenderingInformation.updatedValueAfterOperation = elementFormGroup.get('value')?.value;
        }
        elementRenderingInformation.validationInformation = this.checkValue(elementFormGroup);
        this.validationService.updateValidationSummary(elementFormGroup.get("belongsToTableId")?.value, data.moduleIndex);
        return elementRenderingInformation
    }

    /**
     * Checks the validity of the current value of the reactive form structure for this element
     * and updates the validation information for the component to show it.
     * @returns
     */
    public checkValue(elementFormGroup: UntypedFormGroup): ValidationInformation {
        const validationInformation = <ValidationInformation>{};
        validationInformation.valid = elementFormGroup.valid;
        // If the value isn't valid, update the validation message for the input element
        validationInformation.validationMessage = {
            message: validationInformation.valid ? null : this.validationService.getValidationMessageForElementFormGroup(elementFormGroup)
        };
        return validationInformation;
    }

}
