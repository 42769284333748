import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { ValueChangedEvent } from 'devextreme/ui/check_box';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';
import { UIElementType } from '../../../../interfaces/form/enums/uielement-type-enum';

@Component({
  selector: 'app-check-edit',
  templateUrl: './check-edit.component.html',
  styleUrls: ['./check-edit.component.less']
})
export class CheckEditComponent implements OnInit {

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;
    @Input() embeddedLabelPosition: string;

    public currentValue: boolean;

    public labelLeft: boolean;
    public labelEmbedded: boolean;

    public renderingInformation: InputInformation;

    constructor(private validationService: ElementValidationService, private stringUtils: StringUtilsService, private elementOperations: FormElementOperationsService) { }

    ngOnInit(): void {
        this.currentValue = this.inputFormGroup.get("value")?.value ? true : false;
        this.inputFormGroup.get('displayValue').setValue(this.stringUtils.convertBooleanToYesNoString(this.currentValue));
        if (this.inputFormGroup.get('label')?.value && this.element.UIElement === UIElementType.CheckEditRightLabelDescription) {
            this.labelEmbedded = false;
        } else if (this.embeddedLabelPosition === "left") {
            this.labelEmbedded = true;
            this.labelLeft = true;
        } else {
            this.labelEmbedded = true;
            this.labelLeft = false;
        }
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup, this.currentValue);
        this.subscribeToElementChanges();
    }

    /**
     * On checkbox value change, emits the new value to the table component for representation
     * and triggers the validations.
     * @param e
     */
    valueChanged(e: ValueChangedEvent) {
        this.currentValue = e.value;
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, e.value, this.stringUtils.convertBooleanToYesNoString(e.value));
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.currentValue);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.currentValue = this.inputFormGroup.get("value")?.value ? true : false;
                    this.inputFormGroup.get('displayValue').setValue(this.stringUtils.convertBooleanToYesNoString(this.currentValue));
                }
            }
        })
    }
}
