import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserController } from '../client/R23_Webforms.WebInterface.UserController';

@Injectable()
export class AuthenticationService {
    // Private properties
    private _RefreshTokenTimeout: NodeJS.Timeout;
    private _UserSubject: BehaviorSubject<R23_Webforms.Model.Membership.User | null>;

    // Public properties
    public get CurrentUser(): R23_Webforms.Model.Membership.User | null {
        return this._UserSubject.value;
    }

    constructor(private argUserController: UserController) {
        this._UserSubject = new BehaviorSubject<R23_Webforms.Model.Membership.User | null>(null);
    }

    public Authenticate() {
        const $Deferred = $.Deferred();

        this.argUserController.Authenticate().then((argUser) => {
            this._UserSubject.next(argUser);

            $Deferred.resolve();
        });

        return $Deferred.promise();
    }

    //public Login(argUserLogin: R23_Webforms.Model.Membership.UserLogin, argLoadingAnimationContainer: JQuery | null = null) {
    //    var $Deferred = $.Deferred();

    //    var oRequest: R23_Webforms.Model.General.Request<R23_Webforms.Model.Membership.UserLogin> = {
    //        Data: argUserLogin
    //    };

    //    this.argUserController.Login(oRequest, argLoadingAnimationContainer).then((argUser) => {
    //        this._UserSubject.next(argUser);
    //        this.StartRefreshTokenTimer();

    //        $Deferred.resolve();
    //    });

    //    return $Deferred.promise();
    //}

    //public Logout(argLoadingAnimationContainer: JQuery | null = null) {
    //    var $Deferred = $.Deferred();

    //    this.argUserController.Logout(argLoadingAnimationContainer).then((argResponse) => {
    //        this.StopRefreshTokenTimer();
    //        this._UserSubject.next(null);

    //        $Deferred.resolve();
    //    });

    //    return $Deferred.promise();
    //}

    //public RefreshToken(argLoadingAnimationContainer: JQuery | null = null) {
    //    var $Deferred = $.Deferred();

    //    this.argUserController.RefreshAccessToken(argLoadingAnimationContainer).then((argUser) => {
    //        this._UserSubject.next(argUser);
    //        this.StartRefreshTokenTimer();

    //        $Deferred.resolve();
    //    });

    //    return $Deferred.promise();
    //}

    // Helper methods
    //private StartRefreshTokenTimer() {
    //    if (this.CurrentUser != null && this.CurrentUser.AccessToken != null) {
    //        const oAccessToken = JSON.parse(atob(this.CurrentUser.AccessToken.split('.')[1]));

    //        // Set a timeout to refresh the token a minute before it expires
    //        const oDateExpire = new Date(oAccessToken.exp * 1000);
    //        const iTimeout = oDateExpire.getTime() - Date.now() - (60 * 1000);
    //        this._RefreshTokenTimeout = setTimeout(() => this.RefreshToken(), iTimeout);
    //    }
    //}

    //private StopRefreshTokenTimer() {
    //    clearTimeout(this._RefreshTokenTimeout);
    //}
}
