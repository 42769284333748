<div [ngClass]="'form-editor'">

    <dx-file-uploader name="file" [selectButtonText]="selectFileText" [labelText]="dropFileText" uploadUrl="api/files/chunk-upload" [attr.id]="'input-' + element.ElementID"
                      [maxFileSize]="maxFileSize" [disabled]="inputFormGroup.get('disabled')?.value" [accept]="acceptTypes" [chunkSize]="10000" [multiple]="multiple" uploadMode="instantly"
                      (onBeforeSend)="customizeUploadRequest($event)" (onUploaded)="submitUploadedFileInfo($event)" (onValueChanged)="deleteUploadedFile($event)">

    </dx-file-uploader>


    <div class="validation-summary-container" *ngIf="errorSummaryMessages.length">
        <app-validation-summary [validationMessages]="errorSummaryMessages" [addAnchorNavigation]="false"></app-validation-summary>
    </div>
</div>
