import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BrandingService } from '../../services/branding.service';
import { Branding } from '../../interfaces/branding';
import { FooterLink } from '../../interfaces/form/footer-link';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})

/**
* Manages the information related to the footer.
*/
export class FooterComponent implements OnInit {

    public _footerLinks: FooterLink[];
    private _branding: Branding;

    constructor(private argChangeDetector: ChangeDetectorRef, private brandingService: BrandingService) { }

    /**
     * Init method with the subscribers to the branding information.
     */
    ngOnInit(): void {
        this.brandingService.branding.subscribe((argBranding) => {
            this.argChangeDetector.detectChanges();
            this._branding = argBranding;
        });
        this.brandingService.footerLinks.subscribe((argFooterLinks) => {
            this.argChangeDetector.detectChanges();
            this._footerLinks = argFooterLinks;
        });
    }


    // Getter method of branding.
    get branding(): Branding {
        return this._branding;
    }

    // Getter method of footer links.
    get footerLinks(): FooterLink[] {
        return this._footerLinks;
    }

    /**
     * Gets the background color associated to the branding information.
     */
    public get brandingBackgroundColor() {
        return this.brandingService.obtainBrandingBackgroundColor(this._branding);
    }

    /**
     * Handles the event of clicking in a footer link.
     * @param e with the click event
     */
    public onFooterLinkClick(e: Event) {
        e.preventDefault();
        console.log(e);
    }

}
