<div id="FormContainer" #formerFormContainer>
    <ng-template [ngIf]="pageDescription">
        <div class="container page-description-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="description" [innerHTML]="pageDescription"></div>
                </div>
            </div>
        </div>
    </ng-template>


    <form id="Form" (ngSubmit)="onSubmit()" [formGroup]="formControl">
        <div class="container">

            <div class="form-container" #formContainer>
                <ng-container *ngIf="dataInputResult">
                    <app-form-generator [dataInput]="dataInputResult" [formSubmitted]="formSubmitted" (formDataReady)="submitFormData($event)"> </app-form-generator>
                </ng-container>
            </div>

            <ng-container *ngIf="formLoaded && dataInputResult">
                <ng-container *ngIf="formHasValidation()">
                    <div class="summary">
                        <span id="FormCompulsoryFieldsIndication" i18n>Die mit * gekennzeichneten Felder sind Pflichtfelder.</span>
                    </div>
                </ng-container>
                <div class="summary">
                    <span id="FormAgbsIndication" i18n>Unten finden Sie unsere geltende Datenschutzerklärung.</span>
                </div>

                <ng-container *ngIf="isSummaryVisible">
                    <div class="summary">
                        <span id="FormAfterSendingIndication" i18n="after sending indication">Bitte beachten Sie, dass dieses Formular nach dem Absenden nicht mehr gültig ist.</span>
                    </div>
                </ng-container>
                <div class="action-container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="validation-summary-container">
                                <ng-container *ngIf="!formControl.valid">
                                    <app-validation-summary [validationMessages]="validationService.validationMessages" [addAnchorNavigation]="true"></app-validation-summary>
                                </ng-container>
                            </div>
                            <button type="submit" class="button" [attr.style]="brandingBackgroundColor">
                                <span i18n>Absenden</span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="renderGetDataPopup">
                <app-popup (popupClosed)="clearDataInputPopupInfo($event)"></app-popup>
            </ng-container>
            <ng-container *ngIf="renderSaveDataPopup">
                <app-popup (popupClosed)="clearDataSubmitPopupInfo($event)"></app-popup>
            </ng-container>
        </div>
    </form>

</div>
