import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BrandingService } from '../../services/branding.service';
import { Branding } from '../../interfaces/branding';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

/**
* Manages the information related to the header.
*/
export class HeaderComponent implements OnInit {

    private _branding: Branding;

    constructor(private argChangeDetector: ChangeDetectorRef, private brandingService: BrandingService) { }

    /**
     * Init method with the subscribers to the branding information.
     */
    ngOnInit(): void {
        this.brandingService.branding.subscribe((subscriber) => {
            this.argChangeDetector.detectChanges();
            this._branding = subscriber;
        });
    }


    // Getter method of branding.
    get branding(): Branding {
        return this._branding;
    }

    /**
     * Gets the Icon URL associated to the branding information.
     */
    public get brandingIconUrl() {
        return this.brandingService.obtainBrandingIconUrl(this._branding);
    }
}
