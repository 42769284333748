import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ReactiveFormService } from '../../../services/form/reactive-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { Element } from '../../../interfaces/form/element';
import { Table } from '../../../interfaces/form/table';
import { LayoutItem } from '../../../interfaces/form/layout-item';
import { ExcelService } from '../../../services/excel.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { PopupType } from '../../../interfaces/enum/popup-type';
import { UIElementType } from '../../../interfaces/form/enums/uielement-type-enum';
import { PopupService } from '../../../services/popup.service';
import { RowInsertedEvent, RowInsertingEvent, RowRemovingEvent, RowUpdatedEvent, RowUpdatingEvent, ExportingEvent, EditingStartEvent, InitNewRowEvent } from 'devextreme/ui/data_grid';
import { Layout } from '../../../interfaces/form/layout';
import { ElementValidationService } from '../../../services/form/element-validation.service';
import * as uuid from 'uuid';

type GatheredTableElementInformation = {
    element: Element;
    caption: string;
}

@Component({
    selector: 'app-table-generator',
    templateUrl: './table-generator.component.html',
    styleUrls: ['./table-generator.component.less']
})



/**
* This creates (as currently implemented, but can be changed) a new table in the form,
* based on the module in which the table element was found
*/
export class TableGeneratorComponent implements OnInit, AfterViewInit {


    @Input() tablesInModule: Table[];
    @Input() tableElement: Element;
    @Input() layoutItem: LayoutItem;
    @Input() moduleIndex: number;
    @Output() tableSubmit = new EventEmitter<boolean>();

    // For the import button
    @ViewChild('fileUpload') fileUploadButton: ElementRef;
    // Instance of the dxDataGridComponent
    @ViewChild('dxDataGrid') dxDataGrid: DxDataGridComponent;

    public parentRootFormGroup!: UntypedFormGroup;
    public tableLayout: Layout;
    public tableTitle: string;

    // Table data source
    public dataElements: Record<string, string>[] = [];

    // Stores the index for which a row is being edited/added
    private _editTableRowKey = "";

    // For the table columns
    public gatheredTableElements: GatheredTableElementInformation[] = [];

    // Object for adding the import table button to the DxDataGrid element
    public importButtonOptions = {
        text: "Excel-Datei importieren",
        icon: "import",
        onClick: () => {
            // Clicking in the import button of the dxDataGrid should fire the real input #fileUpload uploading the file (hidden by CSS)
            const event = new MouseEvent('click', { bubbles: true });
            this.fileUploadButton.nativeElement.dispatchEvent(event)
        },
    };

    public renderImportTableErrorPopup = false;
    
    // This class is applied to the add/edit row popup wrapper and it indicates if the form was submitted or not;
    public popupWrapperClass: string;

    constructor(private excelService: ExcelService, private formService: ReactiveFormService, public popupService: PopupService, public validationService: ElementValidationService) {
        
    }

    /**
     * Init method of the current table. The columns are retrieved and the form controls are added for each column edit field.
     */
    ngOnInit(): void {

        const table: Table = this.getTableForElement();
        if (table && table.Layout) {
            this.tableLayout = table.Layout;
            const tableFormGroup = this.formService.searchElementFormGrouplInReactiveFormByElementId(this.tableElement.ElementTableID, true);
            if (tableFormGroup) {
                this.tableTitle = tableFormGroup.get('layoutItemTitle').value;
            }
            const layoutItems = table.Layout.LayoutItems;
            this.getTableElements(table.Elements, layoutItems);
            // Add a form array  consisting on a form group for each table column edit field that is not submittable
            // (only the entered rows will be submitted, not the values of those edit fields)
            this.gatheredTableElements.map((tableColumn) => {
                this.formService.addFormControlForElement(tableColumn.element, null, this.moduleIndex, true, this.tableElement.ElementTableID, null);
            })
        }
        this.popupWrapperClass = "row-edit-form-clean";
    }


    /**
     * After the current table has been rendered, check whether it is the last element rendered. If so, notify the reactive
     * form service so it can emit the form loaded event.
     */
    ngAfterViewInit() {
        if (this.tableElement.ElementID === this.formService.lastElementIdAdded) {
            this.formService.notifyLastElementLoaded();
        }
    }

    /**
     * Executed when the user opens the add row popup form. It indicates that the popup is opened in edit mode (by setting
     * to null the row key indicator) and cleans up the form, in case there was previous validation style or values.
     * @param e
     */
    prepareNewRow(e: InitNewRowEvent) {
        this._editTableRowKey = null;
        this.popupWrapperClass = "row-edit-form-clean";
        this.formService.clearEditRowValuesFromTable(this.tableElement.ElementTableID, this.moduleIndex);
        this.validationService.setValidationSummaryForTableId(this.tableElement.ElementTableID, this.moduleIndex);
    }

    /**
     * Captures an element addition to the current table and maintains the status of the rows and columns parentFormArrays.
     * @param e
     */
    onRowAdd(e: RowInsertingEvent) {
        this.popupWrapperClass = "row-edit-form-submitted";
        // The new item key is autogenerated by DxDataGrid and placed in e.data.__KEY__
        // Adding values to reactive form
        const rowEditFailingFields = this.formService.addRowToTable(null, e.data.__KEY__, this.tableElement.ElementTableID, this.moduleIndex);
        if (rowEditFailingFields.length) {
            e.cancel = true;
            this.validationService.setValidationSummaryForTableId(this.tableElement.ElementTableID, this.moduleIndex);
        }
    }

    /**
     * Once the new row is sucessfully added, reflects the edited data in the table layout.
     * @param e
     */
    onRowAdded(e: RowInsertedEvent) {
        this.updateRowDisplay(this.dataElements.length - 1);
        this.tableSubmit.emit(true);        
        this.popupWrapperClass = "row-edit-form-clean";
    }

    /**
     * When a row is going to be edited, prepares the form control fields for editing that row with the
     * selected rows value.
     * @param e
     */
    prepareEditFields(e: EditingStartEvent) {
        this._editTableRowKey = e.key;
        this.popupWrapperClass = "row-edit-form-clean";
        this.formService.prepareEditRowData(this.tableElement.ElementTableID, this.moduleIndex, e.component.getRowIndexByKey(e.key));
        this.validationService.clearValidationSummaryForTable();
    }

    /**
     * Captures an element update in the current table and maintains the status of the parentFormArray.
     * @param e
     */
    onRowUpdate(e: RowUpdatingEvent) {
        const editedRowIndex = e.component.getRowIndexByKey(e.key);
        const rowEditFailingFields = this.formService.editRowValuesFromTable(editedRowIndex, this.tableElement.ElementTableID, this.moduleIndex);
        if (rowEditFailingFields.length) {
            e.cancel = true;
            this.validationService.setValidationSummaryForTableId(this.tableElement.ElementTableID, this.moduleIndex);
        }
        this.popupWrapperClass = "row-edit-form-submitted";
    }

    /**
     * Once the row is sucessfully edited, reflects the edited data in the table layout.
     * @param e
     */
    onRowUpdated(e: RowUpdatedEvent) {
        this.updateRowDisplay(e.component.getRowIndexByKey(e.key));
        this.popupWrapperClass = "row-edit-form-clean";
    }


    /**
     * After the user decides to save an edited row, it introduces an empty change for forzing the onRowUpdated/ing execution.
     * This is done this way because we are using a totally custom for for adding/editing rows. If the edit fields inside that
     * custom form are edited, dxDataGrid doesn't realize that there are changes in the fields, so it doesn't trigger those event handlers.
     * source: https://supportcenter.devexpress.com/ticket/details/t993714/datagrid-how-to-force-row-update-even-if-there-is-no-changes
     * @param e
     */
    forceUpdateDialog(e: any) {
        if (e.changes.length == 0 && this._editTableRowKey) {
            e.changes.push({
                type: "update",
                key: this._editTableRowKey,
                data: {}
            });
        }
    }


    /**
     * Captures an element removal to the current table and maintains the status of the parentFormArray.
     * @param e
     */
    onRowRemove(e: RowRemovingEvent) {
        //getRowIndexByKey() doesn't seem to be working so the following line is used for getting the deleted row index
        const deletedRowIndex = e.component.getDataSource().items().findIndex(item => item.__KEY__ === e.data.__KEY__);
        this.formService.deleteRowFromTable(deletedRowIndex, this.tableElement.ElementTableID);
    }

    /**
     * If an instantiated input custom component is sending a new value, this function must be called from
     * an event handler so it sets the value of a cell.
     * @param receivedValue
     * @param cellInfo
     */
    saveInputValueInCell(receivedValue: any, cellInfo: any) {
        cellInfo.setValue(receivedValue);
    }

    /**
     * States whether a column belonging to an add/edit row element must be rendered in the table. For example,
     * separators and labels cannot have a value, therefore it doesn't make sense to render it as table's column. 
     * @param element
     * @returns
     */
    columnIsVisibleInTable(element: Element): boolean {
        if (element.UIElement !== UIElementType.Separator && element.UIElement !== UIElementType.Label
            && element.UIElement !== UIElementType.LabelUnderlineRightAlignment) {
            return true;
        } else {
            return false;
        }
    }

    /**
    * Receives the exporting event of the dxDataGrid, customizes the exported file and saves it.
    * @param e
    */
    exportTable(e: ExportingEvent) {
        const fileName = (this.tableTitle ? this.tableTitle : "Export") + ".xlsx"
        this.excelService.exportTableFromDataGrid(e, fileName, this.gatheredTableElements.map(function (gatheredElement) { return gatheredElement.element }));
    }

    /**
   * Receives the event of the user clicking in the import button of the dxDataGrid.
   * @param event with the event 
   */
    importTable(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const fileList: FileList | null = inputElement.files;
        this.excelService.importExcelToDataGrid(fileList.item(0), this.dxDataGrid, this.gatheredTableElements.map((gatheredElement) => {
            return gatheredElement.element
        })).subscribe({
            next: importInfo => {
                this.fileUploadButton.nativeElement.value = "";
                if (importInfo) {
                    const validRows: number[] = [];
                    if (importInfo.length) {
                        let failingColumns: string = null;
                        const currentTableRows = this.dataElements.length;
                        importInfo.forEach((value, rowIndex) => {
                            if (!failingColumns) {
                                // The imported item key is NOT autogenerated by DxDataGrid so a random UUID is generated.
                                // This code needs to be later on double-check as currently I am not sure of the procedure
                                const rowId = uuid.v4();
                                const rowEditFailingFields = this.formService.addRowToTable(value, rowId, this.tableElement.ElementTableID, this.moduleIndex);
                                if (!rowEditFailingFields.length) {
                                    validRows.push(currentTableRows + rowIndex);
                                    value.displayValues["__KEY__"] = rowId;
                                    this.dataElements.push(value.displayValues);
                                    this.formService.clearEditRowValuesFromTable(this.tableElement.ElementTableID, this.moduleIndex);
                                } else {
                                    failingColumns = rowEditFailingFields.map((field) => { return field.get("displayName").value }).join(", ");
                                    // Row Index (starting from 0 in this loop) + 1 (excel starts with 1) + 1 (header row in excel)
                                    this.popupService.popupInfo = {
                                        title: "Excel-Import",
                                        content: `Daten konnten nicht importiert werden. Zeile ${rowIndex + 2} aus der Excel -Datei beeinhaltet ungultige Daten (${failingColumns}).`,
                                        type: PopupType.ERROR
                                    }
                                    this.renderImportTableErrorPopup = true;
                                    

                                }
                            }
                        })
                        // Case an error was found, the rows added before have to be deleted from both the reactive
                        // form structure and the data source of the table.
                        if (failingColumns && failingColumns.length) {
                            for (let i = currentTableRows + validRows.length - 1; i >= currentTableRows; i--) {
                                this.formService.deleteRowFromTable(i, this.tableElement.ElementTableID);
                                this.dataElements.splice(i, 1);
                            }
                        }
                    }
                }
            },
            error: () => {
                this.renderImportTableErrorPopup = true;
            }
        })
    }

    /**
     * When a popup is closed, it resets the information to empty.
     * @param isPopupClosed
     */
    clearPopupInfo(isPopupClosed: boolean) {
        if (isPopupClosed) {
            this.renderImportTableErrorPopup = false;
        }
    }

    /**
     * Extract the pure attribute of type Element from the gathered elements needed for rendering the table.
     * @returns
     */
    extractElementsFromGatheredElements(): Element[] {
        return this.gatheredTableElements.map(el => el.element);
    }

    /**
     * Gets the table belonging to the module's table element.
     * @returns
     */
    private getTableForElement(): Table {

        if (this.tablesInModule) {
            return this.tablesInModule.find(table => { return table.ElementTableID === this.tableElement.ElementTableID });
        } else {
            return null;
        }
    }


    /**
     * Gathers the elements in the table array belonging to the layout items and pushes them in an array.
     * @param tableElements direct Element children of the Table object
     * @param layoutItems layout items inside the Table object
     * @returns
     */
    private getTableElements(tableElements: Element[], layoutItems: LayoutItem[]) {
        
        for (let i = 0; i < layoutItems.length; i++) {
            const currentLayoutItem = layoutItems[i];

            if (currentLayoutItem.Type === 'Element') {
                const foundTableElement = tableElements.find(element => { return element.ElementID == currentLayoutItem.ElementID });
                if (foundTableElement) {
                    const gatheredTableElement = {} as GatheredTableElementInformation;
                    gatheredTableElement.element = foundTableElement;
                    gatheredTableElement.caption = foundTableElement.DisplayName + (foundTableElement.IsRequired ? '*' : '');
                    this.gatheredTableElements.push(gatheredTableElement);                    
                }
            } else if (currentLayoutItem.Type == 'LayoutGroup') {
                this.getTableElements(tableElements, currentLayoutItem.LayoutItems);
            }
        }
    }

    /**
     * Programmatically reflects the changes added in the add/edit form to the table rendered.
     * @param rowIndex
     */
    private updateRowDisplay(rowIndex: number) {
        const rowValuesRecord: Record<string, string> =
            this.formService.getRowDisplayValues(this.tableElement.ElementTableID, this.moduleIndex);
        this.dataElements[rowIndex] = {};
        Object.entries(rowValuesRecord).forEach(([key, val]) => {
            this.dataElements[rowIndex][key] = val;
        })
    }
}
