<ng-template [ngIf]="branding">
    <footer [attr.style]="brandingBackgroundColor">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ng-template ngFor let-footerLink [ngForOf]="footerLinks" let-i="index">
                        <ng-template [ngIf]="footerLink.Content && !footerLink.Url">
                            <a href="#" (click)="onFooterLinkClick($event)" class="no-url-footer-link"> {{footerLink.Text}} </a>
                        </ng-template>

                        <ng-template [ngIf]="footerLink.Url">
                            <span class="separator" *ngIf="i !== 0">|</span> <a href="{{footerLink.Url}}" target="_blank"> {{footerLink.Text}} </a>
                        </ng-template>
                    </ng-template>

                    <ng-template [ngIf]="branding.Url">
                        <a class="branding-url" href="{{branding.Url}}" target="_blank">{{branding.Url}}</a>
                    </ng-template>
                </div>
            </div>
        </div>
    </footer>
</ng-template>
