<ng-container *ngIf="labelText; else formInputText">
    <label [ngClass]="'label'">{{labelText}}</label>
</ng-container>
<ng-template #formInputText>
    <div class="label-container" *ngIf="labelInformation?.displayLabel">
        <label [ngClass]="labelInformation.labelClass" [id]="'label-' + element.ElementID"
               [for]="'input-' + element.ElementID">
            {{inputFormGroup.get('label')?.value ? inputFormGroup.get('label').value : inputFormGroup.get('displayName')?.value}}
        </label>
    </div>
</ng-template>
