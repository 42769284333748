import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { Item } from '../../../../interfaces/form/item';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { ValueChangedEvent } from 'devextreme/ui/tag_box';
import { ItemService } from '../../../../services/item.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-combo-box-edit-checkboxes',
  templateUrl: './combo-box-edit-checkboxes.component.html',
  styleUrls: ['./combo-box-edit-checkboxes.component.less']
})
/**
 * Component logic for combo box edit with checkboxes.
 */
export class ComboBoxEditCheckboxesComponent implements OnInit {

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;

    public sortedItems: Item[] = [];
    public selectedItems: string[] = [];

    public renderingInformation: InputInformation;

    constructor(private validationService: ElementValidationService, private stringUtils: StringUtilsService,
        private itemService: ItemService, private elementOperations: FormElementOperationsService) { }

  /**
   * Initialization. Setting the selectable items.
   */
  ngOnInit(): void {
      const elementItems: Item[] = this.element.Items as Item[];
      if (elementItems.length) {
          this.sortedItems = this.itemService.sortItemsBySortAttribute(elementItems, this.element.ElementItemsSortMode);
          // If there is an initial value, take it from the form control value and set it in the item values array
          const elementValue = this.inputFormGroup.get("value")?.value;
          if (elementValue) { 
              this.selectedItems = (Array.isArray(elementValue) ? elementValue : [elementValue]) as string[];
              this.inputFormGroup.get("value").setValue(this.selectedItems);
              this.inputFormGroup.get('displayValue').setValue(this.getDisplayValue(this.selectedItems));
          }
      }
      this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
          this.selectedItems);
      this.subscribeToElementChanges();
  }

    /**
    * Event listener when the user performs a selection.
    * @param e
    */
    changeComboBoxValue(e: ValueChangedEvent) {
        this.selectedItems = e.value;
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, this.selectedItems, this.getDisplayValue(e.value));
    }

    /**
     * Forms the display value for the displayValue form control of this input element's form group.
     * @param valuesArray
     */
    private getDisplayValue(valuesArray: any[]): string {
        const emitItems: Item[] = [];
        valuesArray.forEach((value: string) => {
            emitItems.push(this.sortedItems.find((elementItem) => elementItem.Value === value));
        })
        return this.stringUtils.convertItemsArrayToItemsDisplayName(emitItems);
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.selectedItems);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.selectedItems = Array.isArray(this.renderingInformation.updatedValueAfterOperation) ?
                        this.renderingInformation.updatedValueAfterOperation : [this.renderingInformation.updatedValueAfterOperation];
                    this.inputFormGroup.get('value').setValue(this.selectedItems);
                    this.inputFormGroup.get('displayValue').setValue(this.getDisplayValue(this.selectedItems));
                }
            }
        })
    }
}
