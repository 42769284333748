import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PopupInfo } from '../interfaces/popup-info';
import { PopupType } from '../interfaces/enum/popup-type';
import { Receipt } from '../interfaces/form/receipt';
import { PopupService } from '../services/popup.service';

/**
 * This service contains all the client methods for consuming the back-end services
 * related to the file creation, exporting, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class FileClientService {

    constructor(private httpClient: HttpClient, private popupService: PopupService) { }

    /**
     * Creates a PDF by calling to the corresponding service.
     * @param receipts
     * @returns
     */
    public createPdf(receipts: Receipt[]): Observable<Blob> {
        return this.httpClient.post<Blob>(`api/File/CreatePdf`, receipts, { withCredentials: true, responseType: 'blob' as 'json' }).pipe(
            map((response) => {
                return new Blob([response], { type: 'application/pdf' });
            }),
            catchError(
                this.handleError.bind(this)
            )
        )
    }

    /**
     * Creates an instance of PopupInfo with the necessary information for rendering an error pop-up.
     * @param error
     * @returns
     */
    private handleError(error: HttpErrorResponse): Observable<Blob> {
        const popupInfo: PopupInfo = { title: "", content: "", type: null };
        if (error.status === 0) {
            popupInfo.title = 'Error';
            popupInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
            popupInfo.type = PopupType.ERROR;
        } else {
            switch (error.status) {
                case 401:
                case 403:
                    popupInfo.title = 'Access denied';
                    popupInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
                    popupInfo.type = PopupType.ERROR;
                    break;
                default:
                    popupInfo.title = 'Error';
                    popupInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
                    popupInfo.type = PopupType.ERROR;
                    break;
            }
        }
        this.popupService.popupInfo = popupInfo;

        throw throwError(() => "Error in the request");
    }
}
