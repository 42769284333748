export enum ValidationFunctionType {
    Unkown = 0,
    PlausiRange = 1,
    UIEnable = 2,
    Update = 3,
    UIVisible = 4,
    InvokeJob = 5,
    UpdateWithSystemFunction = 7,
    UIFilter = 8,
    UpdateWithElementFunction = 9,
    InvokeElementFunction = 10,
    InvokeSystemFunction = 11,
    InteractiveUpdate = 12,
    UIFilterSurvNetSchema = 13,
    PlausiDateNotInFuture = 14,
    PlausiRequired = 15
}
