<div [ngClass]="'form-editor'">
    <dx-number-box [attr.id]="'input-' + element.ElementID" [format]="numberDisplayFormat" [(value)]="inputValue" (onValueChanged)="valueChanged($event)"
                   [disabled]="inputFormGroup.get('value')?.disabled" [max]="(10 | mathPow:element.MaxLength) - 1"
                 [placeholder]="element.NullText ?? ''">
        <!-- Implemented as custom validator in NumberBox because the "valid" attribute is not working for reactive form validations -->
        <dx-validator>
            <dxi-validation-rule #numberBoxValidator type="custom"
                                 [message]="renderingInformation.validationInformation.validationMessage.message"
                                 [validationCallback]="isValid">
            </dxi-validation-rule>
        </dx-validator>
    </dx-number-box>
</div>
