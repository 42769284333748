import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { ValueChangedEvent } from 'devextreme/ui/number_box';
import { formatNumber, parseNumber } from 'devextreme/localization';
import { UntypedFormGroup } from '@angular/forms';
import { DxNumberBoxComponent } from 'devextreme-angular';
import Validator from "devextreme/ui/validator";
import { StringUtilsService } from '../../../../services/string-utils.service';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-number-box',
  templateUrl: './number-box.component.html',
  styleUrls: ['./number-box.component.less']
})
/**
 * Component dealing with the number related elements.
 */
export class NumberBoxComponent implements OnInit, AfterViewInit {

    @ViewChild('numberBoxValidator', { static: false }) public numberBoxInstance: DxNumberBoxComponent;

    @Input() element: Element;
    @Input() moduleIndex: number;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() labelPosition: string;
    @Input() currency: boolean;
    @Input() percentage: boolean;
    @Input() decimalDigits: number;

    public inputValue: number;

    public numberDisplayFormat: string;

    public renderingInformation: InputInformation;
    

    constructor(private validationService: ElementValidationService, private elementOperations: FormElementOperationsService, private stringUtils: StringUtilsService) {
        // Making the component context available in the validity check function callback
        this.isValid = this.isValid.bind(this);
    }

    /**
     * Initialization: setting input format depending on the component inputs.
     */
    ngOnInit(): void {
        this.numberDisplayFormat = this.stringUtils.getNumberTextFormat(this.currency, this.percentage, this.decimalDigits);
        this.setInputFromValue();
        this.inputFormGroup.get('displayValue').setValue(formatNumber(this.inputValue, this.numberDisplayFormat));
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
        this.inputValue);
        this.subscribeToElementChanges();
    }

    /**
     * After view initialization, the validator is taken here as instance for triggering the validation
     * from the beginning, just as the other elements. This case is special because of the uncompatibility
     * of the "valid" attribute with reactive forms (also happening in DateBox) and the fact that the validation
     * isn't automatically triggered after load (in DateBox this wasn't the case).
     */
    ngAfterViewInit() {
        const numberBoxValidator = Validator.getInstance(
            this.numberBoxInstance.instance.element()
        ) as Validator;
        numberBoxValidator?.validate();
    }

    /**
     * With each input change, updates the value in the reactive form structure and checks the
     * validity of the chosen value, updating also the validation message.
     * This method is necessary because of a DevExtreme limitation that prevents the "valid" attribute
     * from working with reactive forms validation.
     * @param e
     * @returns
     */
    isValid(e: any): boolean {
        this.inputFormGroup.get('value').setValue(e.value);
        return this.elementOperations.checkValue(this.inputFormGroup).valid;
    }

    /**
     * After a value was changed in the input, updates the display value in the reactive form structure
     * and also updates the validation summary with the potential errors that the value could have.
     * @param event
     */
    valueChanged(event: ValueChangedEvent) {
        this.inputValue = event.value;
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, event.value, formatNumber(event.value, this.numberDisplayFormat));
    }

    /**
     * Updates the input value according to the form of the element form control fromt he reative form structure.
     */
    private setInputFromValue() {
        const value = this.inputFormGroup.get('value').value;
        if (value) {
            if (typeof value === 'number') {
                this.inputValue = value;
            } else {
                // For imported values from an excel, the value comes in a formatted string and must be parsed
                this.inputValue = parseNumber(value as string, this.numberDisplayFormat);
            }
        }
    }


    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.inputValue);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.setInputFromValue();
                    this.inputFormGroup.get('displayValue').setValue(formatNumber(this.inputValue, this.numberDisplayFormat));
                }
            }
        })
    }

}
