import { Injectable } from '@angular/core';
import { ReactiveFormService } from './reactive-form.service';
import { DataInput } from '../../interfaces/form/data-input';
import { LayoutItem } from '../../interfaces/form/layout-item';
import { Element } from '../../interfaces/form/element';
import { Validation } from '../../interfaces/form/validation';
import { ValidationFunctionType } from '../../interfaces/form/enums/validation-function-type-enum';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidationSummaryService } from '../validation-summary.service';
import { Subject } from 'rxjs';
import { UIElementType } from '../../interfaces/form/enums/uielement-type-enum';

@Injectable({
  providedIn: 'root'
})
/**
 * This class implements all the services that are perform in order to
 * apply the form elements logic functions sent in the Validations attribute of the elements.
 */
export class ElementValidationService {

    // Summary of the validation of the overall form
    public validationMessages: { message: string, elementId: string }[] = [];

    // Summary of the validation of the currently opened table. NOTE: must be cleared on table open
    public tableValidationMessages: { message: string, elementId: string }[] = [];

    //Broadcasts a potential change in any attribute of the form control whose ID is broadcasted and
    //that belongs to the form structure
    public formElementChanged: Subject<{ elementId: string, functionType: ValidationFunctionType }> = new Subject();

    constructor(private formService: ReactiveFormService, private validationSummaryService: ValidationSummaryService) { }

    /**
     * The logic functions sent in the Validations attribute of a given element are applied by calling this method.
     * @param element with the element where the validation is applied
     * @param value with the value of the component representing the element
     * @param belongsToTableId indicating whether the table ID if element is placed in a table layout item. Null for
     * module elements outside tables
     */
    applyElementValidations(element: Element, value: unknown[], belongsToTableId: string) {
        element.Validations.forEach((validation) => {
            if (validation.TargetElementID && validation.Condition) {
                this.applyValidation(validation, this.prepareValueString(value, element.UIElement), belongsToTableId);
            }
        })
    }


    /**
     * Transform the unknown type input value to a string ready to be compared against the element's
     * validation conditions.
     * @param value
     * @param elementInputType
     * @returns
     */
    private prepareValueString(value: unknown[], elementInputType: UIElementType): string[] {
        let valuesString: string[] = [];
        if (value) {
            valuesString = value.map((v:string) => {
                if (v != null) {
                    return String(v);
                } else {
                    return "null";
                }
            });
        } else {
            if (elementInputType === UIElementType.CheckEditLeftLabel ||
                elementInputType === UIElementType.CheckEditRightLabel ||
                elementInputType === UIElementType.CheckEditRightLabelDescription) {
                // Case of a checkbox with null value, it is considered as false in the validation
                valuesString = ["false"];
            } else {
                valuesString = ["null"];
            }
            
        }
        return valuesString;
    }

    /**
     * Transforms the value from an UPDATE validation type into a valid format, when required.
     * @param value
     * @returns
     */
    private prepareStringValue(value: unknown): unknown {
        let transformedValue: unknown;        
        if (value) {

            if (value.toString().toLowerCase() === "null") {
                transformedValue = null;
            } else if (value.toString().toLowerCase() === "false") {
                transformedValue = false;
            } else if (value.toString().toLowerCase() === "true") {
                transformedValue = true;
            } else {
                transformedValue = value;
            }
            
        }
        return transformedValue;
    }

    /**
     * Applies a logic function. Assumes that the logic function in the Validations array element has a condition,
     * otherwise it could lead to errors.
     * @param validation
     * @param valuesString string version of the chosen element input value
     * @param belongsToTable indicating whether the table ID if element is placed in a table layout item. Null for
     * module elements outside tables
     */
    private applyValidation(validation: Validation, valuesString: string[], belongsToTableId: string) {
        const triggerAction = this.isActionToBeTriggered(validation, valuesString);

        switch (validation.FunctionType) {
            case ValidationFunctionType.UIVisible:
                if (triggerAction) {
                    this.formService.showElementFormGroup(validation.TargetElementID, belongsToTableId);
                } else {
                    this.formService.hideElementFormGroup(validation.TargetElementID, belongsToTableId);
                }
                break;
            case ValidationFunctionType.UIEnable:
                if (this.formService.enableElementValueFormControl(validation.TargetElementID, triggerAction, belongsToTableId)) {
                    // Broadcast that there was a change for the form elements to be aware and take action if needed
                    this.formElementChanged.next({ elementId: validation.TargetElementID, functionType: ValidationFunctionType.UIEnable });
                }
                break;
            case ValidationFunctionType.PlausiRequired:
                if (this.formService.controlElementRequiredAttribute(validation.TargetElementID, triggerAction, belongsToTableId)) {
                    // Broadcast that there was a change for the form elements to be aware and take action if needed
                    this.formElementChanged.next({ elementId: validation.TargetElementID, functionType: ValidationFunctionType.PlausiRequired });
                }
                break;
            case ValidationFunctionType.Update:
            case ValidationFunctionType.InteractiveUpdate: {
                if (triggerAction) {
                    if (this.formService.updateElementFormControlValue(validation.TargetElementID, this.prepareStringValue(validation.Value), belongsToTableId)) {
                        // Broadcast that there was a change for the form elements to be aware and take action if needed.
                        // For Webforms, InteractiveUpdate and Update are the same, soy both are set as Update
                        this.formElementChanged.next({ elementId: validation.TargetElementID, functionType: ValidationFunctionType.Update });
                    }
                }
                break;
            }
        }
    }

    /**
     * Determines if an action is to be triggered according to the current value of the elemnt and the conditions for its validation.
     * @param validation
     * @param valuesString
     * @returns
     */
    private isActionToBeTriggered(validation: Validation, valuesString: string[]): boolean {
       // const testCondition = "!1, 2, NULL, !(3,4,5), !7, 8";
        const conditions = validation.Condition.split(',').map((cond) => {
            let transformedCondition = cond.toLowerCase();
            if (transformedCondition.startsWith("not ")) {
                // Sometimes negated conditions come as "NOT <condition>"
                transformedCondition = transformedCondition.replace("not ", "!");
            }
            return transformedCondition;
        });
        
        // This flag is created for indicating that there's a negation group !(a, b, c)
        let isInNegatedGroup = false;
        // This flag will be activated when the value meets any condition (either positive or negative)
        // and therefore the evaluation must be stopped
        let metCondition = false;
        // This flag will be activated when the condition met is positive and therefore action is to be executed
        let triggerAction = true;

        for (let condition of conditions) {
            // Case of the first element of a negated group in a condition
            if (condition.startsWith("!(")) {
                isInNegatedGroup = true;
                condition = condition.substring(condition.indexOf("!(") + 2);
                if (condition.endsWith(")")) {
                    condition = condition.substring(0, condition.indexOf(")") - 1);
                    isInNegatedGroup = false;
                }
                const conditionChosen = this.isConditionChosen(condition, valuesString);
                if (conditionChosen) {
                    metCondition = true;
                    triggerAction = false;
                }
            // Case of a single negated element
            } else if (condition.startsWith("!")) {
                condition = condition.substring(1);
                const conditionChosen = this.isConditionChosen(condition, valuesString);
                if (conditionChosen) {
                    metCondition = true;
                    triggerAction = false;
                }
            // Case of an element in a negated group that it is not the first one
            } else if (isInNegatedGroup) {
                if (condition.endsWith(")")) {
                    condition = condition.substring(0, condition.indexOf(")"));
                    isInNegatedGroup = false;
                }
                const conditionChosen = this.isConditionChosen(condition, valuesString);
                if (conditionChosen) {
                    triggerAction = false;
                    metCondition = true;
                }
            // Case of a not negated, simple condition
            } else {
                triggerAction = this.isConditionChosen(condition, valuesString);
                if (triggerAction) {
                    metCondition = true;
                }
            }
            if (metCondition) {
                break;
            }
        }
        return triggerAction;
    }

    /**
     * Determines if an input value deals with a condition element of a validation
     * @param condition
     * @param value
     * @returns
     */
    private isConditionChosen(condition: string, value: string[]): boolean {
        return value.find((v) =>
            v.toString() === condition.trim()
        ) !== undefined
    }

    /**
     * Updates the validation summary for a form or a table add/edit row form. In case of tables, requires
     * the table ID to which the input element belongs and the module index.
     * @param belongsToTableId
     * @param moduleIndex
     */
    updateValidationSummary(belongsToTableId: string, moduleIndex: number) {
        if (belongsToTableId) {
            if (this.tableValidationMessages.length > 0) {
                this.setValidationSummaryForTableId(belongsToTableId, moduleIndex);
            }
        } else if (this.validationMessages.length > 0) {
            this.setValidationSummary();
        }
    }

    /**
     * It sets a set of messages for each field that requires validation and this validation has failed.
     */
    public setValidationSummary() {
        const formControlsToBeValidated: { name: string, id: string, formControl: UntypedFormControl, elementValidationMessages: { validationFn: string, message: string }[] }[] = [];
        if (this.formService.getReactiveForm()) {
            this.formService.modulesArray.controls.forEach((module, moduleIndex) => {
                this.formService.getLayoutItemsArrayFromModuleNumber(moduleIndex).controls.forEach((layoutItemGroup) => {
                    const elementValue = layoutItemGroup.get('value') as UntypedFormControl;
                    if (elementValue && !elementValue.valid && !elementValue.disabled && !layoutItemGroup.get('avoidSubmitting').value && layoutItemGroup.get('visible').value) {
                        formControlsToBeValidated.push({
                            name: layoutItemGroup.get('label').value, id: "input-" + layoutItemGroup.get('id').value,
                            formControl: elementValue,
                            elementValidationMessages: layoutItemGroup.get("validationMessages").value
                        });
                    }
                })
            })
        }
        this.validationMessages = this.validationSummaryService.createValidationMessagesForFormControlsAndNames(formControlsToBeValidated);
    }


    /**
     * Gets the validation message corresponding to an specific element from the validation summary
     * messages list.
     * @param elementFormGroup with the form group corresponding to the input edit element
     * @returns
     */
    public getValidationMessageForElementFormGroup(elementFormGroup: AbstractControl): string {
        return this.validationSummaryService.createSummaryError((elementFormGroup.get('value') as UntypedFormControl).errors, elementFormGroup.get('validationMessages').value);
    }

    /**
     * It sets a set of messages for each field of the table passed in the parameters that requires validation
     * and this validation has failed.
     * @param tableid
     * @param moduleIndex
     */
    public setValidationSummaryForTableId(tableId: string, moduleIndex: number) {
        this.clearValidationSummaryForTable();
        const formControlsToBeValidated: { name: string, id: string, formControl: UntypedFormControl, elementValidationMessages: { validationFn: string, message: string }[] }[] = [];
        const tableEditElementsFormGroups = this.formService.searchTableEditElementFormGroupsByTableId(tableId, moduleIndex);
        if (tableEditElementsFormGroups) {
            tableEditElementsFormGroups.forEach((layoutItemGroup) => {
                const elementValue = layoutItemGroup.get('value') as UntypedFormControl;
                if (elementValue && !elementValue.valid && !elementValue.disabled && layoutItemGroup.get('avoidSubmitting').value && layoutItemGroup.get('visible').value) {
                    formControlsToBeValidated.push({
                        name: layoutItemGroup.get('label').value,
                        id: "input-" + layoutItemGroup.get('id').value,
                        formControl: elementValue,
                        elementValidationMessages: layoutItemGroup.get("validationMessages").value
                    });
                }
            })
        }
        this.tableValidationMessages = this.validationSummaryService.createValidationMessagesForFormControlsAndNames(formControlsToBeValidated);
    }

    /**
     * Clears the validation messages for the currently opened table. This function must be executed when
     * opening the add/edit row form of a table.
     */
    public clearValidationSummaryForTable() {
        this.tableValidationMessages = [];
    }
}
