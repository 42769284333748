export enum UIElementType {
    none = 0,
    TextEdit = 1,
    CheckEditLeftLabel = 2,
    DateEdit = 3,
    MemoEdit = 4,
    ComboBoxEdit = 5,
    LookupEdit = 6,
    TextWrapEdit = 7,
    ICD10Catalog = 8,
    ListBoxCheckBoxes = 9,
    Label = 10,
    Separator = 11,
    Table = 12,
    CheckEditRightLabel = 13,
    Link = 15,
    LookupEditTree = 16,
    LookupEditTreeCheckBoxes = 17,
    FunctionButton = 18,
    AutoID = 19,
    ComboBoxEditCheckBoxes = 20,
    LabelUnderlineRightAlignment = 21,
    Map = 22,
    Calculated = 23,
    DateEditFixedWidth = 24,
    ListBoxCheckBoxesWithAllSelect = 25,
    ListBoxCheckBoxesWithSearch = 26,
    LookupEditWithSearch = 27,
    Currency = 28,
    DecimalScale2 = 29,
    LookupEditShowValue = 30,
    LookupEditExtValueAndDisplayName = 31,
    DecimalScale4 = 32,
    Percent = 33,
    CheckEditRightLabelDescription = 34,
    DateEditWithTime = 35,
    FileUpload = 38
}
