import { Injectable } from '@angular/core';
import { Item } from '../interfaces/form/item';
import { ElementItemsSortMode } from '../interfaces/form/enums/element-items-sort-mode-enum';

@Injectable({
  providedIn: 'root'
})

/**
 * This class is in charge of centralizìng the operations related to the
 * Item array belonging to an Element object.
 */
export class ItemService {

    /**
     * Sort the selectable items of an element by their sort attribute.
     * @param itemsToSort
     * @returns
     */
    public sortItemsBySortAttribute(itemsToSort: Item[], itemsSortMode: ElementItemsSortMode): Item[] {
        if (itemsSortMode === ElementItemsSortMode.SortNr) {
            return itemsToSort.sort((a, b) => {
                if (a.Sort > b.Sort) {
                    return 1
                } else if (a.Sort < b.Sort) {
                    return -1
                } else {
                    return 0
                }

            })
        } else if (itemsSortMode === ElementItemsSortMode.Alphabetic) {
            return itemsToSort.sort(function (a, b) {
                return a.DisplayName.toLowerCase().localeCompare(b.DisplayName.toLowerCase());
            })
        } else {
            return itemsToSort;
        }
    }
}
