import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.less']
})
/**
 * This class as a controller for the validation summary component. 
 */
export class ValidationSummaryComponent {

    @Input() validationMessages: { message: string, elementId: string }[];
    @Input() addAnchorNavigation: boolean;

    /**
     * For instances of this component where the addAnchorNavigation has been set in the inputs,
     * listens to the click event in the list of failed inputs and scrolls to them.
     * @param id of the element to scroll to
     */
    public navigateToId(id: string) {
        console.log("Scroll to " + id);
        // Scrolling implemented via standard javascript instead of using Angular's
        // ViewportScroller due to the latter not working as desired
        const scrollTarget = document.getElementById(id);
        if (scrollTarget) {
            scrollTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
            // Adding focus to the input element where we just scrolled to
            const inputElements = scrollTarget.getElementsByTagName("input");
            if (inputElements) {
                for (let i = 0; i < inputElements.length; i++) {
                    if (inputElements[i].classList.contains("dx-texteditor-input")) {
                        inputElements[i].focus();
                    }
                }
            }
        }
        
    }
}
