import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// Dictionaries for German language
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { BrandingService } from './services/branding.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {


    constructor(private brandingService: BrandingService, private argChangeDetector: ChangeDetectorRef, private argTitle: Title) {
        loadMessages(deMessages);
        //locale(navigator.language);
        locale("de");
    }

    public ngOnInit() {
        this.brandingService.title.subscribe((argTitle) => {
            this.argChangeDetector.detectChanges();
            this.argTitle.setTitle(argTitle);
        });
    }
}
