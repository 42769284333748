import { Component, OnInit, Input } from '@angular/core';
import { formatMessage } from 'devextreme/localization';
import { Element } from '../../../../interfaces/form/element';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { BeforeSendEvent, UploadedEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { AuthenticationService } from '../../../../services/Authentication.Service';
import { UntypedFormGroup } from '@angular/forms';
import { LabelService } from '../../../../services/form/label.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.less']
})
/**
 * This file acts as a controler of the file uploaders in the form.
 */
export class FileUploaderComponent implements OnInit {

    private UPLOAD_CHUNK_SIZE = 10000;

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;

    public displayLabel: boolean;
    public labelClass: string;

    public selectFileText: string;
    public dropFileText: string;
    public acceptTypes: string;
    public maxFileSize: number;
    public multiple: boolean;

    private belongsToTableId: string;

    public uploadedFiles: File[] = [];

    public errorSummaryMessages: { message: string, elementId: string }[] = [];

    constructor(private validationService: ElementValidationService, private argAuthenticationService: AuthenticationService, private labelService: LabelService) { }

    /**
     * Initialization method where the dxFileUploader component is configured.
     */
    ngOnInit(): void {
        this.selectFileText = formatMessage("dxFileUploader-selectFile");
        this.dropFileText = formatMessage("dxFileUploader-dropFile");
        this.belongsToTableId = this.inputFormGroup.get('belongsToTableId')?.value;
        this.acceptTypes = this.element.FileUploadExtensions ?? "*";
        this.maxFileSize = this.element.FileUploadMaxSize ? this.element.FileUploadMaxSize * 1000000 : 5000000;
        this.multiple = this.element.FileUploadMultiSelect;
        this.displayLabel = this.labelService.isLabelToBePrinted(this.element);
        this.labelClass = this.labelService.getLabelHtmlClass(this.inputFormGroup, this.labelPosition);
        // When a table add/edit form is initialized, the validations must be applied for the initial input value
        if (this.element.Validations && this.belongsToTableId) {
            this.validationService.applyElementValidations(this.element, this.uploadedFiles.map((file) => {
                return file.name;
            }), this.inputFormGroup.get("belongsToTableId").value);
        }
    }

    /**
     * It handles the onBeforeLoad event from the dxFileUploader component and it is meant to customize
     * the POST request before being sent for uploading the selected files.
     * @param e
     */
    customizeUploadRequest(e: BeforeSendEvent) {
        // HttpClient isn't natively supported by Devextreme, therefor the interceptors aren't the recommended way
        // of adding the credentials for the current upload request and the same logic from the interceptor should
        // be applied here
        const currentUser = this.argAuthenticationService.CurrentUser;
        if (currentUser) {
            e.request.withCredentials = true;
            e.request.setRequestHeader('Authorization', 'Bearer ' + currentUser.AccessToken);
        }
    }

    /**
     * Function that is executed each time that a file is uploaded. Submits the data
     * to the reactive structure form and initializes validations.
     * @param e
     */
    submitUploadedFileInfo(e: UploadedEvent) {
        this.uploadedFiles.push(e.file);
        this.submitAndValidate();
    }

    /**
     * This function handles the selection changes (either the user selects a file for uploading or clicks
     * in the delete button). If the selected files are less in size than the previously selected, then a file
     * was selected for being deleted by the user.
     * @param e
     */
    deleteUploadedFile(e: ValueChangedEvent) {
        // If the previous array of selected files is larger than the current, potentially a file was deleted
        // by the user from the selection
        if (e.previousValue.length > e.value.length) {
            e.previousValue.forEach((file) => {
                const foundFile: File = e.value.find((uploadedFile) => {
                    return file.name === uploadedFile.name;
                })
                // If a file isn't in the previous values, it means that it was deleted by the user
                if (!foundFile) {
                    // Proceed here to delete the file in the corresponding service
                    // ...
                    this.uploadedFiles = this.uploadedFiles.filter((uploadedFile) => {
                        return file.name !== uploadedFile.name
                    });
                    this.errorSummaryMessages = [];
                    this.submitAndValidate();
                }
            })
        } else {
            if (e.value.length) {
                const newSelectedFiles: File[] = e.value.slice(e.previousValue.length);
                this.errorSummaryMessages = [];
                let filesUploadedCounter = 0;
                newSelectedFiles.forEach((selectedFile) => {
                    const alreadyUploadedFile: File = this.uploadedFiles.find((uploadedFile) => {
                        return uploadedFile.name === selectedFile.name;
                    });
                    // If a file was already uploaded, don't include it in the list
                    if (alreadyUploadedFile) {
                        const cancelButton = document.getElementsByClassName("dx-fileuploader-cancel-button")[e.previousValue.length + filesUploadedCounter];
                        const cancelUploadClickEvent = new Event('click');
                        cancelButton.dispatchEvent(cancelUploadClickEvent);
                        this.errorSummaryMessages.push({ message: alreadyUploadedFile.name + " würde bereits hochgeladen", elementId: this.element.ElementID });
                    } else {
                        filesUploadedCounter++;
                    }
                })
            } 
           
        }
    }

    /**
     * Sends back the value and display values for updating the reactive form and triggers the validations.
     */
    private submitAndValidate() {
        const fileNames: string[] = this.uploadedFiles.map((file) => {
            return file.name;
        });
        this.inputFormGroup.get('value').setValue(null);
        this.inputFormGroup.get('displayValue').setValue(fileNames.join("<br>"));
        if (this.element.Validations) {
            this.validationService.applyElementValidations(this.element, fileNames, this.inputFormGroup.get("belongsToTableId").value);
        }
    }
}
