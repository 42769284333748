<div [ngClass]="'form-editor'">
    <dx-list ngDefaultControl [attr.id]="'input-' + element.ElementID" [dataSource]="sortedItems" displayExpr="DisplayName"
             keyExpr="Value" [searchEnabled]="search" [searchExpr]="['DisplayName', 'Value']"
             searchMode="contains" [selectionMode]="selectionMode" [showSelectionControls]="true"
             (onSelectionChanged)="changeListSelection($event)" selectAllMode="allPages" [(selectedItems)]="selectedItems">
        <ng-container *ngIf="element.NullText">
            <dxo-search-editor-options placeholder="element.NullText">
            </dxo-search-editor-options>
        </ng-container>
    </dx-list>
</div>
