import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { Item } from '../../../../interfaces/form/item';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { ItemService } from '../../../../services/item.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit {


    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;
    @Input() search: boolean;
    @Input() displaySelectAll: boolean;

    constructor(private validationService: ElementValidationService, private stringUtils: StringUtilsService, private itemService: ItemService, private elementOperations: FormElementOperationsService) { }

    public sortedItems: Item[] = [];
    public selectedItems: Item[] = [];

    public renderingInformation: InputInformation;

    public selectionMode: string;

    /**
     * Init method where the list values are set.
     */
    ngOnInit(): void {
        const elementItems: Item[] = this.element.Items as Item[];
        if (elementItems.length) {
            this.sortedItems = this.itemService.sortItemsBySortAttribute(elementItems, this.element.ElementItemsSortMode);
            // If there is an initial value, take it from the form control value and set it in the items array
            this.setInputFromValue();
            this.inputFormGroup.get('displayValue').setValue(this.stringUtils.convertItemsArrayToItemsDisplayName(this.selectedItems));
        }
        if (this.displaySelectAll) {
            this.selectionMode = "all";
        } else {
            this.selectionMode = "multiple";
        }
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
            this.selectedItems.map((item: Item) => { return item.Value }));
        this.subscribeToElementChanges();
    }


    /**
     * Updates the input value according to the form of the element form control fromt he reative form structure.
     */
    private setInputFromValue() {
        const elementValue = this.inputFormGroup.get("value")?.value;
        if (elementValue) {
            const selectedValues = elementValue as string[];
            selectedValues.forEach((selectedValue: string) => {
                this.selectedItems.push(this.sortedItems.find((sortedItem) => sortedItem.Value === selectedValue));
            })
        }
    }

    /**
     * Event listener when the user performs a selection.
     * @param e
     */
    changeListSelection(e: any) {
        const selectedValues = this.selectedItems.map((item: Item) => { return item.Value });
        const selectedDisplayNames = this.stringUtils.convertItemsArrayToItemsDisplayName(this.selectedItems);
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, selectedValues, selectedDisplayNames);
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup,
                    this.selectedItems.map((item: Item) => { return item.Value }));
                if (this.renderingInformation.updatedAfterOperation) {
                    const newValueArray = Array.isArray(this.renderingInformation.updatedValueAfterOperation) ?
                        this.renderingInformation.updatedValueAfterOperation : [this.renderingInformation.updatedValueAfterOperation];
                    this.inputFormGroup.get('value').setValue(newValueArray);
                    this.setInputFromValue();
                    this.inputFormGroup.get('displayValue').setValue(this.stringUtils.convertItemsArrayToItemsDisplayName(this.selectedItems));
                }
            }
        })
    }
}
