import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserClientService } from '../../client/user-client.service';
import { UserSessionService } from '../../services/user-session.service';
import { ValidationSummaryService } from '../../services/validation-summary.service';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { formatMessage } from 'devextreme/localization';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
/**
 * This component acts as a controler for the login page layout.
 */
export class LoginPageComponent implements OnInit {

    private returnUrl: string;

    // Reactive form
    public loginForm: UntypedFormGroup;

    // Summary of the validation
    public validationMessages: { message: string, elementId: string }[] = [];

    constructor(private router: Router, private userSessionService: UserSessionService,
        private userClient: UserClientService, private validationSummaryService: ValidationSummaryService) { }

    /**
     * Initialization method: defines the return URL based in the query params in the URL.
     */
    ngOnInit(): void {
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormGroup({
                value: new UntypedFormControl('', [Validators.required, Validators.email]),
                messages: new UntypedFormControl([])
            })
        })
        this.loginForm.get("email").get("messages").value.push({ validationFn: "email", message: formatMessage("validation-email") });
        this.loginForm.get("email").get("messages").value.push({ validationFn: "required", message: formatMessage("validation-required-formatted", "Email") });

        const currentUrlTree = this.router.parseUrl(this.router.url);
        if (currentUrlTree.queryParams['returnUrl']) {
            this.returnUrl = Buffer.from(currentUrlTree.queryParams['returnUrl'], 'base64').toString();
        }
    }

    /**
     * This method is triggered by the changes in the input fields and updates the validation messages.
     * @param event
     */
    valueChanged(event: ValueChangedEvent) {
        if (!this.loginForm.valid) {
            this.updateValidationMessages();
        }
    }

    /**
     * Receives the submit request by the user, tries to authenticate the user with the credentials
     * and proceeds according to the results.
     */
    public loginSubmit() {
        
        if (this.loginForm.valid) {
            this.userClient.authenticateUser().subscribe((authenticationInfo) => {
                this.userSessionService.currentUser = authenticationInfo.user;
                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigateByUrl('/');
                }
            });
        } else {
            this.updateValidationMessages();
        }
    }


    /**
     * Updates the validation messages that are to be sent to the validation summary.
     */
    private updateValidationMessages() {
        this.validationMessages = this.validationSummaryService.createValidationMessagesForFormGroup(this.loginForm);
    }
}
