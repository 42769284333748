import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationSummaryService {

    /**
     * Given the current status of the form group sent as a parameter, this method returns an array
     * with the validation messages to be print in a validation summary.
     * @param formGroup
     */
    public createValidationMessagesForFormGroup(formGroup: UntypedFormGroup): { message: string, elementId: string }[] {
        const validationMessages: { message: string, elementId: string }[] = [];
        Object.keys(formGroup.controls).forEach((formFieldGrouplKey) => {
            const formFieldErrors: ValidationErrors = formGroup.get(formFieldGrouplKey).get("value").errors;
            if (formFieldErrors) {
                const fieldValidationMessage = this.createSummaryError(formFieldErrors, formGroup.get(formFieldGrouplKey).get("messages").value);
                if (fieldValidationMessage) {
                    validationMessages.push({ message: fieldValidationMessage, elementId: formFieldGrouplKey });
                }
            }
        })
        return validationMessages;
    }

    /**
     * For form controls that don't have the name to appear in the validation messages,
     * given the current status of the form controls sent as a parameter in the FormControl/name pairs array,
     * this method returns an array with the validation messages to be printed in a validation summary and their IDs.
     * @param formGroup
     */
    public createValidationMessagesForFormControlsAndNames(formControlNamesArray: { name: string, id: string, formControl: UntypedFormControl, elementValidationMessages: { validationFn: string, message: string }[] }[]): { message: string, elementId: string }[] {
        const validationMessages: { message: string, elementId: string }[] = [];
        formControlNamesArray.forEach((formElement) => {
            const formFieldErrors: ValidationErrors = formElement.formControl.errors;
            if (formFieldErrors) {
                const fieldValidationMessage = this.createSummaryError(formFieldErrors, formElement.elementValidationMessages);
                if (fieldValidationMessage) {
                    validationMessages.push({ message: fieldValidationMessage, elementId: formElement.id });
                }
            }
        })
        return validationMessages;
    }

    /**
     * Given a validation error and a key of a form control, creates a printable validation message.
     * @param formFieldErrors
     * @param formFieldKey
     */
    public createSummaryError(formFieldErrors: ValidationErrors, validationMessages: { validationFn: string, message: string }[]): string {
        let fieldValidationMessage: string;
        Object.keys(formFieldErrors).forEach((fieldError) => {
            fieldValidationMessage = validationMessages.find(m => m.validationFn === fieldError)?.message;
        })
        return fieldValidationMessage;
    }
}
