import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { ValueChangedEvent } from 'devextreme/ui/text_area';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-text-wrap-edit',
  templateUrl: './text-wrap-edit.component.html',
  styleUrls: ['./text-wrap-edit.component.less']
})
/**
* This component implements the logic related to the text wrap edit fields.
*/
export class TextWrapEditComponent implements OnInit {

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;

    inputValue: string;

    public renderingInformation: InputInformation;

    constructor(private validationService: ElementValidationService, private elementOperations: FormElementOperationsService) { }

    ngOnInit(): void {
        this.inputValue = typeof this.inputFormGroup.get("value")?.value === 'string' ? this.inputFormGroup.get("value")?.value : "";
        this.inputFormGroup.get('displayValue').setValue(this.inputValue);
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
            this.inputValue);
        this.subscribeToElementChanges();
    }

    /**
     * Emits as an output the new value of the text wrap edit field.
     * @param e
     */
    valueChanged(e: ValueChangedEvent) {
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, e.value, e.value);
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.inputValue);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.inputValue = this.renderingInformation.updatedValueAfterOperation as string;
                    this.inputFormGroup.get('displayValue').setValue(this.inputValue);
                }
            }
        })
    }
}
