import { Component, OnInit, Input } from '@angular/core';
import { Element } from '../../../../interfaces/form/element';
import { Item } from '../../../../interfaces/form/item';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { StringUtilsService } from '../../../../services/string-utils.service';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { ItemService } from '../../../../services/item.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-lookup-edit-datagrid',
  templateUrl: './lookup-edit-datagrid.component.html',
  styleUrls: ['./lookup-edit-datagrid.component.less']
})

    /**
    * Component belonging to the drop down box form elements that use a DxDataGrid for listing.
    */
export class LookupEditDatagridComponent implements OnInit {

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;
    @Input() checkBoxes: boolean;

    public sortedItems: Item[] = [];
    private _selectedItem: string[];

    public renderingInformation: InputInformation;

    public isDropDownBoxOpened = false;

    constructor(private validationService: ElementValidationService, private stringUtils: StringUtilsService,
        private itemService: ItemService, private elementOperations: FormElementOperationsService) { }

    ngOnInit(): void {
        const elementItems: Item[] = this.element.Items as Item[];
        if (elementItems.length) {
            this.sortedItems = this.itemService.sortItemsBySortAttribute(elementItems, this.element.ElementItemsSortMode);
            // If there is an initial value, take it from the form control value and set it in the item values array
            const elementValue = this.inputFormGroup.get("value")?.value;
            if (elementValue) {
                this._selectedItem = elementValue as string[];
                this.inputFormGroup.get("value").setValue(this._selectedItem);
                this.inputFormGroup.get('displayValue').setValue(this.getDisplayValue());
            }
        }
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
            this.selectedItem);
        this.subscribeToElementChanges();
    }
    
    /**
     * Event listener when the user performs a selection in the datagrid. The selecion is assigned and the list of options is hidden.
     * @param e
     */
    changeLookupEditValue(e: SelectionChangedEvent) {
        this.isDropDownBoxOpened = false;
        
        this._selectedItem = e.currentSelectedRowKeys;
        
        
    }

    /**
     * Event listener after a value is selected and the value of the drop down box changes consequently. The value submit
     * and validation rules application are performed here.
     * @param e
     */
    submitLookupEditValue(e: ValueChangedEvent) {
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, this._selectedItem, this.getDisplayValue());
    }

    /**
     * Getter method of the selection.
     */
    get selectedItem(): string[] {
        return this._selectedItem;
    }

    /**
     * Setter method of the selection.
     */
    set selectedItem(value: string[]) {
        this._selectedItem = value;
    }


    /**
     * Gets the display value form control of this input element's form group.
     * @param valuesArray
     */
    private getDisplayValue() {
        let displayValue: string = null;
        if (this.selectedItem) {
            const userSelectedItems: Item[] = [];
            this.selectedItem.forEach(((item) => {
                const foundSelectedItem: Item = this.sortedItems.find((elementItem) => elementItem.Value === item);
                userSelectedItems.push(foundSelectedItem);
            }));
            if (this.checkBoxes) {
                displayValue = this.stringUtils.convertItemsArrayToItemsDisplayName(userSelectedItems);
            } else if (userSelectedItems.length) {
                displayValue = userSelectedItems[0].DisplayName;
            }
        } else {
            displayValue = "";
        }
        return displayValue;
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this._selectedItem);
                if (this.renderingInformation.updatedAfterOperation) {
                    this._selectedItem = Array.isArray(this.renderingInformation.updatedValueAfterOperation) ?
                        this.renderingInformation.updatedValueAfterOperation : [this.renderingInformation.updatedValueAfterOperation];
                    this.inputFormGroup.get('value').setValue(this._selectedItem);
                    this.inputFormGroup.get('displayValue').setValue(this.getDisplayValue());
                }
            }
        })
    }
}
