import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 * This service is aimed to contain all the data (and provider methods of that data) that needs to be shared
 * in between different views (pages) and cannot be passed as routper parameters due to the inconvenience of
 * giving access to the user via URL parameters.
 */
export class ViewStateDataService {

    // states whether the user successfully submitted a form
    private _formSucess: boolean;

    /**
     * Getter method form formSuccess.
     * @returns
     */
    public getFormSuccess(): boolean {
        return this._formSucess === true;
    }

    /**
     * Setter method form formSuccess.
     * @returns
     */
    public setFormSuccess(formSuccess: boolean) {
        this._formSucess = formSuccess;
    }
}
