import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { LabelService } from '../../../../services/form/label.service';
import { Element } from '../../../../interfaces/form/element';
import { ReactiveFormService } from '../../../../services/form/reactive-form.service';
import { ValidationFunctionType } from '../../../../interfaces/form/enums/validation-function-type-enum';
import { LabelInformation } from '../../../../interfaces/form/elements/label-information';
import { UIElementType } from '../../../../interfaces/form/enums/uielement-type-enum';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.less']
})
export class LabelComponent implements OnInit {

    @Input() labelText: string;
    @Input() element: Element;
    @Input() labelPosition: string;

    public inputFormGroup: UntypedFormGroup;

    public labelInformation: LabelInformation;

    constructor(public formService: ReactiveFormService, private validationService: ElementValidationService, private labelService: LabelService) { }

    ngOnInit(): void {
        if (this.element) {
            if (this.element.UIElement !== UIElementType.Table) { 
                this.inputFormGroup = this.formService.searchElementFormGrouplInReactiveFormByElementId(this.element.ElementID, false) as UntypedFormGroup;
                this.labelInformation = this.labelService.getLabelInformation(this.element, this.inputFormGroup, this.labelPosition);
                    this.subscribeToElementChanges();
            } else {
                this.inputFormGroup = this.formService.searchElementFormGrouplInReactiveFormByElementId(this.element.ElementTableID, true) as UntypedFormGroup;
                this.labelInformation = { displayLabel: true, labelClass: "" };
            }
        } 
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: { elementId: string, functionType: ValidationFunctionType }) => {
            if (data.elementId === this.element.ElementID) {
                if (data.functionType === ValidationFunctionType.PlausiRequired || data.functionType === ValidationFunctionType.UIEnable) {
                    // The label info is updated for a required element
                    this.labelInformation = this.labelService.getLabelInformation(this.element, this.inputFormGroup, this.labelPosition);
                } 
                
            }
        })
    }

}
