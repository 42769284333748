import { Injectable } from '@angular/core';
import { Item } from '../interfaces/form/item'
import { UIElementType } from '../interfaces/form/enums/uielement-type-enum';
import { formatNumber, parseNumber } from 'devextreme/localization';

@Injectable({
    providedIn: 'root'
})

/**
 * This class is meant to contain string related operations that may be useful in the code overall.
 * Please, add here all the operations that are added to the code related to strings.
 */
export class StringUtilsService {

    public readonly euroSuffix = " €";
    public readonly percentageSuffix = " %";
    public readonly currencyFormat = "#,###.##" + this.euroSuffix;
    public readonly percentageFormat = "#,###.##" + this.percentageSuffix;
    public readonly fourDigitsDecimalFormat = "#,###.####";
    public readonly twoDigitsDecimalFormat = "#,###.##";

    /**
     * Converts a string stating yes or no to a boolean. This is useful
     * for converting presentation data to form logic.
     * @param yesNoString
     * @returns
     */
    convertYesNoStringToBoolean(yesNoString: string): boolean {
        if (yesNoString.toLowerCase() === 'true' || yesNoString.toLowerCase() === 'ja' || yesNoString.toLowerCase() === '1') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Converts a booelan to a string stating yes or no. This is useful
     * for converting form logic to presentation data.
     * 
     * @param booleanValue
     * @returns
     */
    convertBooleanToYesNoString(booleanValue: boolean): string {
        let returnedString = "Nein";
        if (booleanValue) {
            returnedString = "Ja";
        }
        return returnedString;
    }

    /**
     * Converts a string representing a date with or without time to an object that can be
     * used in date edit fields.
     * @param displayDateString
     */
    convertDateStringToDate(displayDateString: string): Date {
        const [dateString, timeString] = displayDateString.split(' ');
        const [day, month, year]: string[] = dateString.split('.');
        let hour, minute: string;
        if (timeString) {
            [hour, minute] = timeString.split(':');
        }

        return new Date(+year, +month - 1, +day, timeString ? parseInt(hour) : 0, timeString ? +minute : 0, 0, 0);
    }

    /**
    * Converts a number representing a date with or without time to an object that can be
    * used in date edit fields.
    * @param displayDateString
    */
    convertDateNumberToDate(displayDateNumber: number): Date {
        return new Date(displayDateNumber);
    }


    /**
     * Converts a date (and optionally time)  object into a representable string
     * in German format.
     * @param dateData with the date to be converted
     * @param includeTime stating if the time of the day has to be included in the string
     */
    convertDateToDateString(dateData: Date, includeTime: boolean): string {
        let displayValue: string = dateData.toLocaleDateString("DE", {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        if (includeTime) {
            displayValue = displayValue.concat(" ").concat(dateData.getHours().toString()).concat(":").concat(dateData.getMinutes().toString());
        }
        return displayValue;
    }

    /**
     * Converts a string containing the names of the chosen items of an element
     * in a table cell to a string containing the chosen items values.
     * @param itemsString
     * @param items
     * @returns
     */
    convertTableCellDisplayNamesToValues(itemsString: string, items: Item[]): string[] {
        const chosenItemsDisplay: string[] = itemsString.split(" | ");
        const chosenItemsValue: string[] = [];
        if (chosenItemsDisplay) {
            chosenItemsDisplay.forEach((chosenItemDisplay) => {
                const chosenItem = items.find((item) => item.DisplayName === chosenItemDisplay);
                if (chosenItem) { 
                    chosenItemsValue.push(chosenItem.Value);
                }
            })
        }
        if (chosenItemsValue.length) {
            // Angular needs to wrap array values for form controls into another array for not
            // confusing the array values with form group initialization
            return chosenItemsValue;
        } else {
            return [];
        }
    }

    /**
     * Converts an array of items into a display ready string suitable for the cells in the table elements.
     * @param items
     * @returns
     */
    convertItemsArrayToItemsDisplayName(items: Item[]): string {
        if (items.length) {
            return items.map((item) => { return item.DisplayName }).join("<br>");
        } else {
            return "";
        }
    }

    /**
     * Converts a string containing the selected items of a selection input element from the table
     * format to the PDF format.
     * @param itemsInTableFormat
     * @returns
     */
    convertItemsArrayTableDisplayNamesToPDF(itemsInTableFormat: string): string {
        return itemsInTableFormat.replaceAll("<br>", "\n")
    }

    /**
    * Converts a string containing the selected items of a selection input element from the excel
    * format to the display table format.
    * @param itemsInTableFormat
    * @returns
    */
    convertItemExcelDisplayNamesToTable(itemsInTableFormat: string): string {
        return itemsInTableFormat.replaceAll(" | ", "<br>");
    }

    /**
     * Extracts the minimum and maximum number conditions out of a plausibility range available in the conditions
     * attribute of a PlausiRange validator.
     * @param condition
     */
    getMinAndMaxInPlausibilityRange(conditions: string): { min: number, max: number } {
        const conditionsArray = conditions.split(",");
        const minAndMaxObject: { min: number, max: number } = { min: null, max: null };
        conditionsArray.forEach((condition) => {
            if (condition.indexOf("-") != -1) {
                const minAndMaxArray = condition.split("-");
                minAndMaxObject.min = +minAndMaxArray[0];
                minAndMaxObject.max = +minAndMaxArray[1]
            } else if (condition.startsWith(">")) {
                minAndMaxObject.min = +condition.substring(condition.indexOf(">") + 1);
            } else if (condition.startsWith("<")){
                minAndMaxObject.max = +condition.substring(condition.indexOf("<") + 1);
            }
        });
        return minAndMaxObject;
    }


    /**
     * For currency and percentage fields, deletes the units suffix (with
     * the € and the % symbols) so the value for the elemenent input don't contain it.
     * @param stringNumber
     */
    public formatNumberStringIntoValue(numberString: string): string {
        if (numberString.indexOf(this.euroSuffix) !== -1) {
            return numberString.substring(0, numberString.indexOf(this.euroSuffix));
        } else if (numberString.indexOf(this.percentageSuffix) !== -1) {
            return numberString.substring(0, numberString.indexOf(this.percentageSuffix));
        } else {
            return numberString;
        }
    }

    /**
     * Returns a format in which the number has to be formatted, depending on the input element type.
     * @param currency boolean stating whether the currency symbol must be printed or not
     * @param percentage boolean stating whether the percentage symbol must be printed or not
     * @param decimalDigits number stating whether the decimal numbers are 2 or 4
     * @returns formatting string ready for setting as display value and being performed by formatNumber() devextreme localization
     */
    public getNumberTextFormat(currency: boolean, percentage: boolean, decimalDigits: number): string {
        if (currency) {
            return this.currencyFormat;
        } else if (percentage) {
            return this.percentageFormat;
        } else if (decimalDigits == 4) {
            return this.fourDigitsDecimalFormat;
        } else if (decimalDigits == 2) {
            return this.twoDigitsDecimalFormat;
        } else {
            return this.twoDigitsDecimalFormat;
        }
    }

    /**
     *
     * Formats a number ready for the dxDataGrid component. The result is meant to be used as display value.
     * @param elementType UIElementType
     * @param numberValue
     * @returns displayValue of the number.
     */
    public getNumberDisplayValue(elementType: UIElementType, numberValue: number):string {
        const decimalDigits = UIElementType.DecimalScale4 ? 4 : UIElementType.DecimalScale2 ? 2 : null;
        return formatNumber(numberValue, this.getNumberTextFormat(
            elementType === UIElementType.Currency,
            elementType === UIElementType.Percent,
            decimalDigits)
        );
    }

    /**
     * Gets the value of a number form a dxDataGrid ready string.
     * @param elementType
     * @param numberDisplayValue
     * @returns
     */
    public getNumberValueFromDisplayValue(elementType: UIElementType, numberDisplayValue: string): number {
        const decimalDigits = UIElementType.DecimalScale4 ? 4 : UIElementType.DecimalScale2 ? 2 : null;
        return parseNumber(numberDisplayValue, this.getNumberTextFormat(
            elementType === UIElementType.Currency,
            elementType === UIElementType.Percent,
            decimalDigits));
    }
}
