<div [ngClass]="'form-editor'">
    <dx-drop-down-box [attr.id]="'input-' + element.ElementID" [(value)]="selectedItem" displayExpr="DisplayName" [items]="sortedItems" placeholder="Bitte wählen..." [showClearButton]="true"
                      [(opened)]="isDropDownBoxOpened" valueExpr="Value" [disabled]="inputFormGroup.get('value')?.disabled" (onValueChanged)="submitLookupEditValue($event)"
                      [(isValid)]="renderingInformation.validationInformation.valid"
                      [(validationError)]="renderingInformation.validationInformation.validationMessage"
                      [placeholder]="element.NullText ?? ''">
        <dx-data-grid [dataSource]="sortedItems" [hoverStateEnabled]="true" keyExpr="Value" displayExpr="DisplayName"
                      (onSelectionChanged)="changeLookupEditValue($event)">
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="Value" [width]="100"></dxi-column>
            <dxi-column dataField="DisplayName"></dxi-column>
        </dx-data-grid>
    </dx-drop-down-box>
</div>
