import { Component, OnInit } from '@angular/core';
import { BrandingService } from '../../services/branding.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.less']
})
export class HomePageComponent implements OnInit {
    // JQuery
    private $Element: JQuery;

    constructor(private brandingService: BrandingService, private keyCloakService: KeycloakService) { }

    public ngOnInit() {
        this.brandingService.setBranding(null);
        this.brandingService.setFooterLinks(null);
        this.brandingService.setTitle('R23 Webforms');
    }

    public async loginKeyCloak() {
       await this.keyCloakService.login({
           redirectUri: 'https://localhost:44457',
        });
    }
}
