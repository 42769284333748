import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appDateValidator]'
})
export class DateValidatorDirective {

    static dateInTheFuture(): ValidatorFn | null {
        const today: Date = new Date();
        return (control: AbstractControl): ValidationErrors | null => {
            const forbidden = new Date(control.value) > today;
            return forbidden ? { dateInTheFuture: { value: control.value } } : null;
        };
    }

}
