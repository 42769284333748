<ng-container *ngIf="layout">
    <ng-container [ngTemplateOutlet]="gridTemplate"
                  [ngTemplateOutletContext]="{ $implicit: layoutGrid }">
    </ng-container>
</ng-container>
<ng-template #gridTemplate let-layoutGrid>
    <!-- MAIN LAYOUT -->
    <div [formGroup]="formControlStructure" [ngClass]="'form-layout layout-grid-' + layoutGrid.columnsNumber">
        <ng-container *ngFor="let layoutGridColumn of layoutGrid.columns">
            <div [ngClass]="layoutGridColumn.cssClass + ' form-layout-column layout-grid-column-span-' + layoutGridColumn.colSpan">
                <ng-container *ngIf="layoutGridColumn.layoutGrid; else noGridColumn"
                              [ngTemplateOutlet]="gridTemplate"
                              [ngTemplateOutletContext]="{ $implicit: layoutGridColumn.layoutGrid }">
                </ng-container>
                <ng-template #noGridColumn>
                    <div *ngIf="layoutGridColumn.layoutTitle" class="header-column-container">
                        <h3 innerHTML="{{layoutGridColumn.layoutTitle}}"></h3>
                    </div>
                    <ng-container *ngIf="layoutGridColumn.elementId && isElementVisible(layoutGridColumn.elementId)">
                        <ng-container *ngIf="layoutGridColumn.cssClass === 'label-input-column'; else labelLeftElement">
                            <app-label [element]="getElementByElementId(layoutGridColumn.elementId)" [ngClass]="'label-component label-top'"></app-label>
                            <ng-container *ngIf="getElementByElementId(layoutGridColumn.elementId).UIElement !== UIElementType.Table">
                                <app-form-element-generator [element]="getElementByElementId(layoutGridColumn.elementId)" [ngClass]="'element-generator-component'"
                                                            [layoutItem]="getLayoutItemByElementId(layoutGridColumn.elementId)" [moduleIndex]="moduleIndex">
                                </app-form-element-generator>
                            </ng-container>
                            <ng-container *ngIf="getElementByElementId(layoutGridColumn.elementId).UIElement === UIElementType.Table">
                                <app-table-generator [tablesInModule]="tables" [layoutItem]="getLayoutItemByElementId(layoutGridColumn.elementId)" [ngClass]="'table-generator-component'"
                                                     [tableElement]="getElementByElementId(layoutGridColumn.elementId)"
                                                     [moduleIndex]="moduleIndex"></app-table-generator>
                            </ng-container>
                        </ng-container>
                        <ng-template #labelLeftElement>
                            <ng-container *ngIf="layoutGridColumn.cssClass.startsWith('label-column'), else noLabel">
                                <app-label [element]="getElementByElementId(layoutGridColumn.elementId)" [ngClass]="'label-component'"></app-label>
                            </ng-container>
                            <ng-template #noLabel>
                                <ng-container *ngIf="getElementByElementId(layoutGridColumn.elementId).UIElement !== UIElementType.Table">
                                    <app-form-element-generator [element]="getElementByElementId(layoutGridColumn.elementId)" [ngClass]="'element-generator-component'"
                                                                [layoutItem]="getLayoutItemByElementId(layoutGridColumn.elementId)" [moduleIndex]="moduleIndex">
                                    </app-form-element-generator>
                                </ng-container>
                                <ng-container *ngIf="getElementByElementId(layoutGridColumn.elementId).UIElement === UIElementType.Table">
                                    <app-table-generator [tablesInModule]="tables" [layoutItem]="getLayoutItemByElementId(layoutGridColumn.elementId)" [ngClass]="'table-generator-component'"
                                                         [tableElement]="getElementByElementId(layoutGridColumn.elementId)"
                                                         [moduleIndex]="moduleIndex"></app-table-generator>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                </ng-template>
            </div>
        </ng-container>
    </div>
</ng-template>
