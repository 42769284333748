import { KeycloakService } from "keycloak-angular";

export function KeycloakInitializer(
    keycloak: KeycloakService
) {
    return () =>
        keycloak.init({
            config: {
                url: 'https://sso.devagency.de/',
                realm: 'devagency',
                clientId: 'webforms',
            }, initOptions: {
                checkLoginIframe: false
            }
        });
}
