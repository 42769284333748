import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DataInput } from '../../../interfaces/form/data-input';
import { WebformTargetType } from '../../../interfaces/form/enums/webform-target-type-enum';
import { Modul } from '../../../interfaces/form/modul';
import { DataOutput } from '../../../interfaces/form/data-output';
import { ReactiveFormService } from '../../../services/form/reactive-form.service'
import { ElementValidationService } from '../../../services/form/element-validation.service';
 
@Component({
  selector: 'app-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.less']
})

/**
 * This component renders the form for the given data input. In the class it is contained the
 * logic for building the main reactive form structure.
 */
export class FormGeneratorComponent implements OnInit {

    @Input() dataInput: DataInput;
    @Input() formSubmitted: EventEmitter<unknown>;
    @Output() formDataReady = new EventEmitter<DataOutput>();

    private dataInputId: string;
    private dataInputType: WebformTargetType;
    public receivedModules: Modul[];

    constructor(private formService: ReactiveFormService, private validationService: ElementValidationService) { }

    /**
     * Initialization method, only to be called once the dataInput component input has been initialized.
     */
    ngOnInit() {
        //Once the dataInput is received from the back-end, the form generation begins
        if (this.dataInput) {
            this.generateForm(this.dataInput);
        }
        
        this.formSubmitted.subscribe(() => this.packElementsOutputData());
    }

    /**
     * Generates the form for the given data input.
     * @param argDataInput
     */
    private generateForm(argDataInput: DataInput) {
        this.receivedModules = argDataInput.Module;
        this.dataInputId = argDataInput.Id;
        this.dataInputType = argDataInput.Type;
        this.formService.initializeAppReactiveForm(argDataInput);
    }

    /**
     * Given the reactive form data from formControl, it packs the data of the form ready for being sent
     * to the back-end in a DataOutput interface.
     * @returns
     */
    private packElementsOutputData() {
        let formElementsOutput: DataOutput = {
            Elements: [],
            Id: this.dataInputId,
            Type: this.dataInputType,
        };
        formElementsOutput = this.formService.packElementsOutputData(formElementsOutput);
        this.formDataReady.emit(formElementsOutput);
    }
}
