import { Injectable } from "@angular/core";
import { PopupInfo } from "../interfaces/popup-info";

/**
 * This service contains the data and methods needed by the popup component.
 */
@Injectable()
export class PopupService {

    private _popupInfo: PopupInfo;

    get popupInfo(): PopupInfo {
        return this._popupInfo;
    }

    set popupInfo(popupInfo: PopupInfo) {
        this._popupInfo = popupInfo;
    }
}
