import { Pipe, PipeTransform } from '@angular/core';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1. Due to
 * the JavaScript security restriction, the exponent is
 * limited to 16 (related to Number.MAX_SAFE_INTEGER from
 * JavaScript)
 * Usage:
 *   value | mathPow:exponent
 * Example:
 *   {{ 2 | mathPow:10 }}
 *   formats to: 1024
*/
@Pipe({
  name: 'mathPow'
})
export class MathPowPipe implements PipeTransform {

    transform(value: number, exponent = 1): number {
        if (exponent > 16) {
            exponent = 16;
        }
        return Math.pow(value, exponent);
    }

}
