import { Component, OnInit } from '@angular/core';
import { ReceiptService } from '../../services/receipt.service';
import { FileClientService } from '../../client/file-client.service';
import { PopupService } from '../../services/popup.service';
import { BrandingService } from '../../services/branding.service';
import { Branding } from '../../interfaces/branding';


@Component({
    selector: 'app-receipt-component',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.less']

})

/**
 * This component handles the logic for the receipt layout.
 */
export class ReceiptComponent implements OnInit {

    public createPdfResult: Blob;
    public renderPdfExportErrorPopup = false;
    private _branding: Branding;

    constructor(private receiptService: ReceiptService, private fileClient: FileClientService,
        public popupService: PopupService, private brandingService: BrandingService) { }


    /**
     * Init method for configuring the view.
     */
    public ngOnInit() {
        this.brandingService.branding.subscribe((branding) => {
            this._branding = branding;
        });
    }

    /**
     * Receives the "save PDF" button click event and calls to the corresponding service for generating
     * a PDF with the receipt data gathered on form submit.
     */
    public onSavePdfClick() {
        this.fileClient.createPdf(this.receiptService.submittedReceiptData).subscribe({
            next: pdfRequestResult => {
                this.createPdfResult = pdfRequestResult;
                const fileURL = URL.createObjectURL(this.createPdfResult);
                window.open(fileURL);
                URL.revokeObjectURL(fileURL);
            },
            error: () => {
                this.renderPdfExportErrorPopup = true;
            }
        });
            
    }

    /**
     * When a popup is closed, it resets the rendering flag to false.
     * @param isPopupClosed
     */
    clearPopupInfo(isPopupClosed: boolean) {
        if (isPopupClosed) {
            this.renderPdfExportErrorPopup = false;
        }
    }

    public get brandingBackgroundColor() {
        return this.brandingService.obtainBrandingBackgroundColor(this._branding);
    }
}
