<div [attr.id]="'div-' + element.ElementID" [attr.data-display-name]="element.DisplayName" data-editor-type="TextEdit" [formGroup]="parentRootFormGroup" [class.touched]="elementFormGroup.get('value').touched">
    <ng-container formArrayName="modulesArray">
        <ng-container [formGroupName]="moduleIndex">
            <ng-container formArrayName="layoutItemsArray">
                <ng-container [ngSwitch]="element.UIElement">
                    <ng-container *ngSwitchCase="UIElementType.TextEdit">
                        <app-text-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-text-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.CheckEditLeftLabel">
                        <app-check-edit [ngClass]="'form-element check-edit-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" embeddedLabelPosition="left"></app-check-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.DateEdit">
                        <app-date-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [fixedWidth]="" [includeTime]="false"></app-date-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.ComboBoxEdit">
                        <app-combo-box-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-combo-box-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEdit">
                        <app-lookup-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [checkBoxes]="false" [search]="false"></app-lookup-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.TextWrapEdit">
                        <app-text-wrap-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-text-wrap-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.ListBoxCheckBoxes">
                        <app-list [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [search]="false" [displaySelectAll]="false"></app-list>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.Label">
                        <app-label [ngClass]="'form-element label-element'" [labelText]="elementFormGroup.get('label')?.value ? elementFormGroup.get('label').value : elementFormGroup.get('displayName')?.value"></app-label>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.Separator">
                        <hr />
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.CheckEditRightLabel">
                        <app-check-edit [ngClass]="'form-element check-edit-element'" [element]="element"  [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" embeddedLabelPosition="right"></app-check-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEditTree">
                        <app-lookup-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [checkBoxes]="false" [search]="false"></app-lookup-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEditTreeCheckBoxes">
                        <app-lookup-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [checkBoxes]="true" [search]="false"></app-lookup-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.ComboBoxEditCheckBoxes">
                        <app-combo-box-edit-checkboxes [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-combo-box-edit-checkboxes>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LabelUnderlineRightAlignment">
                        <app-label [ngClass]="'form-element label-element label-underline-right-alignment'" [labelText]="elementFormGroup.get('label')?.value ? elementFormGroup.get('label').value : elementFormGroup.get('displayName')?.value"></app-label>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.DateEditFixedWidth">
                        <app-date-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [fixedWidth]="layoutItem.Width" [includeTime]="false"></app-date-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.ListBoxCheckBoxesWithAllSelect">
                        <app-list [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [search]="false" [displaySelectAll]="true"></app-list>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.ListBoxCheckBoxesWithSearch">
                        <app-list [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [search]="true" [displaySelectAll]="true"></app-list>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEditWithSearch">
                        <app-lookup-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [checkBoxes]="false" [search]="true"></app-lookup-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.Currency">
                        <app-number-box [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [currency]="true" [percentage]="false"></app-number-box>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.DecimalScale2">
                        <app-number-box [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [currency]="false" [percentage]="false" [decimalDigits]="2"></app-number-box>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEditShowValue">
                        <app-lookup-edit-datagrid [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-lookup-edit-datagrid>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.LookupEditExtValueAndDisplayName">
                        <app-lookup-edit-datagrid [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-lookup-edit-datagrid>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.DecimalScale4">
                        <app-number-box [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [currency]="false" [percentage]="false" [decimalDigits]="4"></app-number-box>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.Percent">
                        <app-number-box [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [currency]="false" [percentage]="true"></app-number-box>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.CheckEditRightLabelDescription">
                        <app-check-edit [ngClass]="'form-element check-edit-element'" [element]="element"  [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" embeddedLabelPosition="right"></app-check-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.DateEditWithTime">
                        <app-date-edit [ngClass]="'form-element'" [element]="element" [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex" [fixedWidth]="layoutItem.Width" [includeTime]="true"></app-date-edit>
                    </ng-container>
                    <ng-container *ngSwitchCase="UIElementType.FileUpload">
                        <app-file-uploader [ngClass]="'form-element'" [element]="element"  [inputFormGroup]="elementFormGroup" [moduleIndex]="moduleIndex"></app-file-uploader>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>Unbekanntes UI Element: {{element.UIElement}}</span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="element.ToolTip && element.ToolTip.trim().length > 0">
    <dx-tooltip [target]="'#div-' + element.ElementID"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position="bottom"
                [hideOnOutsideClick]="false">
        <div *dxTemplate="let data = data; of: 'content'"> {{element.ToolTip}} </div>
    </dx-tooltip>
</ng-container>
