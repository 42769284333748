import { Component, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DataInput } from '../../interfaces/form/data-input';
import { WebformTargetType } from '../../interfaces/form/enums/webform-target-type-enum';
import { DataOutput } from '../../interfaces/form/data-output';
import { ViewStateDataService } from '../../services/view-state-data.service';
import { ReactiveFormService } from '../../services/form/reactive-form.service';
import { BrandingService } from '../../services/branding.service';
import { Branding } from '../../interfaces/branding';
import { ElementValidationService } from '../../services/form/element-validation.service';
import { FormClientService } from '../../client/form-client.service';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jCaptcha = require('js-captcha');

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.less']
})
export class FormPageComponent implements OnInit {

    @ViewChild('formContainer') formContainer: any;

    formSubmitted = new EventEmitter();

    // Reactive form where all the form components will be added
    public formControl: UntypedFormGroup;

    // Public properties
    public areRequiredFieldsExist: boolean;

    public isSummaryVisible: boolean;
    public pageDescription: string;

    // Data
    public inputId: string;
    public dataInputResult: DataInput;
    private _branding: Branding;

    // Form Captcha
    public captcha: any;
    public renderCaptcha: boolean;

    // States whether the form has been fully loaded
    public formLoaded: boolean;

    //Form submit information
    public dataSubmitResult: boolean;

    // Boolean controlling the rendering of the error popups
    public renderGetDataPopup = false;
    public renderSaveDataPopup = false;

    constructor(private argRoute: ActivatedRoute, private formClientService: FormClientService,
        private argRouter: Router, private viewStateDataService: ViewStateDataService, public formService: ReactiveFormService,
        public validationService: ElementValidationService, private brandingService: BrandingService) { }


    public ngOnInit() {
        this.formControl = this.formService.createAppReactiveForm();
        this.viewStateDataService.setFormSuccess(false);

        // Getting inputID from the url parameters
        this.argRoute.paramMap.subscribe((argParams: ParamMap) => {
            this.inputId = argParams.get('inputid');
        });

        if (this.inputId) {
            const getDataRequest: R23_Webforms.Model.General.Request<string> = {
                Id: this.inputId
            };
            this.formClientService.getDataById(getDataRequest).subscribe({
                // The data is ready from backend
                next: dataInput => {
                    this.dataInputResult = dataInput;
                    if (dataInput) {
                        this.pageDescription = dataInput.PageDescription;
                        this._branding = dataInput.Branding;
                        this.brandingService.setBranding(dataInput.Branding);
                        this.brandingService.setFooterLinks(dataInput.FooterLinks);
                        this.brandingService.setTitle(dataInput.PageTitel);
                        this.toggleSummary(dataInput.Type);
                    }
                    // When the form is fully loaded, the flag serving as reference for the template is set.
                    // Also the captcha is added here.
                    this.formService.lastElementRendered.subscribe((formLoaded) => {
                        this.formLoaded = formLoaded;
                        this.validationService.setValidationSummary();
                        if (this.renderCaptcha && formLoaded) {
                            this.initCaptcha();
                        }
                    })
                }, error: () => {
                    this.renderGetDataPopup = true;
                }
                    
            })

        }


    }

    /**
     * States whether the current form has any required field.
     * @returns boolean
     */
    public formHasValidation(): boolean {
        return this.formService.hasValidationFields;
    }

    /**
     * Validates the captcha (if applicable to the current form) and triggers the event informing the
     * child elements (at the moment, the form generator component) that the user clicked the submit button
     */
    public onSubmit() {
        console.log('onSubmit', this.captcha);
        if (this.captcha) {
            //the submit event is handled by the captcha validation method
            this.captcha.validate();
        } else {
            //the submit event is handled directly
            this.handleFormSubmit();
        }
    }

    /**
     * Once the child elements (at the moment, form generator component) has received the submit event,
     * packed all the data for submit, and triggered the data ready event, this method is called for
     * sending the data to the back-end 
     * @param formData
     */
    public submitFormData(formData: DataOutput) {
        console.log(formData);
        const saveDataRequest: R23_Webforms.Model.General.Request<DataOutput> = {
            Data: formData,
            Id: this.inputId
        };
        this.formClientService.saveData(saveDataRequest).subscribe({
            next: submitResult => {
                if(submitResult) {
                    this.formService.notifyFormExit();
                    this.argRouter.navigate(['/form-success']);
                } else {
                    this.dataSubmitResult = submitResult;
                }
            },
            error: err => {
                this.renderSaveDataPopup = true;
            }
        })
    }

    /**
     * Handles the popup closed event from the popup component. When closed,
     * it also redirects to the root URL.
     * @param isPopupClosed
     */
    clearDataInputPopupInfo(isPopupClosed: boolean) {
        if (isPopupClosed) {
            this.dataInputResult = null;
            this.renderGetDataPopup = false;
            this.argRouter.navigate(['/']);
        }
    }
    /**
     * Handles the popup closed event from the popup component. When closed,
     * it also redirects to the root URL.
     * @param isPopupClosed
     */
    clearDataSubmitPopupInfo(isPopupClosed: boolean) {
        if (isPopupClosed) {
            this.renderSaveDataPopup = false;
            this.dataSubmitResult = null;
        }
    }


    /**
     * Initialization of the captcha. It must be performed by adding elements to the DOM in runtime due to the pure
     * JS nature of the captcha tool and Angular blocking <script> tags in the HTML document. For this to work,
     * the dataInput observable must be ready in order to know if the captcha must be displayed.
     * 
     * @param templateEl with an  HTML element that referenced the current directive
     */
    private initCaptcha() {
        const captchaLabel = document.createElement('span');
        captchaLabel.setAttribute('class', 'label');
        const captchaInput = document.createElement('input');
        captchaInput.setAttribute('type', 'text');
        captchaInput.setAttribute('id', 'captcha');
        const captchaRowDiv = document.createElement('div');
        captchaRowDiv.setAttribute('class', 'form-row captcha');
        captchaRowDiv.append(captchaInput);
        captchaRowDiv.append(captchaLabel);
        captchaRowDiv.append(captchaInput);
        const captchaRowCol = document.createElement('div');
        captchaRowCol.setAttribute('class', 'col-12');
        captchaRowCol.append(captchaRowDiv);
        const captchaContainerRowDiv = document.createElement('div');
        captchaContainerRowDiv.setAttribute('class', 'row');
        captchaContainerRowDiv.appendChild(captchaRowCol);
        this.formContainer.nativeElement.append(captchaContainerRowDiv);
        this.captcha = new jCaptcha({
            el: '#captcha',
            canvasClass: 'jCaptchaCanvas',
            canvasStyle: {
                // required properties for captcha stylings:
                width: 100,
                height: 40,
                textBaseline: 'top',
                font: '20px Arial',
                textAlign: 'left',
                fillStyle: '#ddd'
            },
            // set callback function for handling the form submit
            callback: (response: any) => {
                if (response == 'success') {
                    this.handleFormSubmit();
                }
            }
        });

    }

    /**
     * Gets the background color associated to the branding information.
     */
    public get brandingBackgroundColor() {
        return this.brandingService.obtainBrandingBackgroundColor(this._branding);
    }


    /**
     * An event that the form is to be submitted is sent to the children elements.
     */
    private handleFormSubmit() {
        this.validationService.setValidationSummary();
        const validationSuccess = this.validationService.validationMessages.length <= 0;
        this.viewStateDataService.setFormSuccess(validationSuccess);
        if (validationSuccess) {
            this.formSubmitted.emit();
        }
    }

    private toggleSummary(targetType: WebformTargetType) {
        this.isSummaryVisible = false;

        switch (targetType) {
            case WebformTargetType.Unknown:
                this.isSummaryVisible = true;
                break;
            case WebformTargetType.Category:
                this.isSummaryVisible = true;
                break;
            case WebformTargetType.History:
                this.isSummaryVisible = true;
                break;
            case WebformTargetType.ConfigCategory:
                this.renderCaptcha = true;
                break;
            case WebformTargetType.Note:
                this.isSummaryVisible = true;
                break;
            default:
                break;
        }
    }

}
