import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './services/Authentication.Service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private argAuthenticationService: AuthenticationService) { }

    public intercept(argRequest: HttpRequest<any>, argNext: HttpHandler): Observable<HttpEvent<any>> {
        // Add auth header with jwt if user is logged in
        const oCurrentUser = this.argAuthenticationService.CurrentUser;

        if (oCurrentUser && oCurrentUser.AccessToken) {
            argRequest = argRequest.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + oCurrentUser.AccessToken
                }
            });
        }

        return argNext.handle(argRequest);
    }
}
