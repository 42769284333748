<div [ngClass]="'form-editor'">
    <dx-date-box [attr.id]="'input-' + element.ElementID" [value]="dateValue" [displayFormat]="dateDisplayFormat" [type]="dateBoxType" (onValueChanged)="valueChanged($event)"
                 (onInitialized)="isValid($event)" [width]="dateBoxWidth" [disabled]="inputFormGroup.get('value')?.disabled"
                 [placeholder]="element.NullText ?? ''">
        <!-- Implemented as custom validator in DateBox because the "valid" attribute is not working for reactive form validations -->
        <dx-validator>
            <dxi-validation-rule type="custom"
                                 [message]="renderingInformation.validationInformation.validationMessage.message"
                                 [validationCallback]="isValid">
            </dxi-validation-rule>
        </dx-validator>
    </dx-date-box>
</div>
