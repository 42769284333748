<div id="InfoContainer" class="container">
    <div class="row">
        <div class="col-md-12">
            <h1>Abschicken erfolgreich</h1>
            <p>
                Vielen Dank für die Eingabe Ihrer Daten.
            </p>
        </div>
    </div>

    <div class="row">
        <app-receipt-component class="col-12"></app-receipt-component>
    </div>
</div>
