import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeStyle, SafeUrl, Title } from '@angular/platform-browser';
import { Branding } from '../interfaces/branding';
import { FooterLink } from '../interfaces/form/footer-link';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

    constructor(private sanitizer: DomSanitizer, private argTitle: Title) { }

    public branding: BehaviorSubject<Branding> = new BehaviorSubject<Branding>(null);
    public footerLinks: BehaviorSubject<FooterLink[]> = new BehaviorSubject<FooterLink[]>(null);
    public title: BehaviorSubject<string> = new BehaviorSubject<string>('');


    /**
     * Sets the Branding in a subject field in this class, so the subscribers can access the Branding information once it is available.
     * @param branding
     */
    public setBranding(branding: Branding) {
        this.branding.next(branding);
    }

    /**
     * Sets the footer links in a subject field in this class, so the subscribers can access them when available.
     * @param branding
     */
    public setFooterLinks(footerLinks: FooterLink[]) {
        this.footerLinks.next(footerLinks);
    }

    /**
     * Sets the title in a subject field in this class, so the subscribers can access it once it is available.
     * @param branding
     */
    public setTitle(title: string) {
        this.title.next(title);
    }

    /**
     * Gets the background color associated to the branding information.
     */
    public obtainBrandingBackgroundColor(branding: Branding): SafeStyle {
        let backgroundStyle: SafeStyle = '';

        if (branding && branding.Color) {
            backgroundStyle = this.sanitizer.bypassSecurityTrustStyle('background-color: ' + branding.Color);
        }

        return backgroundStyle;
    }

    /**
     * Gets the Icon URL associated to the branding information.
     */
    public obtainBrandingIconUrl(branding: Branding): SafeUrl {
        let iconUrl: SafeUrl = '';

        if (branding && branding.Icon) {
            iconUrl = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + branding.Icon);
        }

        return iconUrl;
    }
}
