<dx-popup [width]="400"
          [height]="220"
          [showTitle]="true"
          title="{{popupInfo?.title}}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          (onHidden)="closePopup()"
          [showCloseButton]="true"
          container="body"
          [(visible)]="popupIsVisible"
          class="r23-webforms-popup"
          [copyRootClassesToWrapper]="true">
    <div class="popup-content">
        {{popupInfo?.content}}
    </div>
    <div class="popup-action-bar">
        <dx-button text="OK" [useSubmitBehavior]="false" (onClick)="closePopup()" class="button">
        </dx-button>
            
    </div>
</dx-popup>
