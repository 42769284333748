import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataInput } from '../../interfaces/form/data-input';
import { Modul } from '../../interfaces/form/modul';
import { LayoutItem } from '../../interfaces/form/layout-item';
import { Element } from '../../interfaces/form/element';
import { UIElementType } from '../../interfaces/form/enums/uielement-type-enum';
import { ElementOutput } from '../../interfaces/form/element-output';
import { DataOutput } from '../../interfaces/form/data-output';
import { ReceiptTable } from '../../interfaces/form/receipt-table';
import { ReceiptTableRow } from '../../interfaces/form/receipt-table-row';
import { ReceiptService } from '../receipt.service';
import { ValidationFunctionType } from '../../interfaces/form/enums/validation-function-type-enum';
import { DateValidatorDirective } from './custom-validators/date-validator.directive';
import { formatMessage } from 'devextreme/localization';
import { StringUtilsService } from '../string-utils.service';

/**
 * This service is meant to handle the main reactive form structure created for each dynamic form.
 */
@Injectable({
    providedIn: 'root'
})
export class ReactiveFormService {

    // Maximum number in which the receipt table is printed in separated columns mode.
    private MAX_COLUMNS_IN_RECEIPT_TABLE = 5;

    //Contains the whole reactive form structure
    private _reactiveForm: UntypedFormGroup;

    //States whether the form contains any required field so the form must be blocked if the don't have a value
    private _hasValidationFields: boolean;

    //Stores the last element ID added in the form structure. This is useful mantaining asynchronous control,
    //for example, notifying when the latest element of the form has been rendered.
    public lastElementIdAdded: string;

    //Broadcasts the status of the last element being already rendered or not
    public lastElementRendered: BehaviorSubject<boolean>;

    constructor(private fb: UntypedFormBuilder, private receiptService: ReceiptService, private stringUtilsService: StringUtilsService) {
    }

    /**
     * Get the current status of the reactive form.
     */
    public getReactiveForm(): UntypedFormGroup {
        return this._reactiveForm;
    }

    /**
     * Getter of the attribute indicating if the form has any field that is validated on submit.
     */
    get hasValidationFields() {
        return this._hasValidationFields;
    }

    /**
     * Creates a new form for the current customer. At this point contains
     * only an array for the placig there the modules
     */
    public createAppReactiveForm(): UntypedFormGroup {
        this.lastElementRendered = new BehaviorSubject(false);
        this._reactiveForm = this.fb.group({
            formName: new UntypedFormControl(),
            modulesArray: this.fb.array([

            ])
        });
        this._hasValidationFields = false;
        return this._reactiveForm;
    }

    /**
     * With the received input data for a customer, fills the modules array.
     * @param argDataInput
     */
    public initializeAppReactiveForm(argDataInput: DataInput) {
        this._reactiveForm.get("formName").setValue(argDataInput.PageTitel);
        argDataInput.Module.forEach((currentModule, moduleIndex) => {
            // For modules with a layout attribute, a form array with its layout items is generated
            if (currentModule.Layout) {
                this.modulesArray.push(
                    this.fb.group({
                        moduleIndex: moduleIndex,
                        layoutItemsArray: this.fb.array([])
                    })
                )
                this.generateLayoutItems(currentModule.Layout.LayoutItems, moduleIndex, currentModule, "", currentModule.Layout.Orientation !== "Vertical");
            }
        })
    }

    /**
     * With the received module data, fills the layout items array that will contain the form controls and the render information
     * for all the edit fields.
     * @param layoutItems
     * @param moduleIndex
     * @param module
     * @param argLayoutItemTitle
     */
    private generateLayoutItems(layoutItems: LayoutItem[], moduleIndex: number, module: Modul = null, argLayoutItemTitle: string, horizontal: boolean) {
        layoutItems.forEach((layoutItem) => {
            const layoutItemTitle = layoutItem.Header ? layoutItem.Header : argLayoutItemTitle;
            if (layoutItem.Type === "Element") {
                const element: Element = module.Elements.find(
                    function (element) {
                        return (element.ElementID === layoutItem.ElementID);
                    });
                // Only that don't belong to tables are loaded here. The ones belonging
                // to tables, are rendered from the table component
                if (element.UIElement !== UIElementType.Table) {
                    this.addFormControlForElement(element, null, moduleIndex, false, null, layoutItem.Label);
                } else {
                    this.addFormGroupForTable(element, layoutItemTitle, moduleIndex);
                }
            } else {
                this.generateLayoutItems(layoutItem.LayoutItems, moduleIndex, module, layoutItemTitle, layoutItem.Orientation !== "Vertical");
            }
        })
    }


    /**
     * Adds a form control element for an editable field.
     * @param element with the Element for which the form control should be created
     * @param value with he value of the element that should be assigned to the form control in initialization
     * @param moduleIndex with the current module
     * @param avoidSubmitting stating whether the current element's value is to be submitted with the form. For example,
     * in the tables only the row values should be submitted, and not the values of the input form fields for adding a row
     * that were not submitted.
     * @param belongsToTableId only to be sent if the added form control belongs to a table row add/edit field
     * @param label with the LayoutItem printable label for the input element to be displayed. If null, the element display name
     * will be printed.
     * @returns index of the added element in its array
     */
    public addFormControlForElement(element: Element, value: string, moduleIndex: number, avoidSubmitting: boolean, belongsToTableId: string, label: string): number {
        let formControlIndex = 0;
        const parentFormArray = this.getLayoutItemsArrayFromModuleNumber(moduleIndex);
        if (parentFormArray) {
            // Check if the element had been added to the reactive form before
            const elementFormControlIndex = parentFormArray.controls.map(function (control) {
                return control.get('id')?.value
            }).indexOf(element.ElementID);
            if (elementFormControlIndex < 0) {
                // In case it wasn't there, a new form group is created for element
                formControlIndex = parentFormArray.length;
                parentFormArray.push(
                    this.fb.group({
                        value: [value],
                        displayValue: "",
                        id: element.ElementID,
                        avoidSubmitting: avoidSubmitting,
                        displayName: element.DisplayName,
                        visible: true,
                        belongsToTableId: belongsToTableId ? belongsToTableId : null,
                        label: label,
                        validationMessages: null,
                    })
                )
                this.createValidatorsListForElement(element, parentFormArray.at(parentFormArray.controls.length - 1));
                // Keeping record of the last element added to the form. Only elements that will be rendered on form
                // loading, meaning non-submittable elements (elements for editing a table column) are not included
                if (!avoidSubmitting) {
                    this.lastElementIdAdded = element.ElementID;
                }
            } else {
                // In case it was there, the form control associated to the value is edited with the previous value or empty
                // if it didn't exist
                formControlIndex = elementFormControlIndex;
                parentFormArray.controls[formControlIndex].get('value').setValue(value ? value : '');
            }
        }
        return formControlIndex;
    }

    /**
     * Applies the element's own validations, meaning the validations that don't affect to other elements.
     * @param element
     * @param elementFormControl
     */
    private createValidatorsListForElement(element: Element, elementFormControl: AbstractControl) {
        // Validation of type "required"
        if (element.IsRequired) {
            this.addRequiredToFormControl(elementFormControl);
        }
        // Other validations available in the Validations field of the element
        if (element.Validations) {
            const validationMessages: { validationFn: string, message: string }[] = elementFormControl.get("validationMessages").value ?? [];
            this.setFormHasValidationRules();
            element.Validations.forEach((validation) => {
                switch (validation.FunctionType) {
                    case (ValidationFunctionType.PlausiDateNotInFuture): {
                        elementFormControl.get("value").addValidators(DateValidatorDirective.dateInTheFuture());
                        validationMessages.push({
                            validationFn: "dateInTheFuture",
                            message: validation.Message ? validation.Message : "Das Datum kann nicht in der Zukunft eingestellt werden",
                        });
                        break;
                    }
                    case (ValidationFunctionType.PlausiRange): {
                        const minAndMaxObject = this.stringUtilsService.getMinAndMaxInPlausibilityRange(validation.Condition);
                        elementFormControl.get("value").addValidators(Validators.min(minAndMaxObject.min));
                        validationMessages.push({
                            validationFn: "min",
                            message: validation.Message ? validation.Message : `Die eingegebene Zahl muss gr��er als ${minAndMaxObject.min} sein`,
                        });
                        elementFormControl.get("value").addValidators(Validators.max(minAndMaxObject.max));
                        validationMessages.push({
                            validationFn: "max",
                            message: validation.Message ? validation.Message : `Die eingegebene Zahl muss kleiner als ${minAndMaxObject.max} sein`,
                        });
                        break;
                    }

                }
            })
            elementFormControl.get("validationMessages").setValue(validationMessages);
        }
    }

    /**
     * Sets the attribute stating that there are elements in the form that need to be validated.
     */
    private setFormHasValidationRules() {
        if (!this._hasValidationFields) {
            this._hasValidationFields = true;
        }
    }

    /**
     * States if a element is to be rendered or not, after the form validation has been loaded.
     * @param element
     * @returns
     */
    public isElementVisible(element: Element): boolean {
        let elementVisible = true;
        if (element.UIElement !== UIElementType.Table) {
            const currentElementFormGroup: UntypedFormGroup = this.searchElementFormGrouplInReactiveFormByElementId(element.ElementID, false);
            if (!currentElementFormGroup || !currentElementFormGroup.get('visible').value) {
                elementVisible = false;
            }
        }
        return elementVisible;
    }

    /**
     * Changes the value in a form control element.
     * @param element with the Element corresponding to the form control
     * @param newValue with the new value to be set
     * @param moduleIndex with the current module
     */
    public changeFormControlValue(element: Element, newValue: string) {
        const controlFormGroup = this.searchElementFormGrouplInReactiveFormByElementId(element.ElementID, false);
        if (controlFormGroup) {
            controlFormGroup.get('value').setValue(newValue);
            controlFormGroup.get('id').setValue(element.ElementID);
        }
    }


    /**
     * Creates an empty form group for a table.
     * @param element with the Table element of the input data for which the group is added
     * @param layoutItemTitle with the title of table coming from the LayoutItem object
     * @param moduleIndex with the current module idex
     * @returns FormGroup of the added table
     */
    public addFormGroupForTable(element: Element, layoutItemTitle: string, moduleIndex: number): UntypedFormGroup {
        const parentFormArray = this.getLayoutItemsArrayFromModuleNumber(moduleIndex);
        const formControlIndex = parentFormArray.length;
        parentFormArray.push(
            this.fb.group({
                id: element.ElementID,
                tableId: element.ElementTableID,
                itemsArray: this.fb.array([]),
                layoutItemTitle: layoutItemTitle,
            })
        )
        // Keeping record of the last element added to the form.
        this.lastElementIdAdded = element.ElementID;
        return parentFormArray.at(formControlIndex) as UntypedFormGroup;
    }

    /**
     * Each row that is added to the table must be reflected in the reactive forms structure, adding
     * a form array with the rows, and each row with a nested form array for the cell values.
     * @param data: null if the values to be added are from the add/edit row form. Double record of {value - id, value-id} pairs if it comes form
     * an exteral source, being each pair the values and display values tied to the ID of the form element.
     * @param key
     * @param elementTableId
     * @param moduleIndex
     * @return boolean stating whether the add was added/passed validation or not
     */
    public addRowToTable(data: { controlValues: Record<string, unknown>, displayValues: Record<string, string> }, key: string, elementTableId: string, moduleIndex: number): UntypedFormGroup[] {
        if (data) {
            // Case that the information for the new row comes from an external source, and not directly from the add/edit row form
            // In this case, the form controls of the add/edit row form will be filled with the external source values, so the
            // new row validation and/or adition are executed with the same code as when adding/editing from the form
            for (const property in data.controlValues) {
                if (property !== '__KEY__') {
                    const rowEditFormElement: UntypedFormGroup = this.searchElementFormGrouplInReactiveFormByElementId(property, false);
                    if (rowEditFormElement) {
                        rowEditFormElement.get('value').setValue(data.controlValues[property]);
                        rowEditFormElement.get('displayValue').setValue(data.displayValues[property]);
                    }
                }
            }
        }
        const itemsFormArray = this.searchElementFormGrouplInReactiveFormByElementId(elementTableId, true).get('itemsArray') as UntypedFormArray;
        const itemIndex = itemsFormArray.controls.length;
        (itemsFormArray).push(
            this.fb.group({
                itemId: key,
                keysArray: this.fb.array([]),
            })
        )
        const rowAddFailingFields = this.applyInputValuesToRow(elementTableId, moduleIndex, itemsFormArray, itemIndex);
        if (rowAddFailingFields.length > 0) {
            itemsFormArray.removeAt(itemIndex);
        }
        return rowAddFailingFields;
    }

    /**
     * Gets the row display values of the currently opened add/edit row form.
     * @param elementTableId
     * @param moduleIndex
     */
    public getRowDisplayValues(elementTableId: string, moduleIndex: number): Record<string, string> {
        const editElements = this.searchTableEditElementFormGroupsByTableId(elementTableId, moduleIndex);
        const newItemDisplayValues: Record<string, string> = {};
        editElements.forEach((column) => {
            if (!column.get("value")?.disabled && column.get("visible")?.value) {
                newItemDisplayValues[column.get('id').value] = column.get('displayValue').value;
            }
        })
        return newItemDisplayValues;
    }

    /**
     * Prepares teh form structure for editing an existing row by popuplating the add/edit row edit fields
     * with the selected row value.
     * @param elementTableId
     * @param moduleIndex
     * @param rowIndex
     */
    public prepareEditRowData(elementTableId: string, moduleIndex: number, rowIndex: number) {
        const itemsFormArray = this.searchElementFormGrouplInReactiveFormByElementId(elementTableId, true).get('itemsArray') as UntypedFormArray;
        const itemFormGroup = itemsFormArray.at(rowIndex) as UntypedFormGroup;
        const keysFormArray = itemFormGroup.get("keysArray") as UntypedFormArray;
        this.applyRowValuesToInput(elementTableId, moduleIndex, keysFormArray);
    }

    /**
     * It copies all the values in a row to the counterpants in the add/edit row edit fields. This may be used
     * for preparing the row edition fields with the current values of the row under edit.
     * @param elementTableId
     * @param moduleIndex
     * @param itemsFormArray
     * @param rowIndex
     */
    private applyRowValuesToInput(elementTableId: string, moduleIndex: number, rowKeysArrayWithValues: UntypedFormArray) {
        const tableEditElemens: UntypedFormGroup[] = this.searchTableEditElementFormGroupsByTableId(elementTableId, moduleIndex);
        this.clearEditRowValuesFromTable(elementTableId, moduleIndex);
        rowKeysArrayWithValues.controls.forEach((tableCellGroup) => {
            const editElement = tableEditElemens.find((tableEditElement) =>
                tableEditElement.get('id').value === tableCellGroup.get('id').value
            )
            if (editElement) {
                editElement.get('value').setValue(tableCellGroup.get('value').value);
            }
        })
    }

    /**
     * Edits the values of a row from the reactive form structure corresponding to a table.
     * @param editedRowIndex
     * @param elementTableId
     * @param moduleIndex
     * @return boolean stating whether the submitted data passed the validation
     */
    public editRowValuesFromTable(editedRowIndex: number, elementTableId: string, moduleIndex: number): UntypedFormGroup[] {
        const itemsFormArray = this.searchElementFormGrouplInReactiveFormByElementId(elementTableId, true).get('itemsArray') as UntypedFormArray;
        const rowEditFailingFields: UntypedFormGroup[] = this.applyInputValuesToRow(elementTableId, moduleIndex, itemsFormArray, editedRowIndex);
        return rowEditFailingFields;
    }

    /**
     * For a row represented in this form structure as itemsFormArray, applies the current values in the
     * add/edit table row fields. This may be used for adding/editing rows.
     * @param elementTableId
     * @param moduleIndex
     * @param itemsFormArray
     * @param itemIndex
     * @return boolean stating whether the row values were applied, depending on them passing the validation or not
     */
    private applyInputValuesToRow(elementTableId: string, moduleIndex: number, itemsFormArray: UntypedFormArray, itemIndex: number): UntypedFormGroup[] {
        const rowInputFormGroups: UntypedFormGroup[] = this.searchTableEditElementFormGroupsByTableId(elementTableId, moduleIndex);
        const invalidInputs = rowInputFormGroups.filter(input => !input.valid);

        if (!invalidInputs.length) {
            (itemsFormArray.controls[itemIndex].get('keysArray') as UntypedFormArray).clear();
            rowInputFormGroups.forEach((elementGroup) => {
                if (!elementGroup.get('value')?.disabled && elementGroup.get('visible')?.value) {
                    // Case of not disabled and not hidden objects
                    (itemsFormArray.controls[itemIndex].get('keysArray') as UntypedFormArray).push(
                        this.fb.group({
                            value: [elementGroup.get('value')?.value],
                            displayValue: elementGroup.get('displayValue')?.value,
                            displayName: elementGroup.get('displayName').value,
                            id: elementGroup.get('id')?.value,
                        })
                    )
                } else {
                    (itemsFormArray.controls[itemIndex].get('keysArray') as UntypedFormArray).push(
                        this.fb.group({
                            value: null,
                            displayValue: null,
                            displayName: elementGroup.get('displayName').value,
                            id: elementGroup.get('id')?.value,
                        })
                    )
                }
            })
        }
        return invalidInputs;
    }


    /**
     * Clears the value of all the table add/edit row edit fields.
     * @param elementTableId
     * @param moduleIndex
     */
    public clearEditRowValuesFromTable(elementTableId: string, moduleIndex: number) {
        const tableEditElemens: UntypedFormGroup[] = this.searchTableEditElementFormGroupsByTableId(elementTableId, moduleIndex);
        tableEditElemens.forEach((tableEditElement) => {
            tableEditElement.get('value').setValue(null);
            tableEditElement.get('displayValue').setValue(null);
        });
    }

    /**
     * Deletes a row from the reactive form structure corresponding to the data entered in a table.
     * @param deletedRowIndex
     * @param formArrayIndex
     * @param elementTableId
     */
    public deleteRowFromTable(deletedRowIndex: number, elementTableId: string) {
        const itemsFormArray = this.searchElementFormGrouplInReactiveFormByElementId(elementTableId, true).get('itemsArray') as UntypedFormArray;
        itemsFormArray.removeAt(deletedRowIndex);
    }

    /**
     * Gets a form group belonging to a cell from the entered data in a table.
     * @param tableElementID
     * @param rowIndex
     * @param columnIndex
     * @returns
     */
    getTableCellFormGroup(tableElementID: string, rowIndex: number, columnIndex: number): UntypedFormGroup {
        let cellFormGroup: UntypedFormGroup = null;
        const tableElement: UntypedFormGroup = this.searchElementFormGrouplInReactiveFormByElementId(tableElementID, true);
        if (tableElement) {
            const itemsFormGroup: UntypedFormGroup = (tableElement.get('itemsArray') as UntypedFormArray).at(rowIndex) as UntypedFormGroup;
            cellFormGroup = (itemsFormGroup.get('keysArray') as UntypedFormArray).at(columnIndex) as UntypedFormGroup;
        }
        return cellFormGroup;
    }

    /**
     * Getter method for the form array of the modules inside the form control main group.
     */
    get modulesArray() {
        return this._reactiveForm.get('modulesArray') as UntypedFormArray;
    }

    /**
     * For the given module specified by the index, the form array of first-level children layout items si returned.
     * @param moduleIndex
     * @returns
     */
    getLayoutItemsArrayFromModuleNumber(moduleIndex: number): UntypedFormArray {
        return this.modulesArray.controls[moduleIndex].get('layoutItemsArray') as UntypedFormArray;
    }

    /**
     * Searchs the form group corresponding to an element or table element in the reactive form structure.
     * @param elementId with either the elementId or the tableElementId of the element
     * @param isTable is a table (true) or an element (false)
     * @returns
     */
    searchElementFormGrouplInReactiveFormByElementId(elementId: string, isTable: boolean): UntypedFormGroup {
        let foundFormGroup: AbstractControl = null;
        const modulesArray: UntypedFormArray = this.modulesArray;
        if (modulesArray) {
            modulesArray.controls.forEach((moduleFormGroup, moduleIndex) => {
                if (!foundFormGroup) {
                    const layoutItemsArray: UntypedFormArray = this.getLayoutItemsArrayFromModuleNumber(moduleIndex);
                    if (layoutItemsArray) {
                        if (!isTable) {
                            foundFormGroup = layoutItemsArray.controls.find((layoutItemGroup) =>
                                layoutItemGroup.get("id") && layoutItemGroup.get("id").value === elementId
                            )
                        } else {
                            foundFormGroup = layoutItemsArray.controls.find((layoutItemGroup) =>
                                layoutItemGroup.get("tableId") && layoutItemGroup.get("tableId").value === elementId
                            )
                        }
                    }
                }
            })
        }
        return foundFormGroup as UntypedFormGroup;
    }

    /**
     * In a module, searchs for all the edit column element used when adding/editing a row from a table.
     * @param elementTableId of the table
     * @param moduleIndex
     * @returns
     */
    searchTableEditElementFormGroupsByTableId(elementTableId: string, moduleIndex: number): UntypedFormGroup[] {
        const foundEditElementGroups: UntypedFormGroup[] = [];
        this.getLayoutItemsArrayFromModuleNumber(moduleIndex).controls.forEach((layoutItem) => {
            if (layoutItem.get('belongsToTableId') && layoutItem.get('belongsToTableId').value === elementTableId) {
                foundEditElementGroups.push(layoutItem as UntypedFormGroup);
            }
        })
        return foundEditElementGroups;
    }

    /**
     * In the reactive form structure, searchs the form group belonging to an input element of a table column
     * @param elementTableID
     * @param elementID
     */
    searchTableEditElementFormGroupByElementIDAndTableId(elementTableID: string, elementID: string): UntypedFormGroup {
        let foundInputFormGroup: UntypedFormGroup;
        this.modulesArray.controls.forEach((moduleFormGroup, moduleIndex) => {
            if (!foundInputFormGroup) {
                const tableFormInputs: UntypedFormGroup[] = this.searchTableEditElementFormGroupsByTableId(elementTableID, moduleIndex);
                foundInputFormGroup = tableFormInputs.find((formInputGroup) =>
                    formInputGroup.get("id").value === elementID
                )
            }
        })
        return foundInputFormGroup;
    }

    /**
     * Prevents an element from being rendered in the form.
     * @param elementId
     * @param elementBelongsToTable indicating whether the input element belongs to a table add/edit row form
     */
    hideElementFormGroup(elementId: string, elementBelongsToTableId: string) {
        let formGroup: UntypedFormGroup;
        if (!elementBelongsToTableId) {
            formGroup = this.searchElementFormGrouplInReactiveFormByElementId(elementId, false);
        } else {
            formGroup = this.searchTableEditElementFormGroupByElementIDAndTableId(elementBelongsToTableId, elementId);
        }
        if (formGroup) {
            formGroup.get('avoidSubmitting').setValue(true);
            formGroup.get('visible').setValue(false);
        }
    }

    /**
     * Renders an element in the form.
     * @param elementId
     * @param elementBelongsToTable indicating whether the input element belongs to a table add/edit row form
     */
    showElementFormGroup(elementId: string, elementBelongsToTableId: string) {
        let formGroup: UntypedFormGroup;
        if (!elementBelongsToTableId) {
            formGroup = this.searchElementFormGrouplInReactiveFormByElementId(elementId, false);
        } else {
            formGroup = this.searchTableEditElementFormGroupByElementIDAndTableId(elementBelongsToTableId, elementId);
        }
        if (formGroup) {
            formGroup.get('avoidSubmitting').setValue(false);
            formGroup.get('visible').setValue(true);
        }
    }

    /**
     * Enables/disables the value (always correponding to the input value) FormControl in the element form group.
     * @param elementId
     * @param enable stating whether the form control is to be enabled or disabled
     * @param elementBelongsToTableId indicating the table ID if the input element belongs to a table add/edit row form
     * @return boolean indicating of action was taken or it wasnt' necessary
     */
    enableElementValueFormControl(elementId: string, enable: boolean, elementBelongsToTableId: string): boolean {
        let formGroup: UntypedFormGroup;
        if (!elementBelongsToTableId) {
            formGroup = this.searchElementFormGrouplInReactiveFormByElementId(elementId, false);
        } else {
            formGroup = this.searchTableEditElementFormGroupByElementIDAndTableId(elementBelongsToTableId, elementId);
        }
        if (formGroup) {
            if (enable === formGroup.get('value').disabled) {
                if (enable) {
                    formGroup.get('value').enable();
                } else {
                    formGroup.get('value').disable();
                }
                return true;
            }
        }
        return false;
    }

    /**
     * Sets the required validation on a value form control of a form element's form group. 
     * @param elementId
     * @param required
     * @param elementBelongsToTableId indicating whether the input element belongs to a table add/edit row form
     * @return boolean indicating of action was taken or it wasnt' necessary
     */
    controlElementRequiredAttribute(elementId: string, required: boolean, elementBelongsToTableId: string): boolean {
        let formGroup: UntypedFormGroup;
        if (!elementBelongsToTableId) {
            formGroup = this.searchElementFormGrouplInReactiveFormByElementId(elementId, false);
        } else {
            formGroup = this.searchTableEditElementFormGroupByElementIDAndTableId(elementBelongsToTableId, elementId);
        }
        if (formGroup) {
            if (required) {
                return this.addRequiredToFormControl(formGroup);
            } else {
                return this.deleteRequiredFromFormControl(formGroup);
            }
        }
        return false;
    }

    /**
     * Adds the required validator to the value form control and also the validation message
     * to the ValidationMessages form control of a form element form group.
     * @param formGroup
     * @return boolean indicating of action was taken or it wasnt' necessary
     */
    private addRequiredToFormControl(formGroup: AbstractControl) {
        let actionTaken = false;
        if (!formGroup.get("value").hasValidator(Validators.required)) {
            actionTaken = true;
            formGroup.get("value").addValidators(Validators.required);
            const validationMessages = formGroup.get("validationMessages").value ?? [];
            validationMessages.push({
                validationFn: "required",
                message: formatMessage("validation-required-formatted", formGroup.get("label").value ?? formGroup.get("displayName").value)
            });
            formGroup.get("validationMessages").setValue(validationMessages);
            this.setFormHasValidationRules();
        }
        return actionTaken;
    }

    /**
     * Removes the required validator from the value form control and also the validation message
     * from the ValidationMessages form control of a form element form group.
     * @param formGroup
     * @return boolean indicating of action was taken or it wasnt' necessary
     */
    private deleteRequiredFromFormControl(formGroup: AbstractControl): boolean {
        let actionTaken = false;
        if (formGroup.get("value").hasValidator(Validators.required)) {
            actionTaken = true;
            formGroup.get("value").removeValidators(Validators.required);
            let validationMessages: { validationFn: string, message: string }[] = formGroup.get("validationMessages").value;
            if (validationMessages && validationMessages.length) {
                validationMessages = validationMessages.filter((vm) => vm.validationFn !== "required");
                formGroup.get("validationMessages").setValue(validationMessages);
            }
        }
        return actionTaken;
    }

    /**
     * Updates the value (always correponding to the input value) FormControl in the element form group with the given value.
     * @param elementId
     * @param value
     * @param elementBelongsToTableId indicating whether the input element belongs to a table add/edit row form
     * @return boolean indicating of action was taken or it wasnt' necessary
     */
    updateElementFormControlValue(elementId: string, value: unknown, elementBelongsToTableId: string): boolean {
        let actionTaken = false;
        let formGroup: UntypedFormGroup;
        if (!elementBelongsToTableId) {
            formGroup = this.searchElementFormGrouplInReactiveFormByElementId(elementId, false);
        } else {
            formGroup = this.searchTableEditElementFormGroupByElementIDAndTableId(elementBelongsToTableId, elementId);
        }
        if (formGroup) {
            formGroup.get('value').setValue(value);
            actionTaken = true;
        }
        return actionTaken;
    }

    /**
     * To be called by the last rendered element (see related attributes in the variable declarations of this service).
     * It broadcasts that the form is fully loaded to the listeners interested in this event. 
     */
    public notifyLastElementLoaded() {
        this.lastElementRendered.next(true);
    }

    /**
     * To be called when leaving the form, it broadcasts that the form isn't loaded anymore. 
     */
    public notifyFormExit() {
        this.lastElementRendered.complete;
    }

    /**
     * Given the reactive form data from this service, it packs the data of the form ready for being sent
     * to the back-end in a DataOutput interface.
     *
     * @outputDataContainer DataOutput element where the data must be placed
     * @returns the data ready for being sent to the service
     */
    public packElementsOutputData(outputDataContainer: DataOutput): DataOutput {
        this.receiptService.createReceiptData(this._reactiveForm.get("formName").value);
        this.modulesArray.controls.forEach((value, index) => {
            const layoutItemsFormArray: UntypedFormArray = this.getLayoutItemsArrayFromModuleNumber(index);
            layoutItemsFormArray.controls.forEach((value) => {
                if (value.get('value') && !value.get('avoidSubmitting')?.value && !value.get('value')?.disabled && value.get('visible')?.value) {
                    // Case of editor element
                    const elementOutput: ElementOutput = {
                        ElementID: value.get('id').value,
                        Value: value.get('value').value
                    };
                    outputDataContainer.Elements.push(elementOutput);
                    this.receiptService.addEditElementValue(value.get('displayName')?.value, value.get('displayValue').value);
                } else if (value.get('itemsArray')) {
                    //Case of tables, first a loop is performed over each table item
                    const itemElementsOutputArray: ElementOutput[] = [];
                    let receiptTable: ReceiptTable = this.receiptService.createTable();

                    const itemsFormArray = value.get('itemsArray') as UntypedFormArray;
                    console.log(1, itemsFormArray);
                    itemsFormArray.controls.forEach((value, rowIndex) => {
                        const keyElementsOutputArray: ElementOutput[] = [];
                        const keysArray = value.get('keysArray') as UntypedFormArray;
                        //Adding headers to the receipt as first row
                        if (rowIndex === 0) {
                            this.submitTableHeaders(keysArray, receiptTable);
                        }
                        const receiptTableRow: ReceiptTableRow = this.receiptService.createTableRow();
                        this.submitTableRowCells(keysArray, receiptTableRow, keyElementsOutputArray);

                        receiptTable = this.receiptService.addTableRowToTable(receiptTable, receiptTableRow);
                        const itemOutput: ElementOutput = {
                            Elements: keyElementsOutputArray,
                            ElementID: value.get('itemId').value,
                        };
                        itemElementsOutputArray.push(itemOutput);

                    })
                    const tableOutput: ElementOutput = {
                        ElementID: value.get('id').value,
                        Elements: itemElementsOutputArray,
                    };
                    outputDataContainer.Elements.push(tableOutput);
                    this.receiptService.addTable(receiptTable, value.get('layoutItemTitle').value);

                }
            })
        })

        return outputDataContainer;
    }

    /**
     * Performs the submit operations corresponding to the table headers.
     * @param keysArray
     * @param receiptTable
     * @returns
     */
    private submitTableHeaders(keysArray: UntypedFormArray, receiptTable: ReceiptTable) {
        //if (keysArray.length <= this.MAX_COLUMNS_IN_RECEIPT_TABLE) {
        // The headers are only printed for the tables with >= 5 columns
        let receiptTableHeadersRow: ReceiptTableRow = this.receiptService.createTableRow();
        keysArray.controls.forEach((value, index) => {
            if (index < this.MAX_COLUMNS_IN_RECEIPT_TABLE) {
                receiptTableHeadersRow = this.receiptService.createAndAddTableCell(
                    receiptTableHeadersRow, value.get('displayName')?.value);
            }
        })
        this.receiptService.addTableRowToTable(receiptTable, receiptTableHeadersRow);
        //}
    }

    /**
     * Performs the submit operations corresponding to the table row cells.
     * @param keysArray
     * @param receiptTableRow
     * @param keyElementsOutputArray
     */
    private submitTableRowCells(keysArray: UntypedFormArray, receiptTableRow: ReceiptTableRow, keyElementsOutputArray: ElementOutput[]) {
        keysArray.controls.forEach((value, columnIndex) => {
            const keyOutput: ElementOutput = {
                ElementID: value.get('id').value,
                Value: value.get('value').value,
            };
            keyElementsOutputArray.push(keyOutput);

            if (columnIndex < this.MAX_COLUMNS_IN_RECEIPT_TABLE) {
                let printingValue = value.get('displayValue') ? value.get('displayValue').value : " - ";
                if (printingValue?.indexOf("<br>") > -1) {
                    printingValue = this.stringUtilsService.convertItemsArrayTableDisplayNamesToPDF(printingValue);
                }

                this.receiptService.createAndAddTableCell(receiptTableRow, printingValue);
            }
        });
    }
}
