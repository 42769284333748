<input type="file" class="excel-import" (change)="importTable($event)" style="display: none" accept=".xlsx" #fileUpload>
<div [attr.id]="tableElement.ElementID" class="form-table" [attr.data-display-name]="tableElement.DisplayName" data-editor-type="DateEdit">
    <label class="label">{{layoutItem.Label}}</label>
    <dx-data-grid [attr.id]="'dataGrid-' + tableElement.ElementID" [dataSource]="dataElements" [allowColumnReordering]="true" [columnAutoWidth]="true" noDataText="Es wurden noch keine Daten erfasst." (onExporting)="exportTable($event)"
        (onRowInserting)="onRowAdd($event)" (onRowInserted)="onRowAdded($event)" (onRowRemoving)="onRowRemove($event)" (onRowUpdating)="onRowUpdate($event)" (onRowUpdated)="onRowUpdated($event)" (onSaving)="forceUpdateDialog($event)"
                    (onEditingStart)="prepareEditFields($event)" (onInitNewRow)="prepareNewRow($event)" #dxDataGrid>
        <dxo-export [enabled]="true" [formats]="['csv']">
            <dxo-texts exportTo="Test">
            </dxo-texts>
        </dxo-export>
        <dxo-editing [allowDeleting]="true" [allowAdding]="true" [allowUpdating]="true" mode="popup">
            <dxo-popup [showTitle]="true" titleTemplate="title" [visible]="true" [wrapperAttr]="{ class: popupWrapperClass }">
                <div *dxTemplate="let data of 'title'">
                    <span innerHTML="{{tableTitle}}"></span>
                </div>
            </dxo-popup>
            <dxo-form colCount="1">
                <dxi-item itemType="group">
                    <div *dxTemplate class="container">
                         <app-form-layout-generator [moduleIndex]="moduleIndex" [layout]="tableLayout" [elements]="extractElementsFromGatheredElements()" [ngClass]="'form-layout-component'"></app-form-layout-generator>
                        <div class="validation-summary-container">
                            <app-validation-summary [validationMessages]="validationService.tableValidationMessages" [addAnchorNavigation]="true"></app-validation-summary>
                        </div>
                    </div>
                </dxi-item>
            </dxo-form>
        </dxo-editing>
        
        <ng-container *ngFor="let tableFormElement of gatheredTableElements; let elementIndex=index">
            <dxi-column caption="{{tableFormElement.caption}}" dataField="{{tableFormElement.element.ElementID}}"
                        dataType="{{tableFormElement.element.DataType}}" [visible]="columnIsVisibleInTable(tableFormElement.element)"
                        cellTemplate="htmlContentCell">
            </dxi-column>
            <div *dxTemplate="let data of 'htmlContentCell'">
                <div [innerHtml]="data.value"></div>
            </div>
        </ng-container>
        <dxo-toolbar>
            <dxi-item name="addRowButton"></dxi-item>
            <dxi-item name="exportButton"
                        locateInMenu="auto" showText="always"
                        location="after" [options]="{text: 'Excel-Vorlage exportieren'}"></dxi-item>
            <dxi-item locateInMenu="auto" showText="always"
                        location="after" [options]="{text: 'Excel-Vorlage importieren', icon: 'import'}"></dxi-item>
            <dxi-item widget="dxButton" name="importButton" 
                        locateInMenu="auto" showText="always"
                        location="after" [options]="importButtonOptions"></dxi-item>
        </dxo-toolbar>
    </dx-data-grid>
</div>
<ng-container *ngIf="renderImportTableErrorPopup">
    <app-popup (popupClosed)="clearPopupInfo($event)"></app-popup>
</ng-container>
