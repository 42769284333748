import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Element } from '../interfaces/form/element';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { PopupInfo } from '../interfaces/popup-info';
import { PopupType } from '../interfaces/enum/popup-type';
import { StringUtilsService } from './string-utils.service';
import { PopupService } from './popup.service';
import { CellHyperlinkValue, CellValue, Row, Workbook } from 'exceljs';
import { UIElementType } from '../interfaces/form/enums/uielement-type-enum';

type ImportResult = {
    popupInfo: PopupInfo;
    importedItems: { controlValues: Record<string, unknown>, displayValues: Record<string, string> }[];
}

/**
 * Contains the tools necessary for working with excel sheets.
 */
@Injectable()
export class ExcelService {

    constructor(private stringUtils: StringUtilsService, private popupService: PopupService) { }

    /**
     * Exports an excel sheet from a DxDataGrid element.
     * @param e
     * @param fileName: exported file name
     * @param tableElements with an Element array conforming the columns of the table to export
     */
    public exportTableFromDataGrid(e: ExportingEvent, fileName: string, tableElements: Element[]) {
        const cellBorder: any = {
            top: { style: 'thin', color: { argb: 'BDD7EE' } },
            left: { style: 'thin', color: { argb: 'BDD7EE' } },
            bottom: { style: 'thin', color: { argb: 'BDD7EE' } },
            right: { style: 'thin', color: { argb: 'BDD7EE' } }
        }
        import('exceljs').then((Excel) => {
            const workbook = new Excel.Workbook();
            const wsData = workbook.addWorksheet('Eingabeblatt');
            const wsDefinitions = workbook.addWorksheet('Definitionen');

            exportDataGrid({
                component: e.component,
                selectedRowsOnly: true,
                worksheet: wsData,
                customizeCell: function (options) {
                    const { gridCell, excelCell } = options;
                    const columnLetter = excelCell._column.letter;

                    if (gridCell.rowType === 'header') {
                        for (let j = 2; j < 202; j++) {
                            const cell = wsData.getCell(columnLetter + j);
                            cell.border = cellBorder;

                            if (tableElements[gridCell.column.visibleIndex].IsRequired) {
                                const cellFill: any = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFCC' }
                                };

                                cell.fill = cellFill;
                            }

                            // Boolean
                            if (tableElements[gridCell.column.visibleIndex].DataType === 'System.Boolean') {
                                cell.dataValidation = {
                                    type: 'list',
                                    allowBlank: true,
                                    formulae: ['=Definitionen!$' + columnLetter + '$2:$' + columnLetter + '$' + 3]
                                };
                            }

                            // Dropdownlist
                            if (tableElements[gridCell.column.visibleIndex].Items && tableElements[gridCell.column.visibleIndex].Items.length > 0) {
                                cell.dataValidation = {
                                    type: 'list',
                                    allowBlank: true,
                                    formulae: ['=Definitionen!$' + columnLetter + '$2:$' + columnLetter + '$' + (tableElements[gridCell.column.visibleIndex].Items.length + 1)]
                                };
                            }
                        }
                    }
                },
            }).then(() =>
                exportDataGrid({
                    component: e.component,
                    selectedRowsOnly: true,
                    worksheet: wsDefinitions,
                    customizeCell: function (options) {
                        const { gridCell, excelCell } = options;

                        if (gridCell.rowType === 'header') {
                            excelCell.fill = { fgColor: { argb: 'F2F2F2' }, type: 'pattern', pattern: 'solid' };
                            // Boolean
                            if (tableElements[gridCell.column.visibleIndex].DataType === 'System.Boolean') {
                                const columnLetter = excelCell._column.letter;
                                // Set data for dropdownlist
                                const definitionCellYes = wsDefinitions.getCell(columnLetter + 2);
                                const definitionCellNo = wsDefinitions.getCell(columnLetter + 3);

                                definitionCellYes.value = 'Ja';
                                definitionCellNo.value = 'Nein';
                            }
                            // Menu edit element with several options
                            else if (tableElements[gridCell.column.visibleIndex].Items && tableElements[gridCell.column.visibleIndex].Items.length > 0) {
                                const columnLetter = excelCell._column.letter;
                                // Set data for dropdownlist
                                for (let k = 0; k < tableElements[gridCell.column.visibleIndex].Items.length; k++) {
                                    const item = tableElements[gridCell.column.visibleIndex].Items[k];
                                    const definitionCell = wsDefinitions.getCell(columnLetter + (k + 2));

                                    definitionCell.value = item.DisplayName;
                                }
                            }
                        }
                        excelCell.border = cellBorder;
                    },
                })
            ).then(() => {
                this.adjustColumnWidth(wsData);
                this.adjustColumnWidth(wsDefinitions);
                workbook.xlsx.writeBuffer()
                    .then(function (buffer: BlobPart) {

                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName.toString());
                    })
            });
        })



        e.cancel = true;
    }

    /**
     * Imports a valid excel sheet into a datagrid.
     * @param file with the event uploaded file
     * @param dxDataGrid with the dxDataGrid to export the file to
     * @param tableGatheredColumns with the array of the elements that belong to the columns of the table
     * @return ImportResult object with the info for popups and/or the imported items
     */
    public importExcelToDataGrid(file: File, dxDataGrid: DxDataGridComponent, tableGatheredColumns: Element[]): Observable<{ controlValues: Record<string, unknown>, displayValues: Record<string, string> }[]> {
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('\\') + 1).split('.')[1];
        const fileReader = new FileReader();
        if (fileExtension === 'csv') {
            fileReader.readAsText(file);
            return new Observable((subscriber): void => {
                fileReader.onload = ((): void => {
                    const importResult: ImportResult = this.importCsvFile(fileReader.result, dxDataGrid, tableGatheredColumns);
                    if (importResult.popupInfo) {
                        this.popupService.popupInfo = importResult.popupInfo;
                        subscriber.error(new Error("Error importing table"));
                    }
                    subscriber.next(importResult.importedItems);
                })
            })
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            fileReader.readAsArrayBuffer(file);
            return new Observable((subscriber): void => {
                fileReader.onload = ((): void => {
                    const buffer: ArrayBuffer = <ArrayBuffer>fileReader.result;
                    import('exceljs').then((Excel) => {
                        const wb = new Excel.Workbook();
                        wb.xlsx.load(buffer).then((workbook: Workbook) => {
                            const importResult = this.importXlsFile(workbook, dxDataGrid, tableGatheredColumns);
                            if (importResult.popupInfo) {
                                this.popupService.popupInfo = importResult.popupInfo;
                                subscriber.error(new Error("Error importing table"));
                            }
                            subscriber.next(importResult.importedItems);
                        })
                    })


                })
            })
        } else {
            this.popupService.popupInfo = { title: "CSV-Import", content: "Es wurden keine Daten zum Importieren gefunden.", type: PopupType.ERROR }
            return throwError(() => "Error importing table");
        }

    }



    /**
     * Imports a CSV file into a datagrid.
     * @param buffer with the readed information of the CSV file
     * @param dxDataGrid with the dxDataGrid element to export the file to
     * @param tableGatheredColumns with the array of the elements that belong to the columns of the table
     * @return array of imported items
     */
    private importCsvFile(buffer: string | ArrayBuffer, dxDataGrid: DxDataGridComponent, tableGatheredColumns: Element[]): ImportResult {
        const importedItems: { controlValues: Record<string, unknown>, displayValues: Record<string, string> }[] = [];
        let popupInfo: PopupInfo;
        let headerColumns: string[] = [];
        const rows = (buffer as string).split("\r\n");
        if (rows.length > 1) {
            // Collect header columns
            headerColumns = rows[0].split(";");
            for (let i = 1; i < rows.length; i++) {
                let newItemValue: Record<string, unknown> = {};
                let newItemDisplayValue: Record<string, string> = {};
                const row = rows[i];
                const columns = row.split(";");
                if (columns.length !== headerColumns.length) {
                    continue;
                }
                for (let j = 0; j < columns.length; j++) {
                    const column = columns[j];

                    const dataGridColumn: DxiColumnComponent = dxDataGrid.columnsChildren.find((col) => col.caption == headerColumns[j]);
                    if (dataGridColumn) {
                        const tableElement = tableGatheredColumns.find((tableElement) => tableElement.ElementID == dataGridColumn.dataField);

                        const dataField = dataGridColumn.dataField;
                        const dataType = dataGridColumn.dataType;
                        newItemDisplayValue[dataField] = this.stringUtils.convertItemExcelDisplayNamesToTable(column);
                        if (column) {
                            if (tableElement.Items) {
                                //Case of elements where an item (or several) can be chosen
                                newItemValue[dataField] = this.stringUtils.convertTableCellDisplayNamesToValues(column, tableElement.Items);
                            } else {
                                switch (dataType) {
                                    case 'System.Boolean':
                                        newItemValue[dataField] = this.stringUtils.convertYesNoStringToBoolean(column);
                                        break;

                                    case 'System.DateTime':

                                        if (this.stringUtils.convertDateStringToDate(column).getDate()) {
                                            newItemValue[dataField] = this.stringUtils.convertDateStringToDate(column);
                                        }
                                        break;
                                    case 'System.Decimal':
                                        newItemValue[dataField] = this.stringUtils.getNumberValueFromDisplayValue(tableElement.UIElement, column);
                                        newItemDisplayValue[dataField] = this.stringUtils.getNumberDisplayValue(tableElement.UIElement, newItemValue[dataField] as number);
                                        break;
                                    default:
                                        newItemValue[dataField] = column;
                                        break;
                                }
                            }
                        }
                    } else {
                        popupInfo = { title: "CSV-Import", content: "Es wurde keine Spalte mit name ".concat(headerColumns[j]).concat(" in der Tabelle gefunden."), type: PopupType.ERROR };
                        newItemValue = null;
                        newItemDisplayValue = null;
                        break;
                    }
                }
                importedItems.push({ controlValues: newItemValue, displayValues: newItemDisplayValue });
                console.log(importedItems);
            }
        }
        const importResult = { popupInfo: popupInfo, importedItems: importedItems };
        return importResult;

    }

    /**
   * Imports a .xls or .xlst file into a datagrid.
   * @param workbook with the imported workbook
   * @param dxDataGrid with the dxDataGrid element to export the file to
   * @param tableGatheredColumns with the array of the elements that belong to the columns of the table
   * @return array of imported items
   */
    private importXlsFile(workbook: Workbook, dxDataGrid: DxDataGridComponent, tableGatheredColumns: Element[]): ImportResult {
        // Load .xls/.xlsx
        const importedItems: { controlValues: Record<string, unknown>, displayValues: Record<string, string> }[] = [];
        let popupInfo: PopupInfo;
        const sheet = workbook.getWorksheet('Eingabeblatt');
        if (sheet) {
            const headerColumns: string[] = [];
            sheet.eachRow((row: Row, rowIndex: number) => {
                let newItemValue: Record<string, unknown> = {};
                let newItemDisplayValue: Record<string, string> = {};
                const columns = row.values as CellValue[];
                for (let j = 1; j < columns.length; j++) {
                    if (rowIndex == 1 && columns[j]) {
                        headerColumns[j - 1] = columns[j].valueOf() as string;
                    } else {
                        const dataGridColumn: DxiColumnComponent = dxDataGrid.columnsChildren.find((col) => col.caption == headerColumns[j - 1]);

                        if (dataGridColumn) {
                            const tableElement = tableGatheredColumns.find((tableElement) => tableElement.ElementID == dataGridColumn.dataField);
                            let cellValue = columns[j];
                            let controlValue;
                            let displayValue: string;
                            const dataField = dataGridColumn.dataField;
                            const dataType = dataGridColumn.dataType;

                            if (cellValue) {
                                // Links
                                if ((<CellHyperlinkValue>cellValue).text) {
                                    cellValue = (<CellHyperlinkValue>cellValue).text;
                                }
                                
                                if (tableElement.Items) {
                                    //Case of elements where an item (or several) can be chosen
                                    controlValue = this.stringUtils.convertTableCellDisplayNamesToValues(cellValue as string, tableElement.Items);
                                    displayValue = cellValue.toString();
                                } else {
                                    switch (dataType) {
                                        // Boolean
                                        case 'System.Boolean':
                                            controlValue = this.stringUtils.convertYesNoStringToBoolean(cellValue as string);
                                            displayValue = cellValue.toString();
                                            break;

                                        // Datetime
                                        case 'System.DateTime':
                                            if (typeof displayValue === 'string' || cellValue instanceof String) {
                                                controlValue = this.stringUtils.convertDateStringToDate(cellValue as string);
                                                displayValue = this.stringUtils.convertDateToDateString(controlValue, tableElement.UIElement === UIElementType.DateEditWithTime);
                                            }
                                            else {
                                                controlValue = this.stringUtils.convertDateNumberToDate(cellValue as number);
                                                displayValue = this.stringUtils.convertDateToDateString(controlValue, tableElement.UIElement === UIElementType.DateEditWithTime);
                                            }
                                            break;

                                        // Numbers
                                        case 'System.Decimal':
                                            controlValue = cellValue;
                                            displayValue = this.stringUtils.getNumberDisplayValue(tableElement.UIElement, controlValue as number);
                                            break;
                                        default:
                                            controlValue = cellValue.toString();
                                            displayValue = cellValue.toString();
                                            break;
                                    }
                                }

                                newItemValue[dataField] = controlValue;
                                newItemDisplayValue[dataField] = this.stringUtils.convertItemExcelDisplayNamesToTable(displayValue);
                            }

                        } else {
                            popupInfo = { title: "Excel-Import", content: "Es wurde keine Spalte mit name ".concat(headerColumns[j - 1]).concat(" in der Tabelle gefunden."), type: PopupType.ERROR };
                            newItemValue = null;
                            newItemDisplayValue = null;
                            break;
                        }
                    }
                }
                if (rowIndex > 1) {
                    importedItems.push({ controlValues: newItemValue, displayValues: newItemDisplayValue });
                }
            })
        }
        const importResult = { popupInfo: popupInfo, importedItems: importedItems };
        return importResult;
    }


    /**
     * LEGACY FUNCTIONS
     */

    /**
     * Adjusts the with of the columns to be longer than the content.
     * @param worksheet with the Excel.Worksheet to adjust
     */
    public adjustColumnWidth(worksheet: any) {
        worksheet.columns.forEach((column: any) => {
            const lengths = column.values.map((v: any) => v.toString().length + 5) // 5 offset;
            const maxLength = Math.max(...lengths.filter((x: any) => { return typeof x === 'number' }));
            column.width = maxLength;
        });
    }
}
