import { Component, OnInit, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Element } from '../../../../interfaces/form/element';
import { Item } from '../../../../interfaces/form/item';
import { ElementValidationService } from '../../../../services/form/element-validation.service';
import { CustomItemCreatingEvent, SelectionChangedEvent } from 'devextreme/ui/select_box';
import { ItemService } from '../../../../services/item.service';
import { UntypedFormGroup } from '@angular/forms';
import { FormElementOperationsService } from '../../../../services/form/form-element-operations.service';
import { ElementFunctionChangeInformation } from '../../../../interfaces/form/elements/element-function-change-information';
import { ElementChangeInformation } from '../../../../interfaces/form/elements/element-change-information';
import { InputInformation } from '../../../../interfaces/form/elements/input-information';

@Component({
  selector: 'app-combo-box-edit',
  templateUrl: './combo-box-edit.component.html',
  styleUrls: ['./combo-box-edit.component.less']
})
/**
 * This component implements the logic for the combo box edit component.
 */
export class ComboBoxEditComponent implements OnInit {

    @Input() element: Element;
    @Input() inputFormGroup: UntypedFormGroup;
    @Input() moduleIndex: number;
    @Input() labelPosition: string;

    public sortedItems: Item[] = [];
    public itemsDataSource: DataSource;
    private _selectedItem: Item;

    public renderingInformation: InputInformation;

    constructor(private validationService: ElementValidationService, private itemService: ItemService, private elementOperations: FormElementOperationsService) { }

    /**
    * Initialization method where the items are loaded and stored in a data source for the view to handle them.
    */
    ngOnInit(): void {
        const elementItems: Item[] = this.element.Items as Item[];
        if (elementItems.length) {
            this.sortedItems = this.itemService.sortItemsBySortAttribute(elementItems, this.element.ElementItemsSortMode);
            this.itemsDataSource = new DataSource({
                store: {
                    data: this.sortedItems,
                    type: 'array',
                    key: 'DisplayName',
                },
            });
            this.setInputFromValue();
            this.inputFormGroup.get('displayValue').setValue(this.selectedItem ? this.selectedItem.DisplayName : null);
        }
        this.renderingInformation = this.elementOperations.initializeValidation(this.element, this.inputFormGroup,
            this.selectedItem ? this.selectedItem.Value : null);
        this.subscribeToElementChanges();
    }

    /**
     * Method called when the user enters a value that wasn't belonging to the intially selected Items.
     * @param data
     * @returns
     */
    onCustomItemCreate(data: CustomItemCreatingEvent) {
        if (!data.text) {
            data.customItem = null;
            return;
        }
        this.addCustomItem(data.text);
    }
        
    /**
     * Adds a custom item to the seletable items list of the component.
     * @param customItemValue
     */
    private addCustomItem(customItemValue: unknown) {
        const newItemId = this.createCustomItemValueFromDisplayValue(customItemValue);
        const newItem = {
            Value: newItemId,
            DisplayName: customItemValue,
        };

        this.itemsDataSource.store().insert(newItem)
            .then(() => this.itemsDataSource.load())
            .then(() => newItem)
            .catch((error) => {
                throw error;
            });
    }

    /**
     * When the user inserts a custom value, it is taken as display value and here it is created
     * an unique value for that entered custom value.
     * @param customItemDisplayName
     * @returns
     */
    private createCustomItemValueFromDisplayValue(customItemDisplayName: unknown): string {
        return (customItemDisplayName as string) + this.sortedItems.length;
    }

    /**
     * Getter method of the selection.
     */
    get selectedItem(): Item {
        return this._selectedItem;
    }

    /**
     * Setter method of the selection.
     */
    set selectedItem(value: Item) {
        this._selectedItem = value;
    }

    /**
     * Event listener when the user performs a selection.
     * @param e
     */
    changeComboBoxEditValue(e: SelectionChangedEvent) {
        this.selectedItem = e.selectedItem;
        this.renderingInformation.validationInformation = this.elementOperations.processValueChangeValidation(
            this.element, this.inputFormGroup, this.moduleIndex, this.selectedItem ? this.selectedItem.Value : null,
            this.selectedItem ? this.selectedItem.DisplayName : null);
    }

    /**
     * Updates the input value according to the form of the element form control fromt he reative form structure.
     */
    private setInputFromValue() {
        const elementValue = this.inputFormGroup.get("value")?.value;
        if (elementValue) {
            if (Array.isArray(elementValue)) {
                // It can happen (from excel import to table, for example) that the select values are
                // placed in an array
                this.selectedItem = this.sortedItems.find((item) =>
                    (elementValue as string[]).indexOf(item.Value) !== -1
                )
            } else {
                this.selectedItem = this.sortedItems.find((item) =>
                    item.Value === elementValue
                )
            }
        }
    }

    /**
     * Each time that the status in this form element is changed by another element with either
     * validations of logic functions, the change is produced in the element form group of the reactive
     * form structure, then broadcasted and finally listened here, where the info is updated.
     */
    private subscribeToElementChanges() {
        this.validationService.formElementChanged.subscribe((data: ElementFunctionChangeInformation) => {
            if (data.elementId === this.element.ElementID) {
                const elementChangeInfo = {} as ElementChangeInformation;
                elementChangeInfo.elementFunctionChangeInformation = data;
                elementChangeInfo.element = this.element;
                elementChangeInfo.moduleIndex = this.moduleIndex;
                this.renderingInformation = this.elementOperations.processElementChangeValidation(
                    elementChangeInfo, this.inputFormGroup, this.selectedItem ? this.selectedItem.Value : null);
                if (this.renderingInformation.updatedAfterOperation) {
                    this.setInputFromValue();
                    this.inputFormGroup.get('displayValue').setValue(this.selectedItem ? this.selectedItem.DisplayName : null);
                }
            }
        })
    }

}
