import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Element } from '../../../interfaces/form/element';
import { LayoutItem } from '../../../interfaces/form/layout-item';
import { UIElementType } from '../../../interfaces/form/enums/uielement-type-enum';
import { ReactiveFormService } from '../../../services/form/reactive-form.service';
import { ElementValidationService } from '../../../services/form/element-validation.service';

@Component({
  selector: 'app-form-element-generator',
  templateUrl: './form-element-generator.component.html',
  styleUrls: ['./form-element-generator.component.less'],
})
/**
* This creates, for the as-input-received element form group, the reactive form data structure
* necessary for render an element as a form component. The form data is based in the actual element
* received as an input, and also in certain parameters of the layout item form group where the generated
* element form group will be placed.
*/
export class FormElementGeneratorComponent implements OnInit, AfterViewInit {

    @Input() element: Element;
    @Input() layoutItem: LayoutItem;
    @Input() moduleIndex: number;

    public parentRootFormGroup: UntypedFormGroup;
    public elementFormGroup: UntypedFormGroup;

    inputValue: unknown;
    belongsToTableId: string;

    valueValid: boolean;
    validationMessage = {};


    constructor(public formService: ReactiveFormService, private validationService: ElementValidationService) { }


    /**
     * Initialization method. The label information is created and, shall the element have an initial value, it is set in the form structure.
     */
    ngOnInit(): void {
        this.parentRootFormGroup = this.formService.getReactiveForm();
        this.elementFormGroup = this.getElementFormGroup();
        this.inputValue = this.elementFormGroup.get('value')?.value;
        this.belongsToTableId = this.elementFormGroup.get('belongsToTableId')?.value;
    }

    /**
     * After the current element (and the children edit field) have been rendered,
     * check whether it is the last element rendered. If so, notify the reactive
     * form service so it can emit the form loaded event.
     */
    ngAfterViewInit() {
        if (this.element.ElementID === this.formService.lastElementIdAdded) {
            this.formService.notifyLastElementLoaded();
        }
    }

    /**
     * Getter method for making the UIElementType available in the template.
     */
    public get UIElementType() {
        return UIElementType;
    }

    /**
     * Gets the form group corresponding to the current element.
     * @returns
     */
    public getElementFormGroup(): UntypedFormGroup {
        return (this.formService.searchElementFormGrouplInReactiveFormByElementId(this.element.ElementID, false) as UntypedFormGroup);
    }
}
