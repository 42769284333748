import { Injectable } from '@angular/core';
import { Receipt } from '../interfaces/form/receipt';
import { ReceiptTable } from '../interfaces/form/receipt-table';
import { ReceiptTableRow } from '../interfaces/form/receipt-table-row';
import { StringUtilsService } from './string-utils.service';

@Injectable({
  providedIn: 'root'
})
/**
 This service is in charge of performing the operations related to the Receipt information, and also holding
the data for being shared in between components
 */
export class ReceiptService {

    constructor(private stringUtils: StringUtilsService) { }

    // To be set after form submit with the form data
    private _submittedReceiptData: Receipt[];


    public get submittedReceiptData(): Receipt[] {
        return this._submittedReceiptData;
    }

    public set submittedReceiptData(receiptData: Receipt[]) {
        this._submittedReceiptData = receiptData;
    }


    /**
     * Creates an empty instance of receipt data.
     */
    public createReceiptData(formName: string) {
        this.submittedReceiptData = [];
        const creationDate = new Date();
        this.submittedReceiptData.push({
            DisplayName: this.stringUtils.convertDateToDateString(creationDate, true),
            Type: 'CreationDate',
            Value: creationDate
        });
        this.submittedReceiptData.push({
            DisplayName: formName,
            Type: 'FormName',
            Value: formName
        });
    }

    /**
     * Add a name and value pair for the representation of form edit fields outside tables.
     * @param name
     * @param value
     */
    public addEditElementValue(name: string, value: string) {
        this.submittedReceiptData.push({
            DisplayName: name,
            Type: 'Editor',
            Value: value
        });
    }

    /**
     * Creates a table for a receipt. Returns the table instance so the rows can be added
     * to that specific table later on.
     * @returns created table
     */
    public createTable(): ReceiptTable {
        return {
            Rows: []
        };
    }

    /**
     * Adds the specified receipt table with the specified name to the receipt data.
     * @param table
     * @param name
     */
    public addTable(table: ReceiptTable, tableName: string) {
        this.submittedReceiptData.push({
            DisplayName: tableName,
            Table: table,
            Type: 'Table'
        })
    }

    /**
     * Creates a table row for a receipt table. Returns the row instance so the columns can be added
     * to that specific row later on.
     * @returns created row
     */
    public createTableRow(): ReceiptTableRow {
        return {
            Columns: []
        };
    }

    /**
     * Adds the specified row to the specified receipt table.
     * @param table
     * @param row
     * @returns
     */
    public addTableRowToTable(table: ReceiptTable, row: ReceiptTableRow): ReceiptTable {
        table.Rows.push(row);
        return table;
    }

    /**
     * Creates and adds a cell to a receipt table row with the specified value. Returns the row instance
     * so more columns can be added to that specific instance later on.
     * @param row to add the column in
     * @param value with the cell content
     * @returns row after adding the cell
     */
    public createAndAddTableCell(row: ReceiptTableRow, value: string): ReceiptTableRow {
        row.Columns.push({
            Value: value,
        });
        return row;
    }

    /**
     * Edits the value of a receipt table cell, given the row, the index of the column and the value.
     * @param row
     * @param columnIndex
     * @param value
     */
    public editTableCell(row: ReceiptTableRow, columnIndex: number, value: string) {
        row.Columns[columnIndex] = { Value: value };
    }
}
