import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewStateDataService } from '../../services/view-state-data.service';

@Component({
  selector: 'app-form-success-page',
  templateUrl: './form-success-page.component.html',
  styleUrls: ['./form-success-page.component.less']
})
export class FormSuccessPageComponent implements OnInit {

    constructor(private router: Router, private viewStateDataService: ViewStateDataService) { }

    public ngOnInit() {
        if (!this.viewStateDataService.getFormSuccess()) {
            this.router.navigate(['/']);
        }
    }

}
