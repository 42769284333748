import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PopupInfo } from '../interfaces/popup-info';
import { PopupType } from '../interfaces/enum/popup-type';
import { DataInput } from '../interfaces/form/data-input';
import { DataOutput } from '../interfaces/form/data-output';
import { GeneralDataResponse } from './objects/general-data-response';
import { PopupService } from '../services/popup.service';

/**
 * This service contains all the client methods for consuming the back-end services
 * related to the form.
 */
@Injectable({
  providedIn: 'root'
})
export class FormClientService {

    constructor(private httpClient: HttpClient, private popupService: PopupService) { }

    /**
    * Gets the input data from backend for the given inputId.
    * @param request with the ID of the input to retrieve
    * @returns
    */
    public getDataById(request: R23_Webforms.Model.General.Request<string>): Observable<DataInput> {
        return this.httpClient.post<GeneralDataResponse>(`api/Form/GetDataById`, request, { withCredentials: true }).pipe(
            map((response) => {
                //Successful request
                return response.Data;
            }),
            catchError(
                // Wrong request
                this.handleError.bind(this)
            )
        );
    }

    /**
    * Saves the given DataOutput object in back-end.
    * @param request containing the DataOuput object with the form submitted data
    * @returns
    */
    public saveData(request: R23_Webforms.Model.General.Request<DataOutput>): Observable<boolean> {
        
        return this.httpClient.post<R23_Webforms.Model.General.Response>(`api/Form/SaveData`, request, { withCredentials: true }).pipe(
            map((response) => {
                if (response.Data) {
                    // Submit successful
                    return response.Data;
                } else {
                    // Request successful, submit error
                    const popupInfo: PopupInfo = {
                        title: "Error",
                        content: "Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.",
                        type: PopupType.ERROR
                    }
                    this.popupService.popupInfo = popupInfo;
                    return false;
                }
            }),
            catchError(
                // Request error
                this.handleError.bind(this)
            )
        );
    }

    /**
    * Method in charge of handling request errors. Keep udating this method to match the requirements.
    * @param error
    */
    private handleError(error: HttpErrorResponse) {
        const popupInfo: PopupInfo = { title: null, content: null, type: null };
        if (error.status === 0) {
            popupInfo.title = "Error";
            popupInfo.content = "Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.";
            popupInfo.type = PopupType.ERROR;
        } else {
            switch (error.status) {
                case 401:
                case 403:
                    popupInfo.title = "Access denied";
                    popupInfo.content = "Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.";
                    popupInfo.type = PopupType.ERROR;
                    break;
                default:
                    popupInfo.title = "Error";
                    popupInfo.content = "Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.";
                    popupInfo.type = PopupType.ERROR;
                    break;
            }
        }
        this.popupService.popupInfo = popupInfo;

        throw throwError(() => "Error in the request");
    }
}
