<div id="InfoContainer" class="container">
    <div class="row">
        <div class="col-md-12">
            <h1>Login</h1>
            <p>
                Bitte geben Sie Ihren Namen ein, um fortzufahren.
            </p>

            <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
                <div class="form-container">
                    <ng-container formGroupName="email">
                        <dx-text-box id="LoginInput" [ngClass]="loginForm.get('email').get('value').valid ? 'form-editor' : 'form-editor invalid-input'"
                                     type="text" autocomplete="off" formControlName="value" (onValueChanged)="valueChanged($event)"></dx-text-box>
                    </ng-container>
                </div>
                <div class="action-container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="validation-summary-container">
                                <ng-container *ngIf="!loginForm.valid">
                                    <app-validation-summary [validationMessages]="validationMessages" [addAnchorNavigation]="false"></app-validation-summary>
                                </ng-container>
                            </div>
                            <button type="submit" class="button">
                                <span i18n>Absenden</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

