<ng-template [ngIf]="branding">
    <header>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ng-template [ngIf]="branding">
                        <div class="topbar">
                            <ng-template [ngIf]="branding.Icon">
                                <img [attr.src]="brandingIconUrl" />
                            </ng-template>
                            <span> {{branding.Name}} </span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </header>
</ng-template>
