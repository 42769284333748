import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupInfo } from '../../interfaces/popup-info';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.less']
})
export class PopupComponent implements OnInit {

    // Send only in case the popup texts are to be obtained from the input.
    // If this input has no value, the texts will be obtained from the popup service.
    @Input() popupInfoInput: PopupInfo;
    @Output() popupClosed = new EventEmitter<boolean>();

    popupInfo: PopupInfo;
    popupIsVisible: boolean;

    constructor(private popupService: PopupService) { }

    /**
     * Applies on rendering the popup. If the popup data comes from an input, it will
     * take that data for rendering the popup. If not, it will take it from the popup service.
     */
    ngOnInit(): void {
        this.popupIsVisible = true;
        if (this.popupInfoInput) {
            this.popupInfo = this.popupInfoInput;
        } else {
            this.popupInfo = this.popupService.popupInfo;
        }
    }

    /**
     * Receives the event of the user clicking in the (x) button for closing the popup.
     * Closes the popup and emits the event to the instancing component so it can
     * take further action if required.
     */
    closePopup() {
        this.popupIsVisible = false;
        this.popupService.popupInfo = null;
        this.popupClosed.emit(true);
    }

}
