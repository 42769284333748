import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PopupInfo } from '../interfaces/popup-info';
import { PopupType } from '../interfaces/enum/popup-type';
import { User } from '../interfaces/user/user';


@Injectable({
  providedIn: 'root'
})
export class UserClientService {

    constructor(private httpClient: HttpClient) { }

    /**
       * Authenticates a user.
       * @returns
       */
    public authenticateUser(): Observable<{ user: User, popupInfo: PopupInfo }> {
        return this.httpClient.post<unknown>(`api/User/Authenticate`, {} , { withCredentials: true }).pipe(
            map(() => {
                //Successful request. Placeholder implementation
                return { user: null, popupInfo: null };
            }),
            catchError(
                this.handleError
            )
        )
    }

    /**
    * Method in charge of handling request errors. Keep udating this method to match the requirements.
    * @param error
    * @returns
    */
    private handleError(error: HttpErrorResponse): Observable<{ user: User, popupInfo: PopupInfo }> {
        const returnInfo: PopupInfo = { title: "", content: "", type: null };
        if (error.status === 0) {
            returnInfo.title = 'Error';
            returnInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
            returnInfo.type = PopupType.ERROR;
        } else {
            switch (error.status) {
                case 401:
                case 403:
                    returnInfo.title = 'Access denied';
                    returnInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
                    returnInfo.type = PopupType.ERROR;
                    break;
                default:
                    returnInfo.title = 'Error';
                    returnInfo.content = 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.';
                    returnInfo.type = PopupType.ERROR;
                    break;
            }
        }
        // Return an observable with a user-facing error message.
        return new Observable((subscriber): void => {
            subscriber.next({ user: null, popupInfo: returnInfo });
        });
    }
}
