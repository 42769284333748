import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../interfaces/user/user';

@Injectable({
  providedIn: 'root'
})
/**
 * This service contains all the attributes and methods needed for handling
 * the user session.
 */
export class UserSessionService {

    private _refreshTokenTimeout: NodeJS.Timeout;
    private _userSubject: BehaviorSubject<User | null>;

    constructor() {
        this._userSubject = new BehaviorSubject<User | null>(null);
    }

    /**
     * Sets the new user to the user subject so it can be then broadcasted.
     */
    public set currentUser(user: User | null) {
        this._userSubject.next(user);
    }

    public get currentUser(): User | null {
        return this._userSubject.value;
    }

}
