<div id="InfoContainer" class="container">
    <div class="row">
        <div class="col-md-12">
            <h1>404</h1>
            <p>
                Der von Ihnen gewünschte Service ist nicht mehr verfügbar.<br />
                Bitte wenden Sie sich an Ihren Ansprechpartner.
            </p>
            <!-- <button (click)="loginKeyCloak()">Login mit KeyCloak</button> -->
        </div>
    </div>
</div>
