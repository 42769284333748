import { Component, Input, OnInit } from '@angular/core';
import { Layout } from '../../../interfaces/form/layout';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ReactiveFormService } from '../../../services/form/reactive-form.service';
import { Element } from '../../../interfaces/form/element';
import { Table } from '../../../interfaces/form/table';
import { UIElementType } from '../../../interfaces/form/enums/uielement-type-enum';
import { LayoutItem } from '../../../interfaces/form/layout-item';
import { LayoutGrid } from '../../../interfaces/form/layout/layout-grid';
import { LayoutGridColumn } from '../../../interfaces/form/layout/layout-grid-column';

@Component({
    selector: 'app-form-layout-generator',
    templateUrl: './form-layout-generator.component.html',
    styleUrls: ['./form-layout-generator.component.less']
})
export class FormLayoutGeneratorComponent implements OnInit {

    @Input() moduleIndex: number;
    @Input() layout: Layout;
    @Input() elements: Element[];
    @Input() tables: Table[];

    public formControlStructure: UntypedFormGroup;

    public layoutGrid: LayoutGrid;

    // The found layout item-element mappings through the layout will be gathered
    // here for the view to have fast access to them.
    private layoutElementPairs: { elementId: string, layoutItem: LayoutItem }[] = [];


    constructor(private formService: ReactiveFormService) { }

    /**
     * Init method where the main form structure is received. With the layout data in hand,
     * it creates an object that contains the basic data for the HTML to render the
     * layout as specified in the view.
     */
    ngOnInit() {
        if (this.layout) {
            this.formControlStructure = this.formService.getReactiveForm();
            this.layoutGrid = this.createLayoutGrid(this.layout.LayoutItems, this.layout.Orientation !== "Vertical");
            this.generateLayoutGrid(this.layout.LayoutItems, this.layout.Orientation !== "Vertical", this.layoutGrid);
        }
    }

    /**
     * For any layout or layout item, it determines the maximum number of elements per row,
     * excepting the case of horizontal layouts/layout items containing other layout items,
     * which are not taken here into account (a new, nested grid will be generated for them)
     * @param layoutItems contained as immediate child of the layout or layout items
     * @param horizontal stating the parent layout/layout item orientation
     * @param layoutGrid with the layout grid that will contain the object tree of the layout
     * @returns the number of objects in a layout grid, not counting nested horizontal groups
     * of layout items
     */
    private getLayoutGridColumns(layoutItems: LayoutItem[], horizontal: boolean, layoutGrid: LayoutGrid): number {
        let returnColumnsNumber: number = layoutGrid.columnsNumber;
        layoutItems.forEach((layoutItem) => {
            if (layoutItem.Type === "Element") {
                if (horizontal) {
                    returnColumnsNumber = layoutItems.length * layoutGrid.columnsNumber;
                }
            } else {
                let returnedColumnsNumber: number;
                if (!horizontal) {
                    // The number of columns must be obtained just for vertical layouts
                    // Horizontal layouts are ignored as they require a new, nested layout
                    returnedColumnsNumber = this.getLayoutGridColumns(layoutItem.LayoutItems, layoutItem.Orientation !== "Vertical", layoutGrid);
                }
                if (returnedColumnsNumber > returnColumnsNumber) {
                    returnColumnsNumber = returnedColumnsNumber;
                }
            }
        })
        return returnColumnsNumber;
    }

    /**
     * Generates an object tree that is easily to be interpretated by the HTML component.
     * @param layoutItems contained as immediate child of the layout or layout items
     * @param horizontal stating the parent layout/layout item orientation
     * @param parentGrid with the layout grid to analyze
     */
    private generateLayoutGrid(layoutItems: LayoutItem[], horizontal: boolean, parentGrid: LayoutGrid) {

        layoutItems.forEach((layoutItem) => {
            if (layoutItem && layoutItem.Type === "LayoutGroup") {

                if (horizontal) {
                    // In case of a layoutgroup whose parent is horizontal, the column will contain
                    // a new (nested) grid for its content
                    const nestedGrid = this.createLayoutGrid(layoutItem.LayoutItems, layoutItem.Orientation !== "Vertical");
                    this.addLayoutGridContainerColumn(layoutItem, layoutItems.length, parentGrid, nestedGrid);
                    this.addLayoutItemHeader(layoutItem, nestedGrid);
                    this.generateLayoutGrid(layoutItem.LayoutItems, layoutItem.Orientation !== "Vertical", nestedGrid);
                } else {
                    // In case of a layoutgroup whose parent is vertical, no column is added to the grid
                    this.addLayoutItemHeader(layoutItem, parentGrid);
                    this.generateLayoutGrid(layoutItem.LayoutItems, layoutItem.Orientation !== "Vertical", parentGrid);
                }



            } else if (layoutItem && layoutItem.Type === "Element") {
                this.layoutElementPairs.push({ elementId: layoutItem.ElementID, layoutItem: layoutItem })
                if (layoutItem.LabelPosition === "Left") {
                    this.addLabelLeftAndInputColumns(layoutItem, horizontal, layoutItems.length, parentGrid);
                } else {
                    this.addLabelTopAndInputColumn(layoutItem, horizontal, layoutItems.length, parentGrid);
                }
            }

        });

    }

    /**
    * For the given module specified by the index, the form array of first-level children layout items si returned.
    * @param moduleIndex
    * @returns
    */
    getLayoutItemsArrayFromModuleNumber(moduleIndex: number): UntypedFormArray {
        return this.formService.getLayoutItemsArrayFromModuleNumber(moduleIndex);
    }

    /**
     * For a layout item of type 'Element', gets the corresponding Element in Module.
     * @param layoutItemElementId
     * @returns
     */
    getElementByElementId(layoutItemElementId: string): Element {
        return this.elements.find(
            function (element) {
                return (element.ElementID === layoutItemElementId)
            });
    }

    /**
     * Gets the element-containing layout from the given element id.
     * @param layoutItemElementId
     * @returns
     */
    getLayoutItemByElementId(layoutItemElementId: string): LayoutItem {
        return this.layoutElementPairs.find((li) =>
            li.elementId === layoutItemElementId
        )?.layoutItem;
    }

    /**
     * States whether an element is to be rendered or not, based on the loaded form with validations.
     * @param layoutItemElementId
     * @returns
     */
    isElementVisible(layoutItemElementId: string): boolean {
        let visible = false;
        const element = this.getElementByElementId(layoutItemElementId);
        if (element) {
            visible = this.formService.isElementVisible(element);
        }
        return visible;
    }


    /**
     * Getter method for making the UIElementType available in the template.
     */
    public get UIElementType() {
        return UIElementType;
    }

    /**
     * Creates a Layout Grid object for the layout or horizontal layout item.
     * @param layoutItems with the direct children of the layout/layout item
     * @param horizontal stating whether the layout/layout item is horizontal
     * @returns
     */
    private createLayoutGrid(layoutItems: LayoutItem[], horizontal: boolean): LayoutGrid {
        const newLayoutGrid: LayoutGrid = {
            columns: [],
            columnsNumber: 1
        };
        newLayoutGrid.columnsNumber = this.getLayoutGridColumns(layoutItems, horizontal, newLayoutGrid) * 2;
        return newLayoutGrid;

    }

    /**
     * A column is added in a way that indicates de HTML that it is meant to act as a
     * container for another nested grid.
     * @param layoutItem
     * @param siblingsNumber
     * @param parentGrid
     */
    private addLayoutGridContainerColumn(layoutItem: LayoutItem, siblingsNumber: number, parentGrid: LayoutGrid, nestedGrid: LayoutGrid) {
        const layoutGridColumn: LayoutGridColumn = {
            layoutTitle: null,
            colSpan: parentGrid.columnsNumber / siblingsNumber,
            elementId: null,
            cssClass: "horizontal-layout-column",
            layoutGrid: nestedGrid
        }
        parentGrid.columns.push(layoutGridColumn);
    }

    /**
     * Adds a layout grid column to the layout grid with the layout item title, if proceeding.
     * @param layoutItem with the layout item (from the input data) under evaluation
     * @param layoutGrid where the layout header column is to be added
     */
    private addLayoutItemHeader(layoutItem: LayoutItem, layoutGrid: LayoutGrid) {
        if (layoutItem.Header) {
            const layoutTitleColumn: LayoutGridColumn = {
                layoutTitle: layoutItem.Header,
                colSpan: layoutGrid.columnsNumber,
                elementId: null,
                cssClass: "header-column",
                layoutGrid: null
            };
            layoutGrid.columns.push(layoutTitleColumn);
        }
    }

    /**
     * For layout items of type Element where the label is placed in the left of the input,
     * one column is created for the label (if the label is to be printed) and another column
     * is added for the input.
     * @param layoutItem
     * @param horizontal
     * @param siblingsNumber
     * @param parentGrid
     */
    private addLabelLeftAndInputColumns(layoutItem: LayoutItem, horizontal: boolean, siblingsNumber: number, parentGrid: LayoutGrid) {
        let labelLayoutGridColumn = <LayoutGridColumn>{};
        let inputLayoutGridColumn = <LayoutGridColumn>{};
        if (horizontal) {
            labelLayoutGridColumn = {
                layoutTitle: null,
                colSpan: 1,
                elementId: layoutItem.ElementID,
                cssClass: "label-column-horizontal",
                layoutGrid: null
            };
            inputLayoutGridColumn = {
                layoutTitle: null,
                // If the label isn't to be printed, the input takes its place as well
                colSpan: layoutItem.Label ? (parentGrid.columnsNumber / siblingsNumber) - 1 : parentGrid.columnsNumber / siblingsNumber,
                elementId: layoutItem.ElementID,
                cssClass: "input-column-horizontal",
                layoutGrid: null
            };
        } else {
            labelLayoutGridColumn = {
                layoutTitle: null,
                colSpan: 1,
                elementId: layoutItem.ElementID,
                cssClass: "label-column-vertical",
                layoutGrid: null
            };
            inputLayoutGridColumn = {
                layoutTitle: null,
                // If the label isn't to be printed, the input takes its place as well
                colSpan: layoutItem.Label ? parentGrid.columnsNumber - 1 : parentGrid.columnsNumber,
                elementId: layoutItem.ElementID,
                cssClass: "input-column-vertical",
                layoutGrid: null
            };
        }
        if (layoutItem.Label) {
            // If the layout item's label is not null, the column for the label has to be added
            parentGrid.columns.push(labelLayoutGridColumn);
        }
        parentGrid.columns.push(inputLayoutGridColumn);
    }


    /**
     * For layout items of type Element where the label is placed in top of the input,
     * one column is created for both the label and the input.
     * @param layoutItem
     * @param horizontal
     * @param siblingsNumber
     * @param parentGrid
     */
    private addLabelTopAndInputColumn(layoutItem: LayoutItem, horizontal: boolean, siblingsNumber: number, parentGrid: LayoutGrid) {
        let labelInputLayoutGridColumn = <LayoutGridColumn>{};
        if (horizontal) {
            labelInputLayoutGridColumn = {
                layoutTitle: null,
                colSpan: parentGrid.columnsNumber / siblingsNumber,
                elementId: layoutItem.ElementID,
                cssClass: "label-input-column",
                layoutGrid: null
            };
        } else {
            labelInputLayoutGridColumn = {
                layoutTitle: null,
                colSpan: parentGrid.columnsNumber,
                elementId: layoutItem.ElementID,
                cssClass: "label-input-column",
                layoutGrid: null
            };
        }
        parentGrid.columns.push(labelInputLayoutGridColumn);
    }
}
