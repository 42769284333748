import { Injectable } from '@angular/core';
import { UIElementType } from '../../interfaces/form/enums/uielement-type-enum';
import { Element } from '../../interfaces/form/element';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { LabelInformation } from '../../interfaces/form/elements/label-information';

@Injectable({
  providedIn: 'root'
})

/**
 * Provides functions that help rendering the form element labels.
 */
export class LabelService {

    /**
     * For a form input element, takes the necessary information for rendering the label
     * so it reflects the current status of the element (required * preffix, if the label is to
     * be rendered or not, etc)
     * @param element
     * @param elementFormGroup
     * @param labelPosition
     */
    getLabelInformation(element: Element, elementFormGroup: UntypedFormGroup, labelPosition: string): LabelInformation {
        const labelInformation = {} as LabelInformation;
        labelInformation.displayLabel = this.isLabelToBePrinted(element);
        labelInformation.labelClass = this.getLabelHtmlClass(elementFormGroup, labelPosition);
        return labelInformation;
    }

    /**
     * This method states for which elements the label should not be printed. These are:
     * - Tables
     * - Table column edit fields
     * - Label elements, where the labels will be printed in a separate component.
     * @param element with the element information
     */
    isLabelToBePrinted(element: Element): boolean {
        if (element.UIElement !== UIElementType.Table && element.UIElement !== UIElementType.Label
            && element.UIElement !== UIElementType.LabelUnderlineRightAlignment &&
            element.UIElement !== UIElementType.CheckEditLeftLabel && element.UIElement !== UIElementType.CheckEditRightLabel) {
            return true;
        } else {
            return false;
        }
    }


    /**
     * Creates the HTML class attributes for the label element of an element.
     * @param elementFormGroup
     * @param labelPosition
     * @returns
     */
    getLabelHtmlClass(elementFormGroup: UntypedFormGroup, labelPosition: string) {
        let labelClass = "label";
        if (labelPosition === "Left") {
            labelClass = labelClass.concat(" label-left");
        } else {
            labelClass = labelClass.concat(" label-top");
        }
        if (elementFormGroup.get("value").hasValidator(Validators.required)) {
            labelClass = labelClass.concat(" required");
        }
        if (elementFormGroup.get("value").disabled) {
            labelClass = labelClass.concat(" disabled");
        }
        return labelClass;
    }
}
