<div class="action-container">
    <div class="row">
        <div class="col-md-12">
            <button class="button" (click)="onSavePdfClick()" [attr.style]="brandingBackgroundColor">
                <span>Pdf speichern</span>
            </button>
        </div>
    </div>
</div>
<ng-container *ngIf="renderPdfExportErrorPopup">
    <app-popup (popupClosed)="clearPopupInfo($event)"></app-popup>
</ng-container>
