<div [ngClass]="'form-editor'">
    <dx-drop-down-box [attr.id]="'input-' + element.ElementID" [(value)]="selectedItem" [(opened)]="isDropDownBoxOpened" [showClearButton]="true" placeholder="Bitte wählen..."
                      [dataSource]="sortedItems" valueExpr="Value" displayExpr="DisplayName" [disabled]="inputFormGroup.get('value')?.disabled" (onValueChanged)="onDropDownBoxValueChanged($event)"
                      [(isValid)]="renderingInformation.validationInformation.valid"
                      [(validationError)]="renderingInformation.validationInformation.validationMessage"
                      [placeholder]="element.NullText ?? ''">
        <dx-tree-view [dataSource]="sortedItems"
                      [selectionMode]="selectionMode"
                      [selectByClick]="true"
                      [searchEnabled]="search"
                      keyExpr="Value"
                      displayExpr="DisplayName"
                      parentIdExpr="ParentId"
                      [showCheckBoxesMode]="showCheckBoxesMode"
                      (onItemSelectionChanged)="changeLookupEditValue($event)">
        </dx-tree-view>
    </dx-drop-down-box>
</div>
