import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { AuthenticationService } from './services/Authentication.Service';
import { UtilityService } from './services/Utility.Service';
import { PopupService } from './services/popup.service';
import { AppInitializer } from './app.initializer';
import { JwtInterceptor } from './app.jwt-interceptor';
import { ExcelService } from './services/excel.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormGeneratorComponent } from './components/form/form-generator/form-generator.component';
import { FormElementGeneratorComponent } from './components/form/form-element-generator/form-element-generator.component';
import { TextEditComponent } from './components/form/elements/text-edit/text-edit.component';
import { DateEditComponent } from './components/form/elements/date-edit/date-edit.component';
import {
    DxDateBoxModule, DxDropDownBoxModule, DxListModule, DxDataGridModule, DxTextBoxModule, DxValidationSummaryModule,
    DxButtonModule, DxPopupModule, DxLoadIndicatorModule, DxSelectBoxModule, DxCheckBoxModule, DxNumberBoxModule,
    DxTreeViewModule, DxTagBoxModule, DxTextAreaModule, DxFileUploaderModule, DxToolbarModule, DxValidatorModule,
    DxTooltipModule
} from 'devextreme-angular';
import { LookupEditComponent } from './components/form/elements/lookup-edit/lookup-edit.component';
import { TableGeneratorComponent } from './components/form/table-generator/table-generator.component';
import { PopupComponent } from './components/popup/popup.component';
import { ComboBoxEditComponent } from './components/form/elements/combo-box-edit/combo-box-edit.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { CheckEditComponent } from './components/form/elements/check-edit/check-edit.component';
import { NumberBoxComponent } from './components/form/elements/number-box/number-box.component';
import { LabelComponent } from './components/form/elements/label/label.component';
import { ComboBoxEditCheckboxesComponent } from './components/form/elements/combo-box-edit-checkboxes/combo-box-edit-checkboxes.component';
import { LookupEditDatagridComponent } from './components/form/elements/lookup-edit-datagrid/lookup-edit-datagrid.component';
import { ListComponent } from './components/form/elements/list/list.component';
import { TextWrapEditComponent } from './components/form/elements/text-wrap-edit/text-wrap-edit.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FormPageComponent } from './pages/form-page/form-page.component';
import { FormSuccessPageComponent } from './pages/form-success-page/form-success-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UserController } from './client/R23_Webforms.WebInterface.UserController';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { FileUploaderComponent } from './components/form/elements/file-uploader/file-uploader.component';
import { FormLayoutGeneratorComponent } from './components/form/form-layout-generator/form-layout-generator.component';
import { Anchor } from 'exceljs';
import { KeycloakInitializer } from './keycloak.initializer';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DateValidatorDirective } from './services/form/custom-validators/date-validator.directive';
import { MathPowPipe } from './pipes/math-pow.pipe';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        FormGeneratorComponent,
        FormElementGeneratorComponent,
        TextEditComponent,
        DateEditComponent,
        LookupEditComponent,
        TableGeneratorComponent,
        PopupComponent,
        ComboBoxEditComponent,
        ReceiptComponent,
        CheckEditComponent,
        NumberBoxComponent,
        LabelComponent,
        ComboBoxEditCheckboxesComponent,
        LookupEditDatagridComponent,
        ListComponent,
        TextWrapEditComponent,
        HomePageComponent,
        FormPageComponent,
        FormSuccessPageComponent,
        LoginPageComponent,
        ValidationSummaryComponent,
        FileUploaderComponent,
        FormLayoutGeneratorComponent,
        DateValidatorDirective,
        MathPowPipe,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserTransferStateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot([
            //{ path: 'process/:vorgangid', component: ProcessComponent },
            //{ path: 'panel/:panelid', component: PanelComponent },
            { path: 'form/:inputid', component: FormPageComponent },
            { path: 'form-success', component: FormSuccessPageComponent },
            { path: 'login', component: LoginPageComponent },
            { path: '**', component: HomePageComponent },
        ],
        {
            anchorScrolling: 'enabled',
        }),
        DxDropDownBoxModule,
        DxDateBoxModule,
        DxListModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxToolbarModule,
        DxValidationSummaryModule,
        DxButtonModule,
        DxPopupModule,
        DxLoadIndicatorModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxNumberBoxModule,
        DxTreeViewModule,
        DxTagBoxModule,
        DxTextAreaModule,
        DxFileUploaderModule,
        DxValidatorModule,
        DxTooltipModule,
        KeycloakAngularModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true, deps: [AuthenticationService] },
        { provide: APP_INITIALIZER, useFactory: KeycloakInitializer, multi: true, deps: [KeycloakService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

        UserController,

        // Services
        AuthenticationService,
        ExcelService,
        UtilityService,
        PopupService,

        //Angular
        UntypedFormBuilder,
        DatePipe,
       
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
