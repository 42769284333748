
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UtilityService } from "../services/Utility.Service";
import { PopupService } from "../services/popup.service";
import { PopupType } from "../interfaces/enum/popup-type";
import * as $ from 'jquery';

@Injectable()
    export class UserController {

    constructor(private argHttpClient: HttpClient, private argUtilityService: UtilityService, private popupService: PopupService) { }
    
            public Authenticate(argLoadingAnimationContainer: JQuery | null = null): JQueryPromise<R23_Webforms.Model.Membership.User> {
                const $Deferred = $.Deferred<R23_Webforms.Model.Membership.User>();
                
                this.AuthenticateDataSource(argLoadingAnimationContainer).then((argResponse) => {
                    const oResponse = this.argUtilityService.ProcessResponse(argResponse);
    
                    if (oResponse !== undefined) {
                        $Deferred.resolve(oResponse);
                    } else {
                        $Deferred.reject();
                    }
                });
              
                return $Deferred.promise();
            }
    
            public AuthenticateDataSource(argLoadingAnimationContainer: JQuery | null = null): JQueryPromise<R23_Webforms.Model.General.Response<R23_Webforms.Model.Membership.User>> {
                const $Deferred = $.Deferred<R23_Webforms.Model.General.Response<R23_Webforms.Model.Membership.User>>();
    
                
                
                this.argHttpClient.post<R23_Webforms.Model.General.Response<R23_Webforms.Model.Membership.User>>(`api/User/Authenticate`, {}, { withCredentials: true }).subscribe((argResponse) => {
            
                    const oResponse = argResponse;
            
                    if (oResponse !== undefined && oResponse !== null) {
                        $Deferred.resolve(oResponse);
                    } else {
                        $Deferred.reject();
                    }
                }, (argError) => {
            
                    switch (argError.status) {
                        case 401:
                        case 403:
                            this.popupService.popupInfo = { title: 'Access denied', content: 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.', type: PopupType.ERROR };
                            break;
                        default:
                            this.popupService.popupInfo = { title: 'Access denied', content: 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.', type: PopupType.ERROR };
                            break;
                    }
                });
                        
                return $Deferred.promise();
            }
        
}
