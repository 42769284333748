import { AuthenticationService } from "./services/Authentication.Service";

export function AppInitializer(argAuthenticationService: AuthenticationService) {
    return () => new Promise((argResolve: any) => {
        // Attempt to refresh token on app start up to auto authenticate
        argAuthenticationService.Authenticate().then(() => {
            argResolve();
        });
    });
}
