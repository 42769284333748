import { Injectable } from '@angular/core';
import { ResponseState } from '../interfaces/form/enums/response-state-enum';
import { PopupService } from './popup.service';
import { PopupType } from '../interfaces/enum/popup-type';

@Injectable()
export class UtilityService {

    constructor(private popupService: PopupService) {
    }

    public ProcessResponse<T>(argResponse: R23_Webforms.Model.General.Response<T>): T {
        let oResponse: T;

        if (argResponse != null) {
            switch (argResponse.State) {
                case ResponseState.Success:
                    oResponse = argResponse.Data;
                    break;
                case ResponseState.Info:
                    //this.argWindowService.Info('Info', argResponse.Message);
                    oResponse = argResponse.Data;
                    break;
                case ResponseState.Error:
                    this.popupService.popupInfo = { title: 'Access denied', content: 'Es ist ein technisches Problem aufgetreten. Bitte wenden Sie sich an Ihren Ansprechpartner.', type: PopupType.ERROR };
                    break;
                default:
                    break;
            }
        }

        return oResponse;
    }
}
